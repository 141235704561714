import React from 'react'
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles'
import { TextValidator } from 'react-material-ui-form-validator'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  formSection: {
    marginTop: 25,
  },
})

interface FillLimitPerPeriodInputProps {
  fillLimitPerPeriod: string
  fillLimitPeriod: string
  handleChangeFillLimitPerPeriod: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const FillLimitPerPeriodInput = ({
  fillLimitPerPeriod,
  fillLimitPeriod,
  handleChangeFillLimitPerPeriod,
}: FillLimitPerPeriodInputProps) => {
  const classes = useStyles()

  const fillLimitPeriodWithDefault = fillLimitPeriod || 'Day'
  const parsedFillLimitPeriod = parseInt(fillLimitPerPeriod)

  return (
    <>
      <div className={classes.formSection}>
        <FormLabel className={classes.title}>Gallon Limit Per {fillLimitPeriodWithDefault}</FormLabel>
        <TextValidator
          name={'fillLimitPerPeriod'}
          value={!isNaN(parsedFillLimitPeriod) ? parsedFillLimitPeriod : ''}
          margin="dense"
          id="name"
          type="name"
          validators={['required']}
          errorMessages={[`Gallon Limit Per ${fillLimitPeriodWithDefault} is required`]}
          style={{
            display: 'flex',
          }}
          inputProps={{
            maxLength: 20,
          }}
          onChange={handleChangeFillLimitPerPeriod}
        />
      </div>
    </>
  )
}
export default FillLimitPerPeriodInput
