import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import axios from 'axios'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import PromptToDeleteAccount from './PromptToDeleteAccount'
import AccountDeleted from './AccountDeleted'
import { DoesAccountExistApiResponse } from '../../../types/types'
import ShowError from '../../Messages/ShowError'
import { useKeyPress } from '@react-typed-hooks/use-key-press'

const useStyles = makeStyles({
  cardHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  root: {
    minWidth: 275,
  },
  title: {
    justifyContent: 'center',
    display: 'flex',
    paddingBottom: 10,
  },
  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const DeleteAccount = () => {
  const classes = useStyles()
  const [accountNumber, setAccountNumber] = useState<string>('')
  const [isDeletingAccount, setIsDeletingAccount] = useState<boolean>(false)
  const [accountDeleted, setAccountDeleted] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [deletedRecords, setDeletedRecords] = useState<number>(0)

  const isEnterPressed = useKeyPress({ targetKey: 'Enter' })
  useEffect(() => {
    if (isEnterPressed === true) {
      if (!isDeletingAccount) {
        lookupAccount().then((r) => console.log(''))
      } else if (!accountDeleted) {
        setAccountDeletedDone()
      }
    }
  }, [isEnterPressed])

  const lookupAccount = async () => {
    const data = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/doesAccountExist`, {
      token: localStorage.getItem('CFN_TOKEN'),
      customerNumber: accountNumber,
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
    })
    const doesAccountExist: DoesAccountExistApiResponse = data.data

    if (doesAccountExist && doesAccountExist.doesAccountAlreadyExist === true) {
      setIsDeletingAccount(true)
    } else {
      setErrorMessage('*Account not found')
    }
  }
  const setAccountDeletedDone = () => {
    setAccountDeleted(true)
  }
  const setRowsDeleted = (rowsDeleted: number) => {
    setDeletedRecords(rowsDeleted)
  }

  return (
    <div className={classes.cardHolder}>
      <Card className={classes.root}>
        {!isDeletingAccount ? (
          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" component="h2" className={classes.title}>
              Delete Account
            </Typography>
            <ShowError errorMessage={'WARNING: This is a permanent action that cannot be undone!'}></ShowError>

            <div className={classes.controlWrapper}>
              <TextField
                onChange={(event: any) => setAccountNumber(event.target.value)}
                value={accountNumber}
                style={{ marginBottom: 20 }}
                label={'Enter Account Number'}
              />
              <Button variant="contained" onClick={lookupAccount}>
                Lookup Account
              </Button>
            </div>
          </CardContent>
        ) : !accountDeleted ? (
          <PromptToDeleteAccount
            setRowsDeleted={setRowsDeleted}
            customerNumber={accountNumber}
            setAccountDeleted={setAccountDeletedDone}
          />
        ) : (
          <AccountDeleted rowsAffected={deletedRecords} accountNumber={accountNumber} />
        )}
      </Card>
    </div>
  )
}

export default DeleteAccount
