import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import { getCardStatus } from '../../utils/helpers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useStore } from '../../store/StoreProvider'
import HasOregonPUCPermitInput from './ModalInputs/HasOregonPUCPermitInput'
import ProductsAuthorizedInput from './ModalInputs/ProductsAuthorizedInput'
import VehicleNumberInput from './ModalInputs/VehicleNumberInput'
import DepartmentInput from './ModalInputs/DepartmentInput'
import ModalInformation from './ModalInputs/ModalInformation'
import FillLimitPerPurchaseRadioGroup from './ModalInputs/FillLimitPerPurchaseRadioGroup'
import FillLimitPerPeriodInput from './ModalInputs/FillLimitPerPeriodInput'
import VehicleDescriptionInput from './ModalInputs/VehicleDescriptionInput'
import { CardModifiedCb, CardsTableDisplay } from '../../types/types'
import CardLabelInput from './ModalInputs/CardLabelInput'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  leftLabel: {
    marginRight: 20,
  },
  flexContainer: {
    display: 'flex',
  },
  formSection: {
    marginTop: 25,
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
})
interface ModifyVehicleCardModalProps {
  isOpen: boolean
  handleClose: () => void
  cardBeingModified: CardsTableDisplay
  cardModifiedCb: CardModifiedCb
}

export const ModifyVehicleCardModal = ({
  isOpen,
  handleClose,
  cardBeingModified,
  cardModifiedCb,
}: ModifyVehicleCardModalProps) => {
  const classes = useStyles()
  const { user } = useStore()
  const [dieselExhaustFluidAuthorized, setDieselExhaustFluidAuthorized] = useState(false)
  const [compressedNaturalGas, setCompressedNaturalGas] = useState(false)
  const [department, setDepartment] = useState('')
  const [fillLimitPerPeriod, setFillLimitPerPeriod] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const [hasCardRequestBeenMade, setHasCardRequestBeenMade] = useState(false)
  const [fillLimitPerPurchase, setFillLimitPerPurchase] = useState('10')
  const [pucPermitNumber, setPucPermitNumber] = useState('')

  const [vehicleNumber, setVehicleNumber] = useState('')
  const [vehicleDescription, setVehicleDescription] = useState('')
  const [hasOregonPucPlates, setHasOregonPucPlates] = useState(false)
  const [cardLabel, setCardLabel] = useState('')

  const [allProductsAuthorized, setAllProductsAuthorized] = useState(false)
  const [dieselAuthorized, setDieselAuthorized] = useState(false)
  const [oilAuthorized, setOilAuthorized] = useState(false)
  const [unleadedAuthorized, setUnleadedAuthorized] = useState(false)
  const [mediumUnleadedAuthorized, setMediumUnleadedAuthorized] = useState(false)
  const [premiumUnleadedAuthorized, setPremiumUnleadedAuthorized] = useState(false)
  const [offRoadDieselAuthorized, setOffRoadDieselAuthorized] = useState(false)

  useEffect(() => {
    setFillLimitPerPurchase(cardBeingModified.fill_limit_per_purchase)
    setVehicleNumber(cardBeingModified.veh_num.toString())
    setVehicleDescription(cardBeingModified.vehicleDescription)
    setFillLimitPerPeriod(cardBeingModified.fill_limit_per_period)
    setDepartment(cardBeingModified.department)
    setCardLabel(cardBeingModified.card_name)
  }, [cardBeingModified])

  const handleDieselExhaustFluidAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDieselExhaustFluidAuthorized(event.target.checked)
  }
  const handleCompressedNaturalGas = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompressedNaturalGas(event.target.checked)
  }
  const handleAllProductsAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllProductsAuthorized(event.target.checked)
  }
  const handleDieselAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDieselAuthorized(event.target.checked)
  }
  const handleOilAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOilAuthorized(event.target.checked)
  }
  const handleUnleadedAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnleadedAuthorized(event.target.checked)
  }
  const handleMediumUnleadedAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMediumUnleadedAuthorized(event.target.checked)
  }
  const handlePremiumUnleadedAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPremiumUnleadedAuthorized(event.target.checked)
  }
  const handleOffRoadAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOffRoadDieselAuthorized(event.target.checked)
  }
  const handleChangeCardLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardLabel(event.target.value)
  }
  const handleChangeVehicleNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleNumber(event.target.value)
  }
  const handleChangeVehicleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVehicleDescription(event.target.value)
  }
  const handleChangeDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDepartment(event.target.value)
  }
  const handleChangePUCPermitNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPucPermitNumber(event.target.value)
  }
  const handleChangeHasOregonPucPlates = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Casting string true/false from form into actual boolean type by using truthy falsy
    const value = event.target.value === 'true'
    setHasOregonPucPlates(value)
  }
  const handleChangeFillLimitPerPurchase = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFillLimitPerPurchase(event.target.value)
  }
  const handleChangeFillLimitPerPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFillLimitPerPeriod(event.target.value)
  }

  const handleSendUpdateRequest = async () => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/cards/changeVehicleCard`, {
      cardNumber: cardBeingModified.card_num,
      department: department,
      cardLabel: cardLabel,
      fillLimitPerPeriod: fillLimitPerPeriod,
      fillLimitPerPurchase: fillLimitPerPurchase,
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
      token: localStorage.getItem('CFN_TOKEN'),
      customerNumber: user?.id,
      areAllProductsAuthorized: allProductsAuthorized,
      isDieselAuthorized: dieselAuthorized,
      isOilAuthorized: oilAuthorized,
      isUnleadedAuthorized: unleadedAuthorized,
      isMediumUnleadedAuthorized: mediumUnleadedAuthorized,
      isPremiumUnleadedAuthorized: premiumUnleadedAuthorized,
      isOffRoadDieselAuthorized: offRoadDieselAuthorized,
      isCompressedNaturalGasAuthorized: compressedNaturalGas,
      isDieselExhaustFluidAuthorized: dieselExhaustFluidAuthorized,
    })

    if (response.status === 200) {
      cardModifiedCb(cardBeingModified.card_num)
      setHasCardRequestBeenMade(true)
    } else {
      setErrorMessage('*Could Not Create Card Modification Request')
    }
  }

  const clearSelections = () => {
    setHasCardRequestBeenMade(false)
    setVehicleNumber('')
    setVehicleDescription('')
    setDepartment('')
    setHasOregonPucPlates(false)
    setPucPermitNumber('')
    setFillLimitPerPurchase('')
    setFillLimitPerPeriod('')
    setCompressedNaturalGas(false)
    setCompressedNaturalGas(false)
  }
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        clearSelections()
        handleClose()
      }}
      aria-labelledby="form-dialog-title"
    >
      {!hasCardRequestBeenMade ? (
        <>
          <ValidatorForm onSubmit={handleSendUpdateRequest}>
            <DialogTitle id="form-dialog-title">Modify Vehicle Card Request</DialogTitle>
            <DialogContent>
              <ModalInformation title={'Company Name'} information={user && user.customerName}></ModalInformation>
              <ModalInformation title={'Card Number'} information={cardBeingModified.card_num}></ModalInformation>
              <ModalInformation title={'Card Status'} information={getCardStatus(cardBeingModified.card_status)}></ModalInformation>
              <div className={classes.formSection}>
                <CardLabelInput cardLabel={cardLabel} handleChangeCardLabel={handleChangeCardLabel}></CardLabelInput>
              </div>
              <VehicleNumberInput
                vehicleNumber={vehicleNumber}
                handleChangeVehicleNumber={handleChangeVehicleNumber}
              ></VehicleNumberInput>
              <VehicleDescriptionInput
                vehicleDescription={vehicleDescription}
                handleChangeVehicleDescription={handleChangeVehicleDescription}
              ></VehicleDescriptionInput>
              <DepartmentInput
                department={department}
                handleChangeDepartment={handleChangeDepartment}
              ></DepartmentInput>
              <HasOregonPUCPermitInput
                hasOregonPUCPlates={hasOregonPucPlates}
                handleChangeHasOregonPUCPlates={handleChangeHasOregonPucPlates}
                pucPermitNumber={pucPermitNumber}
                handleChangePUCPermitNumber={handleChangePUCPermitNumber}
              ></HasOregonPUCPermitInput>
              <FillLimitPerPurchaseRadioGroup
                fillLimitPerPurchase={fillLimitPerPurchase}
                handleChangeFillLimitPerPurchase={handleChangeFillLimitPerPurchase}
              ></FillLimitPerPurchaseRadioGroup>
              <FillLimitPerPeriodInput
                fillLimitPeriod={cardBeingModified.fill_limit_period}
                fillLimitPerPeriod={fillLimitPerPeriod}
                handleChangeFillLimitPerPeriod={handleChangeFillLimitPerPeriod}
              ></FillLimitPerPeriodInput>
              <ProductsAuthorizedInput
                dieselExhaustFluid={dieselExhaustFluidAuthorized}
                handleDieselExhaustFluid={handleDieselExhaustFluidAuthorized}
                compressedNaturalGas={compressedNaturalGas}
                handleCompressedNaturalGas={handleCompressedNaturalGas}
                allProductsAuthorized={allProductsAuthorized}
                dieselAuthorized={dieselAuthorized}
                oilAuthorized={oilAuthorized}
                unleadedAuthorized={unleadedAuthorized}
                mediumUnleadedAuthorized={mediumUnleadedAuthorized}
                premiumUnleadedAuthorized={premiumUnleadedAuthorized}
                offRoadAuthorized={offRoadDieselAuthorized}
                handleAllProductsAuthorized={handleAllProductsAuthorized}
                handleDieselAuthorized={handleDieselAuthorized}
                handleOilAuthorized={handleOilAuthorized}
                handleUnleadedAuthorized={handleUnleadedAuthorized}
                handleMediumUnleadedAuthorized={handleMediumUnleadedAuthorized}
                handlePremiumUnleadedAuthorized={handlePremiumUnleadedAuthorized}
                handleOffRoadAuthorized={handleOffRoadAuthorized}
              ></ProductsAuthorizedInput>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose()
                  clearSelections()
                }}
              >
                Cancel
              </Button>
              <Button type={'submit'}>Request</Button>
            </DialogActions>
          </ValidatorForm>
        </>
      ) : (
        <div>
          <p style={{ padding: 10 }}>New Card Modification Request Sent</p>
          <DialogActions>
            <Button
              onClick={() => {
                clearSelections()
                handleClose()
              }}
            >
              Close
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  )
}
export default ModifyVehicleCardModal
