import React from 'react'
import { Redirect, Route } from 'react-router-dom'

interface ProtectedRouteProps {
  componentToRender: React.FunctionComponent
  path: string
  isAdminRoute: boolean
  isAuthorized: boolean
}

export const ProtectedRoute = ({ componentToRender, path, isAdminRoute, isAuthorized }: ProtectedRouteProps) => {
  return isAuthorized ? (
    <Route path={path} component={componentToRender} />
  ) : (
    <Redirect to={{ pathname: isAdminRoute ? '/admin' : '/login' }} />
  )
}

export default ProtectedRoute
