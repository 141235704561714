import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Customer, User } from '../../types/types'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { makeStyles } from '@material-ui/core/styles'
import { allAccountsLabels } from '../../Db'
import { useStore } from '../../store/StoreProvider'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useWindowWidth } from '@react-hook/window-size'
import DataLoadingSpinner from '../Global/DataLoadingSpinner'

const useStyles = makeStyles({
  table: {},
  tableHead: {
    backgroundColor: '#ededee',
  },
  headers: {
    fontWeight: 'bold',
  },
  incompleteRegistration: {
    backgroundColor: 'yellow',
  },
  completeRegistration: {},
})

export const AdminAccountsList = () => {
  const history = useHistory()
  const classes = useStyles()
  const [accounts, setAccounts] = useState<Customer[]>()
  // the job of this variable is to get changed to trigger the useEffect hook when a registration email is sent
  const [isUpdatingAccounts, setIsUpdatingAccounts] = useState<boolean>(false)
  const width = useWindowWidth()
  const { user } = useStore()

  useEffect(() => {
    const getAllAccounts = async () => {
      const customerAccounts = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/getAllAccounts`, {
        token: localStorage.getItem('CFN_TOKEN'),
      })
      setAccounts(customerAccounts.data)
    }
    if (!accounts || isUpdatingAccounts == true) {
      getAllAccounts()
      setIsUpdatingAccounts(false)
    }
  }, [accounts, isUpdatingAccounts])

  const setSpoofAccount = (spoofId: number) => {
    if (!user) {
      return
    }

    localStorage.setItem('spoofUserId', spoofId.toString())
    history.push('/transactions')
  }
  const resendRegistrationEmail = async (customerNumber: number) => {
    try {
      await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/sendRegistrationEmail`, {
        token: localStorage.getItem('CFN_TOKEN'),
        customerNumber: customerNumber,
      })
      setIsUpdatingAccounts(true)
    } catch {
      console.log("Couldn't send email")
    }
  }

  const getAccountTableRow = (row: Customer, i: number) => {
    return (
      <TableRow
        key={i}
        className={row.hasCompletedRegistration !== 1 ? classes.incompleteRegistration : classes.completeRegistration}
      >
        <TableCell component="th" scope="row">
          {row.customerNumber}
        </TableCell>
        <TableCell>{row.customerName}</TableCell>
        {/*TODO: when customer login is implemented make this link route to a logged in customer*/}
        <TableCell>
          <button
            onClick={() => {
              setSpoofAccount(row.customerNumber)
            }}
          >
            Login As Customer
          </button>
        </TableCell>
        <TableCell>
          {row.hasCompletedRegistration !== 1 && (
            <button
              onClick={() => {
                resendRegistrationEmail(row.customerNumber)
              }}
              disabled={moment().diff(moment(row.lastRegistrationEmailSent), 'seconds') < 3600}
            >
              Re-send Registration Email
            </button>
          )}
        </TableCell>
      </TableRow>
    )
  }

  const getAccountTableRowResponsive = (row: Customer, i: number) => {
    return (
      <TableRow
        key={i}
        className={row.hasCompletedRegistration !== 1 ? classes.incompleteRegistration : classes.completeRegistration}
      >
        <p>Customer Number: {row.customerNumber}</p>
        <p>Customer Name: {row.customerName}</p>
        {/*TODO: when customer login is implemented make this link route to a logged in customer*/}

        <button
          onClick={() => {
            setSpoofAccount(row.customerNumber)
          }}
        >
          Login As Customer
        </button>
        {row.hasCompletedRegistration !== 1 && (
          <button
            onClick={() => {
              setSpoofAccount(row.customerNumber)
            }}
          >
            Re-send Registration Email
          </button>
        )}
      </TableRow>
    )
  }

  return (
    <>
      {/*!accounts*/}
      {!accounts ? (
        <DataLoadingSpinner />
      ) : (
        <Paper>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
              {width >= 879 && (
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {allAccountsLabels.columns.map((label, i) => (
                      <TableCell key={i} className={classes.headers}>
                        {label.title}
                      </TableCell>
                    ))}
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
              )}

              <TableBody>
                <TableRow></TableRow>
                {/*TODO: After the routes are more hashed out make sure to come back and add a way to dynamically wrap column data in <a> tags*/}
                {/*If data is filtered map filtered data, else map all data*/}

                {/*The media queries are now sexier */}
                {width >= 879
                  ? accounts && accounts.map((row, i) => getAccountTableRow(row, i))
                  : accounts &&
                    accounts.map((row, i) => (
                      <>
                        {getAccountTableRowResponsive(row, i)} <hr />
                      </>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </>
  )
}

export default AdminAccountsList
