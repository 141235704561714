import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import AccountNumberInput from './ModalInputs/AccountNumberInput'
import EmailInput from './ModalInputs/EmailInput'


const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  leftLabel: {
    marginRight: 20,
  },
  flexContainer: {
    display: 'flex',
  },
  formSection: {
    marginTop: 25,
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
})
interface ForgotPasswordModalProps {
  isOpen: boolean
  handleClose: () => void
}

export const ForgotPasswordModal = ({ isOpen, handleClose }: ForgotPasswordModalProps) => {
  const classes = useStyles()
  const [accountNumber, setAccountNumber] = useState('')
  const [email, setEmail] = useState('')
  const [hasPasswordRequestBeenMade, setHasPasswordRequestBeenMade] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handlePasswordRequest = async () => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/forgotPassword`, {
      email: email,
      customerNumber: accountNumber,
    })

    if (response.status === 200) {
      setHasPasswordRequestBeenMade(true)
    } else {
      setErrorMessage('*Could Not Create Password Request')
    }
  }
  const closeModal = () => {
    clearSelections()
    handleClose()
  }

  const handleChangeAccountNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccountNumber(event.target.value)
  }

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const clearSelections = () => {
    setHasPasswordRequestBeenMade(false)
    setAccountNumber('')
    setEmail('')
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose()
      }}
      onBackdropClick={clearSelections}
      aria-labelledby="form-dialog-title"
    >
      {!hasPasswordRequestBeenMade ? (
        <>
          <ValidatorForm onSubmit={handlePasswordRequest}>
            <DialogTitle id="form-dialog-title">Request Password</DialogTitle>

            <DialogContent>
              Please input your account number and the email associated with your account below to reset your password
              <AccountNumberInput
                accountNumber={accountNumber}
                handleChangeAccountNumber={handleChangeAccountNumber}
              ></AccountNumberInput>
              <EmailInput email={email} handleChangeEmailAddress={handleChangeEmail}></EmailInput>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal}>Cancel</Button>
              <Button type={'submit'}>Request Password</Button>
            </DialogActions>
          </ValidatorForm>
        </>
      ) : (
        <div>
          <p style={{ padding: 10 }}>Forgot Password Request Sent</p>
          <DialogActions>
            <Button onClick={closeModal}>Close</Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  )
}
export default ForgotPasswordModal
