import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
})

interface DepartmentInputProps {
  department: string
  handleChangeDepartment: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const DepartmentInput = ({ department, handleChangeDepartment }: DepartmentInputProps) => {
  const classes = useStyles()
  const departmentLength = department ? department.length : 0
  return (
    <>
      <FormLabel className={classes.title}>Department</FormLabel>
      <Tooltip
        title={
          'Enter department if card is to be assigned to a department on an account with an expanded single card system.'
        }
      >
        <InfoIcon style={{ height: 15 }}></InfoIcon>
      </Tooltip>
      <TextValidator
        name={'department'}
        value={department}
        margin="dense"
        id="name"
        type="name"
        style={{
          display: 'flex',
        }}
        inputProps={{
          maxLength: 20,
        }}
        onChange={handleChangeDepartment}
        helperText={`${departmentLength}/20`}
      />
    </>
  )
}
export default DepartmentInput
