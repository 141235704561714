import React from 'react'
import './App.css'
import { NavBar } from './components/Navigation/Navbar'

const App = () => {
  return (
    <div className="App">
      <NavBar />
    </div>
  )
}

export default App
