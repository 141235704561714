import React, { Dispatch, SetStateAction, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import clsx from 'clsx'
import axios from 'axios'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useStore } from '../../store/StoreProvider'
import { DoesSubAccountExistApiResponse } from '../../types/types'
import ShowError from '../Messages/ShowError'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  leftLabel: {
    marginRight: 20,
  },
  flexContainer: {
    display: 'flex',
  },
})
interface NewSubAccountModalProps {
  isOpen: boolean
  handleClose: () => void
  customerNumber: number
  setRefreshDataTable: Dispatch<SetStateAction<boolean>>
  isCreatingNewSubAccount: boolean
  setIsCreatingNewSubaccount: Dispatch<SetStateAction<boolean>>
}

export const NewSubAccountModal = ({
  isOpen,
  handleClose,
  customerNumber,
  setRefreshDataTable,
  isCreatingNewSubAccount,
  setIsCreatingNewSubaccount,
}: NewSubAccountModalProps) => {
  const classes = useStyles()
  const { user } = useStore()

  const [newCustomerUsername, setNewCustomerUsername] = useState('')
  const [newCustomerPassword, setNewCustomerPassword] = useState('')
  const [newCustomerEmail, setNewCustomerEmail] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const closeSubAccountModal = () => {
    setNewCustomerUsername('')
    setNewCustomerPassword('')
    setNewCustomerEmail('')
    handleClose()
  }

  const handleCreateSubaccount = async () => {
    // checks if subaccount already exists, if it does creation fails as to not overwrite the existing account
    const doesSubAccountExist = await axios.post(
      `${process.env.REACT_APP_BASE_ROOT_URL}/accounts/doesSubAccountExist`,
      {
        username: newCustomerUsername,
        email: newCustomerEmail,
        customerNumber: customerNumber,
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
        token: localStorage.getItem('CFN_TOKEN'),
      }
    )
    const doesAccountExist: DoesSubAccountExistApiResponse = doesSubAccountExist.data
    // if you received a response AND response indicates account doesnt exist
    console.log(doesAccountExist.doesSubAccountAlreadyExist)
    if (doesAccountExist && doesAccountExist.doesSubAccountAlreadyExist === false) {
      const response = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/createSubAccount`, {
        username: newCustomerUsername,
        password: newCustomerPassword,
        email: newCustomerEmail,
        customerNumber: customerNumber,
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
        token: localStorage.getItem('CFN_TOKEN'),
      })

      if (response.status === 200) {
        setRefreshDataTable(true)
        setIsCreatingNewSubaccount(true)
      } else {
        setErrorMessage('*Could Not Create New Subaccount')
      }
    } else {
      setErrorMessage('*Subaccount Already Exists')
    }
  }

  const handleSetNewCustomerUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCustomerUsername(event.target.value)
  }
  const handleSetNewCustomerEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCustomerEmail(event.target.value)
  }
  const handleSetNewCustomerPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCustomerPassword(event.target.value)
  }

  const clearSelections = () => {
    setNewCustomerUsername('')
    setNewCustomerPassword('')
  }
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        clearSelections()
        handleClose()
      }}
      aria-labelledby="form-dialog-title"
    >
      {!isCreatingNewSubAccount ? (
        <>
          <ValidatorForm onSubmit={handleCreateSubaccount}>
            <DialogTitle id="form-dialog-title">Create New Subaccount</DialogTitle>
            <DialogContent>
              <div className={classes.flexContainer}>
                <div className={clsx(classes.title, classes.leftLabel)}>Company Name:</div>
                {user?.customerName}
              </div>
              <div className={classes.flexContainer}>
                <div className={clsx(classes.title, classes.leftLabel)}>
                  Customer: {localStorage.getItem('spoofUserId') ? localStorage.getItem('spoofUserId') : customerNumber}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <TextValidator
                  name={'username'}
                  value={newCustomerUsername}
                  margin="dense"
                  id="username"
                  label="Subaccount Username"
                  style={{ paddingRight: 10 }}
                  onChange={handleSetNewCustomerUsername}
                  validators={['required']}
                  errorMessages={['Username is required']}
                />
                <TextValidator
                  name={'password'}
                  value={newCustomerPassword}
                  margin="dense"
                  id="password"
                  label="Subaccount Password"
                  style={{ paddingRight: 10 }}
                  onChange={handleSetNewCustomerPassword}
                  validators={['required']}
                  errorMessages={['Password is required']}
                />
                <TextValidator
                  name={'email'}
                  value={newCustomerEmail}
                  margin="dense"
                  id="email"
                  label="Subaccount Email"
                  style={{ paddingRight: 10 }}
                  onChange={handleSetNewCustomerEmail}
                  validators={['required', 'isEmail']}
                  errorMessages={['Email is required', 'Email must be in proper format']}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeSubAccountModal}>Cancel</Button>
              <Button type={'submit'}>Create</Button>
            </DialogActions>
            <ShowError errorMessage={errorMessage}></ShowError>
          </ValidatorForm>
        </>
      ) : (
        <div>
          <p style={{ padding: 10 }}>New Subaccount Created</p>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  )
}
export default NewSubAccountModal
