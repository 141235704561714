import React from 'react'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Link } from 'react-router-dom'
import { useStore } from '../../store/StoreProvider'
import { Typography } from '@material-ui/core'

const styles = makeStyles({
  root: {
    flexGrow: 1,
    fontFamily: 'Oswald',
    fontWeight: 600,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  buttonHover: {
    '&:hover': {
      borderRadius: 0,
      borderBottom: '1px solid #e32124',
      backgroundColor: 'transparent',
    },
  },
  typography: {
    color: 'black',
    fontFamily: 'Oswald',
    textTransform: 'none',
  },
  title: {
    flexGrow: 1,
  },
  backGround: {
    backgroundColor: 'white',
  },
  toolbar: {
    justifyContent: 'center!important' as 'center',
  },
  '@media (max-width: 600px)': {
    toolbar: {
      justifyContent: 'left!important' as 'center',
    },
  },
  topImg: {
    width: '100%',
    height: 225,
  },
  aligner: {
    alignItems: 'center',
    justifyContent: 'center',
    // width: '75%',
  },
  aboveNavbar: {
    display: 'flex',
    height: 200,
    flexDirection: 'row',
    width: '100% !important',
  },
  travelCardLogoContainer: {
    display: 'flex',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  travelCardLogo: {
    maxWidth: 590,
  },
  cfnLogoContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cfnLogo: {
    maxWidth: 71,
  },
  loginButton: {
    paddingLeft: 50,
  },
})
export const AdminNavigationOptions = () => {
  const classes = styles()
  const { user, setUser } = useStore()

  const logoutAdmin = () => {
    localStorage.removeItem('CFN_TOKEN')
    localStorage.removeItem('spoofUserId')
    setUser(undefined)
  }

  return (
    <>
      <Button
        disableRipple={true}
        className={`${classes.typography} + ${classes.buttonHover}`}
        component={Link}
        to="/addAccount"
      >
        Add Account
      </Button>
      <Button
        disableRipple={true}
        className={`${classes.typography} + ${classes.buttonHover}`}
        component={Link}
        to="/changePassword"
      >
        Change Password
      </Button>
      <Button
        disableRipple={true}
        className={`${classes.typography} + ${classes.buttonHover}`}
        component={Link}
        to="/deleteAccount"
      >
        Delete Account
      </Button>
      <Button
        disableRipple={true}
        className={`${classes.typography} + ${classes.buttonHover}`}
        component={Link}
        to="/adminAccountsList"
      >
        View All Accounts
      </Button>
      <Button
        disableRipple={true}
        className={`${classes.typography} + ${classes.buttonHover}`}
        component={Link}
        to="/removeDuplicateInvoices"
      >
        Remove Duplicate Invoices
      </Button>
      <Typography className={`${classes.typography}`}>{user ? `Logged in as ${user.username}` : 'Login'}</Typography>
      {user && (
        <Button
          disableRipple={true}
          className={`${classes.typography} + ${classes.buttonHover}`}
          component={Link}
          onClick={logoutAdmin}
          to={'/admin'}
        >
          {'Logout'}
        </Button>
      )}
    </>
  )
}

export default AdminNavigationOptions
