import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { DataTableDisplayColumns, Statement } from '../../types/types'
import TablePagination from '@material-ui/core/TablePagination'
import moment from 'moment'
import { Popper, TextField } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import StatementRequestModal from '../Modals/StatementRequestModal'
import { useStore } from '../../store/StoreProvider'
import FilterListIcon from '@material-ui/icons/FilterList'
import { DaterangePicker } from '../Global/DaterangePicker'

const useStyles = makeStyles({
  table: {},
  searchAndClearWrapper: {
    display: 'flex',
    paddingRight: 20,
  },
  dateInputBox: {
    maxWidth: 75,
  },
  searchIcon: {
    color: '#FF2045',
  },
})
interface ResponsiveStatementDisplayProps {
  statements: Statement[] | undefined
  labels: DataTableDisplayColumns
  filteredStatements: Statement[] | undefined
  setFilteredStatements: (statements: Statement[] | undefined) => void
}

export const ResponsiveStatementDisplay = ({
  labels,
  statements,
  filteredStatements,
  setFilteredStatements,
}: ResponsiveStatementDisplayProps) => {
  const classes = useStyles()
  const { user } = useStore()
  const [statementNumberBeingRequested, setStatementNumberBeingRequested] = useState(0)
  const [page, setPage] = useState(0)
  const [requestStatementModalOpen, setRequestStatementModalOpen] = useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [statementIdFilterValue, setStatementIdFilterValue] = useState('')
  const [startDate, setStartDate] = useState<Date | undefined>(moment().subtract(1, 'months').toDate())
  const [endDate, setEndDate] = useState<Date | undefined>(moment().toDate())
  const [filterPopperAnchorEl, setFilterPopperAnchorEl] = React.useState<null | HTMLElement>(null)

  const filterValues = [startDate, endDate, statementIdFilterValue]

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const sortByDate = () => {
    let sortedDataTable: Statement[] | undefined = []
    const tableToUse = filteredStatements ? filteredStatements : statements
    sortedDataTable =
      tableToUse &&
      tableToUse.sort((a: Statement, b: Statement) => {
        const dateA = new Date(a.statementDate)
        const dateB = new Date(b.statementDate)
        return dateB.valueOf() - dateA.valueOf()
      })

    setFilteredStatements(sortedDataTable)
  }

  const handleRequestStatementOpen = (statementBeingRequested: number) => {
    setStatementNumberBeingRequested(statementBeingRequested)
    setRequestStatementModalOpen(true)
  }
  const handleRequestStatementClose = () => {
    setRequestStatementModalOpen(false)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const clearAllFilters = () => {
    setEndDate(undefined)
    setStartDate(undefined)
    setStatementIdFilterValue('')
  }

  const filterPopperHandleClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterPopperAnchorEl(filterPopperAnchorEl ? null : event.currentTarget)
  }

  const filterTable = () => {
    // When filtering return to first page of dataset
    setPage(0)
    // sortByDate()
    // Makes a copy of all data on data table. This serves as source of truth for all filters going down the line
    let tempFilteredTable: Statement[] | undefined = statements

    //Filtering is exclusive so if it fails any one filter it is removed
    if (startDate) {
      tempFilteredTable = tempFilteredTable?.filter((row) => moment(row.statementDate).isAfter(moment(startDate)))
    }
    if (endDate) {
      tempFilteredTable = tempFilteredTable?.filter((row) => moment(row.statementDate).isBefore(moment(endDate)))
    }
    if (statementIdFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.id.toString().startsWith(statementIdFilterValue))
    }
    setFilteredStatements(tempFilteredTable)
  }

  useEffect(() => {
    // Any time one of the filters is updated re-run the filtering logic
    filterTable()
  }, filterValues)

  useEffect(() => {
    sortByDate()
    filterTable()
  }, [statements])

  const getStatementTableRow = (row: Statement, i: number) => {
    return (
      <TableRow key={i}>
        <TableCell component="th" scope="row">
          <p>Date: {moment(row.statementDate).format('MM/DD/YYYY')}</p>
          <p>Statement Id: {row.id}</p>
          <a href={'#'} onClick={() => handleRequestStatementOpen(row.id)}>
            Request Statement
          </a>
        </TableCell>
      </TableRow>
    )
  }

  // TODO: Replace this with a composable version that can accept a component as a prop for the rows
  return (
    <>
      <StatementRequestModal
        isOpen={requestStatementModalOpen}
        handleClose={handleRequestStatementClose}
        statementBeingRequested={statementNumberBeingRequested}
        customerNumber={user?.id ? user?.id : 0}
      ></StatementRequestModal>
      <Paper>
        <div className={classes.searchAndClearWrapper}>
          <IconButton aria-label="filter list" onClick={filterPopperHandleClick}>
            <FilterListIcon />
          </IconButton>
          <Popper id={'dadasad'} open={Boolean(filterPopperAnchorEl)} anchorEl={filterPopperAnchorEl}>
            <Paper style={{ display: 'flex', flexDirection: 'column' }}>
              <>
                <div>
                  <IconButton onClick={() => clearAllFilters()}>
                    <ClearIcon></ClearIcon>
                  </IconButton>
                  <div style={{ maxWidth: 100 }}>
                    <p>Date:</p>
                    <DaterangePicker
                      onChange={daterange => {
                        daterange.startDate && setStartDate(daterange.startDate)
                        daterange.endDate && setEndDate(daterange.endDate)
                      }}
                      initialDateRange={{
                        startDate: startDate,
                        endDate: endDate,
                      }}
                    />
                  </div>
                  <p>Statement Id:</p>
                  <TextField
                    style={{ maxWidth: 75 }}
                    onChange={(event: any) => setStatementIdFilterValue(event.target.value)}
                    value={statementIdFilterValue}
                  />
                </div>
              </>
            </Paper>
          </Popper>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small">
            <TableBody>
              <TableRow></TableRow>
              {/*TODO: After the routes are more hashed out make sure to come back and add a way to dynamically wrap column data in <a> tags*/}
              {/*If data is filtered map filtered data, else map all data*/}
              {filteredStatements
                ? filteredStatements
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => getStatementTableRow(row, i))
                : statements &&
                  statements
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => getStatementTableRow(row, i))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          onPageChange={() => {}}
          count={filteredStatements && filteredStatements !== undefined ? filteredStatements.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}
export default ResponsiveStatementDisplay
