import jwt_decode from 'jwt-decode'
import { User } from '../types/types'


export const getCardStatus = (status: string) => {
  if (status === 'I') {
    return 'Invalid'
  } else if (status === 'V') {
    return 'Valid'
  } else {
    return ''
  }
}

export const getUserToSet = () => {
  const token = localStorage.getItem('CFN_TOKEN')
  if (!token) {
    return undefined
  }
  const decodedToken: any = jwt_decode(token)
  //const issuedAtDate = moment.unix(decodedToken.iat)
  //const expireDate = moment.unix(decodedToken.exp)

  // const hoursLeftBeforeTokenExpire = moment.utc().diff(expireDate, 'hours')
  // // if the hours left is less than 0 it means the token hasnt expired yet, ie: if the token is an hour old this value will be -23
  // if (hoursLeftBeforeTokenExpire > 0) {
  //   // dont allow user data to be set if the token is expired
  //   return undefined
  // }

  const userToSet: User = {
    token: token ? token : '',
    username: decodedToken.username,
    isAdmin: decodedToken.level === 'admin',
    id: decodedToken.id,
    customerName: decodedToken.companyName ? decodedToken.companyName : '',
    isRegistrationComplete: decodedToken.isRegistrationComplete,
    emailOnfile: decodedToken.emailOnFile,
  }

  return userToSet
}

// export const setDateRange = (newFilterValue: string, setEndDate: (date: Date) => void, setStartDate: (date: Date) => void ) => {
//   const todaysDate = moment().toDate();
//   setEndDate(todaysDate);
//
//   if(newFilterValue === 'quarter')
//   {
//     const startOfCurrentQuarter = moment().startOf('quarter').toDate()
//     setStartDate(startOfCurrentQuarter)
//   }
//   if(newFilterValue === 'currentMonth')
//   {
//     const startOfMonth = moment().startOf('month').toDate();
//     setStartDate(startOfMonth)
//   }
//   if(newFilterValue === 'currentMonth')
//   {
//     const firstDayOfYear = moment().startOf('year').toDate();
//     setStartDate(firstDayOfYear)
//   }
//   if(newFilterValue === 'threeMonth')
//   {
//     const threeMonthsAgo = moment().subtract(3, 'M').toDate();
//     setStartDate(threeMonthsAgo)
//   }
// }
