import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  title: {
    justifyContent: 'center',
    display: 'flex',
    paddingBottom: 10,
  },

  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
})

interface AccountDeletedProps {
  rowsAffected: number
  accountNumber: string
}

export const AccountDeleted = ({ rowsAffected, accountNumber }: AccountDeletedProps) => {
  let history = useHistory()
  const classes = useStyles()

  const returnToAdminPanel = () => {
    history.push('adminAccountsList')
  }

  return (
    <>
      <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h5" component="h2" className={classes.title}>
          Account {accountNumber} Deleted! {rowsAffected} records deleted.
        </Typography>
        <div className={classes.controlWrapper}>
          <Button variant="contained" onClick={returnToAdminPanel}>
            Return To Admin Panel
          </Button>
        </div>
      </CardContent>
    </>
  )
}

export default AccountDeleted
