import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ValidatorForm } from 'react-material-ui-form-validator'
import EmailInput from "./ModalInputs/EmailInput";
import axios from "axios";
import ShowError from "../Messages/ShowError";

interface AccountNeedsEmailModalProps {
  customerId: string,
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void
}

export const AccountNeedsEmailModal = ({ customerId, isOpen, setIsOpen }: AccountNeedsEmailModalProps) => {
  const [email, setEmail] = useState('')
  const [hasEmailBeenSet, setHasEmailBeenSet] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')


  const userSetEmail = async () => {
    //console.log('token ' + JSON.stringify(decodedToken))
    const emailSubmitted = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/userSetOwnEmailAfterAttemptedUsernameLogin`, {
      // verifies with token from url sent in email to confirm user has access to email
      customerNumber: customerId,
      email: email,
    })
    if (emailSubmitted.status === 200) {
      console.log(emailSubmitted)
      setHasEmailBeenSet(true)
    } else {
      setErrorMessage('*Email could not be set.')
    }
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const closeModal = () => {
    clearSelections()
    handleClose()
  }

  const handleChangeEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEmail(event.target.value as string)
  }

  const clearSelections = () => {
    setEmail('')
  }
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        clearSelections()
        handleClose()
      }}
      onBackdropClick={clearSelections}
      aria-labelledby="form-dialog-title"
    >
      {
        !hasEmailBeenSet ?
        <>
          <ValidatorForm onSubmit={userSetEmail}>
            <DialogTitle id="form-dialog-title">Only Email Logins Are Allowed.</DialogTitle>
            <DialogTitle id="form-dialog-title">Please Enter the Email You Would Like To Use For your
              Account</DialogTitle>
            <DialogContent>
              <EmailInput email={email} handleChangeEmailAddress={handleChangeEmail}></EmailInput>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal}>Cancel</Button>
              <Button type="submit">Add Email To Account</Button>
            </DialogActions>
            <ShowError errorMessage={errorMessage}></ShowError>
          </ValidatorForm>
        </>:
            <div>
              <DialogTitle id="form-dialog-title">Account Email Set!</DialogTitle>
              <DialogTitle id="form-dialog-title">Please Log In With Your Email Address and Password</DialogTitle>
              <DialogActions>
              <Button onClick={closeModal}>Close</Button>
                </DialogActions>
            </div>

      }
    </Dialog>
  )
}
export default AccountNeedsEmailModal
