import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import clsx from 'clsx'
import axios from 'axios'
import { getCardStatus } from '../../utils/helpers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useStore } from '../../store/StoreProvider'
import ChangeCardLabelQuestion from './ModalInputs/ChangeCardLabelQuestion'
import ReasonForReplacementQuestion from './ModalInputs/ReasonForReplacementQuestion'
import { CardModifiedCb, CardsTableDisplay } from '../../types/types'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  leftLabel: {
    marginRight: 20,
  },
  flexContainer: {
    display: 'flex',
  },
  formSection: {
    marginTop: 25,
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
})
interface ModifyCardModalProps {
  isOpen: boolean
  handleClose: () => void
  cardBeingModified: CardsTableDisplay
  cardModifiedCb: CardModifiedCb
}

export const ReplaceCardModal = ({
  isOpen,
  handleClose,
  cardBeingModified,
  cardModifiedCb,
}: ModifyCardModalProps) => {
  const classes = useStyles()
  const { user } = useStore()
  const [newCardLabel, setNewCardLabel] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [reasonForReplacement, setReasonForReplacement] = useState('')
  const [shouldChangeCardLabel, setShouldChangeCardLabel] = useState(false)

  const [hasCardRequestBeenMade, setHasCardRequestBeenMade] = useState(false)

  const handleSendReplaceCardRequest = async () => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/cards/replaceCard`, {
      customerNumber: user?.id,
      companyName: user?.customerName,
      cardStatus: cardBeingModified.card_status,
      cardBeingModified: cardBeingModified.card_num,
      newLabel: newCardLabel,
      reasonForReplacement: reasonForReplacement,
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
      token: localStorage.getItem('CFN_TOKEN'),
    })

    if (response.status === 200) {
      cardModifiedCb(cardBeingModified.card_num)
      setHasCardRequestBeenMade(true)
    } else {
      setErrorMessage('*Could Not Create Card Order Request')
    }
  }
  const closeModal = () => {
    clearSelections()
    setHasCardRequestBeenMade(false)
    handleClose()
  }
  const handleChangeShouldChangeCardLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Casting string true/false from form into actual boolean type by using truthy falsy
    const value = event.target.value === 'true'
    setShouldChangeCardLabel(value)
  }
  const handleChangeReasonForReplacement = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonForReplacement(event.target.value)
  }

  const handleChangeNewCardLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewCardLabel(event.target.value)
  }

  const clearSelections = () => {
    setHasCardRequestBeenMade(false)
    setNewCardLabel('')
    setReasonForReplacement('')
    setShouldChangeCardLabel(false)
  }
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        //  clearSelections();
        handleClose()
      }}
      onBackdropClick={clearSelections}
      aria-labelledby="form-dialog-title"
    >
      {!hasCardRequestBeenMade ? (
        <>
          <ValidatorForm onSubmit={handleSendReplaceCardRequest}>
            <DialogTitle id="form-dialog-title">Replace Card</DialogTitle>
            <DialogContent>
              <div className={classes.flexContainer}>
                <div className={clsx(classes.title, classes.leftLabel)}>Company Name:</div>
                {user?.customerName}
              </div>
              <div className={classes.flexContainer}>
                <div className={clsx(classes.title, classes.leftLabel)}>Card Number:</div>
                {cardBeingModified.card_num}
              </div>
              <div className={classes.flexContainer}>
                <div className={clsx(classes.title, classes.leftLabel)}>Vehicle Number:</div> {cardBeingModified.veh_num}
              </div>
              <div className={classes.flexContainer}>
                <div className={clsx(classes.title, classes.leftLabel)}>Card Status:</div> {getCardStatus(cardBeingModified.card_status)}
              </div>
              <ReasonForReplacementQuestion
                reasonForReplacement={reasonForReplacement}
                handleChangeReasonForReplacement={handleChangeReasonForReplacement}
              ></ReasonForReplacementQuestion>
              <ChangeCardLabelQuestion
                newCardLabel={newCardLabel}
                shouldChangeCardLabel={shouldChangeCardLabel}
                handleChangeShouldChangeCardLabel={handleChangeShouldChangeCardLabel}
                handleChangeNewCardLabel={handleChangeNewCardLabel}
              ></ChangeCardLabelQuestion>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal}>Cancel</Button>
              <Button type={'submit'}>Order</Button>
            </DialogActions>
          </ValidatorForm>
        </>
      ) : (
        <div>
          <p style={{ padding: 10 }}>New Card Replacement Request Sent</p>
          <DialogActions>
            <Button onClick={closeModal}>Close</Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  )
}
export default ReplaceCardModal
