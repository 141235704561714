import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Button from '@material-ui/core/Button'
// @ts-ignore
import * as Spinner from 'react-spinkit'
import ShowError from '../../Messages/ShowError'

interface PromptToDeleteMultipleInvoicesProps {
  duplicateRecordsFoundCount: number
  deleteSwipes: () => void
  isDeleting: boolean
}

export const PromptToDeleteMultipleInvoices = ({
  duplicateRecordsFoundCount,
  deleteSwipes,
  isDeleting,
}: PromptToDeleteMultipleInvoicesProps) => {
  return (
    <>
      <p>Found {duplicateRecordsFoundCount} duplicate invoices. Would you like to proceed with deletion?</p>
      <ShowError
        errorMessage={'Warning: This action will permanently delete records from the database and cannot be reversed!'}
      ></ShowError>
      {!isDeleting ? (
        <Button variant="contained" onClick={deleteSwipes}>
          Delete Duplicate Invoices
        </Button>
      ) : (
        <>
          <p>Deleting Duplicate Records, please stand by...</p>
          <Spinner name="pacman" color="#ed2224" />
        </>
      )}
    </>
  )
}

export default PromptToDeleteMultipleInvoices
