import React, { useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TransactionHistoryDataDisplay from './TransactionHistoryDataDisplay'
import { historyTable } from '../../Db'
import axios from 'axios'
import { useStore } from '../../store/StoreProvider'
import Media from 'react-media'
import ResponsiveTransactionHistoryDataDisplay from './ResponsiveTransactionHistoryDataDisplay'

const styles = makeStyles({
  flexContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
  },
})

export const HistoryPage = () => {
  const classes = styles()
  const { historyDataTable, setHistoryDataTableDisplay } = useStore()
  //   const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    const getAllTransactions = async () => {
      const data = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts`, {
        token: localStorage.getItem('CFN_TOKEN'),
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
      })
      setHistoryDataTableDisplay(data.data)
    }
    if (!historyDataTable) {
      getAllTransactions()
    }
  }, [historyDataTable])

  return (
    <>
      <div className={classes.flexContainer}>
        <Media
          query="(max-width: 879px)"
          render={() => (
            <ResponsiveTransactionHistoryDataDisplay
              columns={historyTable.columns}
            ></ResponsiveTransactionHistoryDataDisplay>
          )}
        />
        <Media
          query="(min-width: 880px)"
          render={() => <TransactionHistoryDataDisplay columns={historyTable.columns}></TransactionHistoryDataDisplay>}
        />
      </div>
    </>
  )
}

export default HistoryPage
