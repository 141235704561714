import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import axios from 'axios'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import { useHistory } from 'react-router-dom'
import AccountCreated from './AccountCreated'
import EmailInput from '../../Modals/ModalInputs/EmailInput'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import FormLabel from '@material-ui/core/FormLabel'
import { DoesAccountExistApiResponse } from '../../../types/types'
import ShowError from '../../Messages/ShowError'

const useStyles = makeStyles({
  cardHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  root: {
    minWidth: 275,
  },
  title: {
    display: 'flex',
    paddingBottom: 10,
  },
  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  fieldTitle: {
    fontWeight: 'bold',
    color: 'black',
    paddingTop: 5,
    textAlign: 'left',
  },
})

export const AddNewCustomer = () => {
  let history = useHistory()
  const classes = useStyles()
  const [accountNumber, setAccountNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [customerName, setCustomerName] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [addLinkToChangePassword, setAddLinkToChangePassword] = useState<boolean>(false)
  const [accountCreated, setAccountCreated] = useState<boolean>(false)

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const createNewCustomer = async () => {
    let wasAccountRegistered = false
    // check to make sure customer does not already exist
    try {
      const data = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/doesAccountExist`, {
        token: localStorage.getItem('CFN_TOKEN'),
        customerNumber: accountNumber,
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
      })
      const doesAccountExist: DoesAccountExistApiResponse = data.data
      if (doesAccountExist && doesAccountExist.doesAccountAlreadyExist === true) {
        setErrorMessage(`*Account ${accountNumber} already exists`)
        setAddLinkToChangePassword(true)
        return
      } else {
        setErrorMessage('')
        console.log('creating customer')
        const data = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/addNewAccount`, {
          token: localStorage.getItem('CFN_TOKEN'),
          customerNumber: accountNumber,
          //   password: newPassword,
          email: email,
          customerName: customerName,
          spoofedCustomerId: localStorage.getItem('spoofUserId'),
        })
        if (data && data.status === 200) {
          wasAccountRegistered = true
          await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/sendRegistrationEmail`, {
            token: localStorage.getItem('CFN_TOKEN'),
            customerNumber: accountNumber,
            customerName: customerName,
            spoofedCustomerId: localStorage.getItem('spoofUserId'),
          })
          setAccountCreated(true)
        } else {
          setErrorMessage('*Account Could Not Be Created')
        }
      }
    } catch (e) {
      console.log('')
      if (wasAccountRegistered) {
        // account was registered but SMTP server could not be used
        setErrorMessage(
          'Account was created but registration email could not be sent. Please contact OEC for help with the SMTP server.'
        )
      }
    }
  }

  return (
    <div className={classes.cardHolder}>
      <Card className={classes.root}>
        {accountCreated ? (
          <AccountCreated customerNumber={accountNumber}></AccountCreated>
        ) : (
          <>
            <ValidatorForm onSubmit={createNewCustomer}>
              <CardContent
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h5" component="h2" className={classes.title}>
                  Add New Customer
                </Typography>
                <div className={classes.controlWrapper}>
                  <FormLabel className={classes.fieldTitle}>Account Number</FormLabel>
                  <TextValidator
                    name={'accountNumber'}
                    validators={['required']}
                    errorMessages={['Account number is required']}
                    onChange={(event: any) => setAccountNumber(event.target.value)}
                    value={accountNumber}
                    style={{ marginBottom: 20, display: 'flex' }}
                    inputProps={{
                      maxLength: 16,
                    }}
                    helperText={`${accountNumber.length}/16`}
                  />
                  <FormLabel className={classes.fieldTitle}>Customer Name</FormLabel>
                  <TextValidator
                    name={'customerName'}
                    validators={['required']}
                    errorMessages={['Customer name is required']}
                    onChange={(event: any) => setCustomerName(event.target.value)}
                    value={customerName}
                    style={{ marginBottom: 20, display: 'flex' }}
                  />
                  <EmailInput email={email} handleChangeEmailAddress={handleChangeEmail} />
                  <Button variant="contained" type={'submit'}>
                    Create New Customer
                  </Button>
                  <ShowError errorMessage={errorMessage}></ShowError>
                </div>
              </CardContent>
            </ValidatorForm>
          </>
        )}
      </Card>
    </div>
  )
}

export default AddNewCustomer
