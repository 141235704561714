import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DriverCardOrder, SingleCardOrder, VehicleCardOrder } from '../../types/types'
import { ValidatorForm } from 'react-material-ui-form-validator'
import VehicleNumberInput from './ModalInputs/VehicleNumberInput'
import HasOregonPUCPermitInput from './ModalInputs/HasOregonPUCPermitInput'
import FillLimitPerPurchaseRadioGroup from './ModalInputs/FillLimitPerPurchaseRadioGroup'
import FillLimitPerPeriodInput from './ModalInputs/FillLimitPerPeriodInput'
import ProductsAuthorizedInput from './ModalInputs/ProductsAuthorizedInput'
import CardLabelInput from './ModalInputs/CardLabelInput'
import DepartmentInput from './ModalInputs/DepartmentInput'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  formSection: {
    marginTop: 25,
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
})
interface SingleCardOrderModalProps {
  isOpen: boolean
  handleClose: () => void
  handleSetCurrentCardOrder: (cardOrder: SingleCardOrder | VehicleCardOrder | DriverCardOrder) => void
}

export const SingleCardOrderModal = ({ isOpen, handleClose, handleSetCurrentCardOrder }: SingleCardOrderModalProps) => {
  const [encodeVehicleNumber, setEncodeVehicleNumber] = useState(false)
  const [enterVehicleNumberAtPump, setEnterVehicleNumberAtPump] = useState(false)
  const [enterOdometerReadingAtPump, setEnterOdometerReadingAtPump] = useState(false)
  const [hasOregonPucPlates, setHasOregonPucPlates] = useState(false)
  const [allProductsAuthorized, setAllProductsAuthorized] = useState(false)

  const [dieselAuthorized, setDieselAuthorized] = useState(false)
  const [oilAuthorized, setOilAuthorized] = useState(false)
  const [unleadedAuthorized, setUnleadedAuthorized] = useState(false)
  const [mediumUnleadedAuthorized, setMediumUnleadedAuthorized] = useState(false)
  const [premiumUnleadedAuthorized, setPremiumUnleadedAuthorized] = useState(false)
  const [offRoadDieselAuthorized, setOffRoadDieselAuthorized] = useState(false)

  const [emailAddress, setEmailAddress] = useState('')
  const [department, setDepartment] = useState('')
  const [cardLabel, setCardLabel] = useState('')
  const [fillLimitPerPeriod, setFillLimitPerPeriod] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [dieselExhaustFluidAuthorized, setDieselExhaustFluidAuthorized] = useState(false)
  const [compressedNaturalGas, setCompressedNaturalGas] = useState(false)

  const [vehicleNumber, setVehicleNumber] = useState('')
  const [pucPermitNumber, setPucPermitNumber] = useState('')
  const [fillLimitPerPurchase, setFillLimitPerPurchase] = useState('10')

  const buildAndAddOrder = () => {
    const orderToAdd: SingleCardOrder = {
      orderType: 'single',
      oregonPucPlates: hasOregonPucPlates,
      pucPermitNumber: pucPermitNumber,
      fillLimitPerPurchase: fillLimitPerPurchase,
      fillLimitPerDay: fillLimitPerPeriod,
      cardLabel: cardLabel,
      department: department,
      vehicleNumber: vehicleNumber,
      areAllProductsAuthorized: allProductsAuthorized,
      isDieselAuthorized: dieselAuthorized,
      isOilAuthorized: oilAuthorized,
      isUnleadedAuthorized: unleadedAuthorized,
      isMediumUnleadedAuthorized: mediumUnleadedAuthorized,
      isPremiumUnleadedAuthorized: premiumUnleadedAuthorized,
      isOffroadDieselAuthorized: offRoadDieselAuthorized,
      isCompressedNaturalGasAuthorized: compressedNaturalGas,
      isDieselExhaustFluidAuthorized: dieselExhaustFluidAuthorized,
    }
    handleSetCurrentCardOrder(orderToAdd)
    closeModal()
  }
  const handleDieselExhaustFluidAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDieselExhaustFluidAuthorized(event.target.checked)
  }
  const handleCompressedNaturalGas = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompressedNaturalGas(event.target.checked)
  }
  const handleChangeDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDepartment(event.target.value)
  }
  const closeModal = () => {
    setVehicleNumber('')
    setAllProductsAuthorized(false)
    setDieselAuthorized(false)
    setOilAuthorized(false)
    setUnleadedAuthorized(false)
    setOffRoadDieselAuthorized(false)
    setMediumUnleadedAuthorized(false)
    setPremiumUnleadedAuthorized(false)
    setEncodeVehicleNumber(false)
    setEnterVehicleNumberAtPump(false)
    setEnterOdometerReadingAtPump(false)
    setHasOregonPucPlates(false)
    setPucPermitNumber('')
    setFillLimitPerPurchase('')
    setFillLimitPerPeriod('')
    setCardLabel('')
    setDepartment('')
    handleClose()
  }

  const handleChangeFillLimitPerPurchase = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFillLimitPerPurchase(event.target.value)
  }
  const handleChangeCardLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardLabel(event.target.value)
  }

  const handleChangeFillLimitPerPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFillLimitPerPeriod(event.target.value)
  }

  const handleChangeHasOregonPucPlates = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Casting string true/false from form into actual boolean type by using truthy falsy
    const value = event.target.value === 'true'
    setHasOregonPucPlates(value)
  }
  const handleAllProductsAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllProductsAuthorized(event.target.checked)
  }
  const handleDieselAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDieselAuthorized(event.target.checked)
  }
  const handleOilAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOilAuthorized(event.target.checked)
  }
  const handleUnleadedAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnleadedAuthorized(event.target.checked)
  }
  const handleMediumUnleadedAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMediumUnleadedAuthorized(event.target.checked)
  }
  const handlePremiumUnleadedAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPremiumUnleadedAuthorized(event.target.checked)
  }
  const handleOffRoadAuthorized = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOffRoadDieselAuthorized(event.target.checked)
  }

  const handleChangeVehicleNumber = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVehicleNumber(event.target.value as string)
  }

  const handleChangePUCPermitNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPucPermitNumber(event.target.value)
  }
  const clearSelections = () => {
    setEncodeVehicleNumber(false)
    setEnterVehicleNumberAtPump(false)
    setEnterOdometerReadingAtPump(false)
    setHasOregonPucPlates(false)
    setAllProductsAuthorized(false)
    setDieselAuthorized(false)
    setOilAuthorized(false)
    setUnleadedAuthorized(false)
    setMediumUnleadedAuthorized(false)
    setPremiumUnleadedAuthorized(false)
    setOffRoadDieselAuthorized(false)
    setEmailAddress('')
    setDepartment('')
    setCardLabel('')
  }
  return (
    <Dialog
      open={isOpen}
      onBackdropClick={clearSelections}
      onClose={() => {
        clearSelections()
        handleClose()
      }}
      aria-labelledby="form-dialog-title"
    >
      <>
        <ValidatorForm onSubmit={buildAndAddOrder}>
          <DialogTitle id="form-dialog-title">Single Card Order Form</DialogTitle>
          <DialogContent>
            <CardLabelInput cardLabel={cardLabel} handleChangeCardLabel={handleChangeCardLabel}></CardLabelInput>
            <VehicleNumberInput
              vehicleNumber={vehicleNumber}
              handleChangeVehicleNumber={handleChangeVehicleNumber}
            ></VehicleNumberInput>
            <HasOregonPUCPermitInput
              hasOregonPUCPlates={hasOregonPucPlates}
              handleChangeHasOregonPUCPlates={handleChangeHasOregonPucPlates}
              pucPermitNumber={pucPermitNumber}
              handleChangePUCPermitNumber={handleChangePUCPermitNumber}
            ></HasOregonPUCPermitInput>
            <FillLimitPerPurchaseRadioGroup
              fillLimitPerPurchase={fillLimitPerPurchase}
              handleChangeFillLimitPerPurchase={handleChangeFillLimitPerPurchase}
            ></FillLimitPerPurchaseRadioGroup>
            <FillLimitPerPeriodInput
              fillLimitPeriod="Day"
              fillLimitPerPeriod={fillLimitPerPeriod}
              handleChangeFillLimitPerPeriod={handleChangeFillLimitPerPeriod}
            ></FillLimitPerPeriodInput>
            <ProductsAuthorizedInput
              allProductsAuthorized={allProductsAuthorized}
              dieselExhaustFluid={dieselExhaustFluidAuthorized}
              handleDieselExhaustFluid={handleDieselExhaustFluidAuthorized}
              compressedNaturalGas={compressedNaturalGas}
              handleCompressedNaturalGas={handleCompressedNaturalGas}
              dieselAuthorized={dieselAuthorized}
              oilAuthorized={oilAuthorized}
              unleadedAuthorized={unleadedAuthorized}
              mediumUnleadedAuthorized={mediumUnleadedAuthorized}
              premiumUnleadedAuthorized={premiumUnleadedAuthorized}
              offRoadAuthorized={offRoadDieselAuthorized}
              handleAllProductsAuthorized={handleAllProductsAuthorized}
              handleDieselAuthorized={handleDieselAuthorized}
              handleOilAuthorized={handleOilAuthorized}
              handleUnleadedAuthorized={handleUnleadedAuthorized}
              handleMediumUnleadedAuthorized={handleMediumUnleadedAuthorized}
              handlePremiumUnleadedAuthorized={handlePremiumUnleadedAuthorized}
              handleOffRoadAuthorized={handleOffRoadAuthorized}
            ></ProductsAuthorizedInput>
            <DepartmentInput department={department} handleChangeDepartment={handleChangeDepartment}></DepartmentInput>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cancel</Button>
            <Button type="submit">Add Card Order</Button>
          </DialogActions>
        </ValidatorForm>
      </>
    </Dialog>
  )
}
export default SingleCardOrderModal
