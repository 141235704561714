import React, { useEffect, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import makeStyles from '@material-ui/core/styles/makeStyles'
import axios from 'axios'
import { useStore } from '../../store/StoreProvider'
import ShowError from '../Messages/ShowError'

const useStyles = makeStyles({
  cardHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  root: {
    minWidth: 275,
  },
  title: {
    justifyContent: 'center',
    display: 'flex',
    paddingBottom: 10,
  },
  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const LegacyRegister = () => {
  const { setDisableLegacyEmailPopup, setUser } = useStore()
  const location = useLocation()
  const classes = useStyles()
  const { token } = useParams()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [hasRegisteredWithEmail, setHasRegisteredWithEmail] = useState<boolean>(false)
  let history = useHistory()

  // on enter disables the popup that blocks legacy users from being able to use app while unregistered
  useEffect(() => {
    setDisableLegacyEmailPopup(true)
  }, [])
  // on exit uses useEffect cleanup to re-enable the popup that blocks legacy users from being able to use app while unregistered
  // in the event that the user manually changes the url.
  useEffect(() => {
    return () => {
      setDisableLegacyEmailPopup(false)
    }
  }, [])

  const logout = () => {
    localStorage.removeItem('CFN_TOKEN')
    localStorage.removeItem('spoofUserId')
    setUser(undefined)
    history.push('/login')
  }

  const addLegacyEmail = async () => {
    console.log('token ' + JSON.stringify(decodedToken))
    const emailSubmitted = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/legacyAccountConfirmed`, {
      // verifies with token from url sent in email to confirm user has access to email
      token: location.pathname.split('+')[1],
      customerNumber: decodedToken.customerId,
      userName: decodedToken.username,
    })

    if (emailSubmitted.status === 200) {
      setHasRegisteredWithEmail(true)
    } else {
      setErrorMessage('*Account could not be registered.')
    }
  }

  const decodedToken: any = jwt_decode(token ? token : '')
  return (
    <>
      {!hasRegisteredWithEmail ? (
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" component="h2" className={classes.title}>
            Adding email address {decodedToken.customerEmail} to account {decodedToken.username}
          </Typography>
          <Typography variant="h5" component="h2" className={classes.title}>
            Customer ID: {decodedToken.customerId}
          </Typography>
          <Button variant="contained" onClick={addLegacyEmail}>
            Add Email
          </Button>
          <ShowError errorMessage={errorMessage}></ShowError>
        </CardContent>
      ) : (
        <CardContent>
          <h1>Email added for {decodedToken.username}!</h1>
          <Button variant="contained" onClick={logout}>
            Logout
          </Button>
          <ShowError errorMessage={errorMessage}></ShowError>
        </CardContent>
      )}
    </>
  )
}

export default LegacyRegister
