import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import { getCardStatus } from '../../utils/helpers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useStore } from '../../store/StoreProvider'
import CardLabelInput from './ModalInputs/CardLabelInput'
import DepartmentInput from './ModalInputs/DepartmentInput'
import ModalInformation from './ModalInputs/ModalInformation'
import FillLimitPerPeriodInput from './ModalInputs/FillLimitPerPeriodInput'
import { CardModifiedCb, CardsTableDisplay } from '../../types/types'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  leftLabel: {
    marginRight: 20,
  },
  formSection: {
    marginTop: 25,
  },
})
interface ModifyDriverCardModalProps {
  isOpen: boolean
  handleClose: () => void
  cardBeingModified: CardsTableDisplay
  cardModifiedCb: CardModifiedCb
}

export const ModifyDriverCardModal = ({
  isOpen,
  handleClose,
  cardBeingModified,
  cardModifiedCb,
}: ModifyDriverCardModalProps) => {
  const classes = useStyles()
  const { user } = useStore()

  const [cardLabel, setCardLabel] = useState('')
  const [department, setDepartment] = useState('')
  const [fillLimitPerPeriod, setFillLimitPerPeriod] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const [hasCardRequestBeenMade, setHasCardRequestBeenMade] = useState(false)

  const cardStatus = cardBeingModified.card_status
  const cardNumberBeingModified = cardBeingModified.card_num

  useEffect(() => {
    setCardLabel(cardBeingModified.card_name)
    setFillLimitPerPeriod(cardBeingModified.fill_limit_per_period)
    setDepartment(cardBeingModified.department)
  }, [cardBeingModified])

  const handleChangeCardLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardLabel(event.target.value)
  }
  const handleChangeDepartment = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDepartment(event.target.value)
  }
  const handleChangeFillLimitPerPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFillLimitPerPeriod(event.target.value)
  }
  const handleSendUpdateRequest = async () => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/cards/changeDriverCard`, {
      department: department,
      cardLabel: cardLabel,
      fillLimitPerPeriod: fillLimitPerPeriod,
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
      token: localStorage.getItem('CFN_TOKEN'),
      customerNumber: user?.id,
      cardNumber: cardNumberBeingModified,
    })

    if (response.status === 200) {
      cardModifiedCb(cardNumberBeingModified)
      setHasCardRequestBeenMade(true)
    } else {
      setErrorMessage('*Could Not Create Card Modification Request')
    }
  }

  const clearSelections = () => {
    setCardLabel('')
    setDepartment('')
    setFillLimitPerPeriod('')
    setHasCardRequestBeenMade(false)
  }
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        clearSelections()
        handleClose()
      }}
      aria-labelledby="form-dialog-title"
    >
      {!hasCardRequestBeenMade ? (
        <>
          <div className={classes.formSection}>
            <ValidatorForm onSubmit={handleSendUpdateRequest}>
              <DialogTitle id="form-dialog-title">Modify Driver Card Request</DialogTitle>
              <DialogContent>
                <ModalInformation title={'Company Name'} information={user && user.customerName}></ModalInformation>
                <ModalInformation title={'Card Number'} information={cardNumberBeingModified}></ModalInformation>
                <ModalInformation title={'Card Status'} information={getCardStatus(cardStatus)}></ModalInformation>
                <div style={{ paddingTop: 25 }}>
                  <CardLabelInput cardLabel={cardLabel} handleChangeCardLabel={handleChangeCardLabel}></CardLabelInput>
                  <DepartmentInput
                    department={department}
                    handleChangeDepartment={handleChangeDepartment}
                  ></DepartmentInput>
                  <FillLimitPerPeriodInput
                    fillLimitPerPeriod={fillLimitPerPeriod}
                    fillLimitPeriod={cardBeingModified.fill_limit_period}
                    handleChangeFillLimitPerPeriod={handleChangeFillLimitPerPeriod}
                  ></FillLimitPerPeriodInput>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    clearSelections()
                    handleClose()
                  }}
                >
                  Cancel
                </Button>
                <Button type={'submit'}>Request</Button>
              </DialogActions>
            </ValidatorForm>
          </div>
        </>
      ) : (
        <div>
          <p style={{ padding: 10 }}>New Card Modification Request Sent</p>
          <DialogActions>
            <Button
              onClick={() => {
                clearSelections()
                handleClose()
              }}
            >
              Close
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  )
}
export default ModifyDriverCardModal
