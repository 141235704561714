import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ManageSubAccountsDisplay from './ManageSubAccountsDisplay'
import Media from 'react-media'
import ManageSubAccountsResponsiveDisplay from './ManageSubAccountsResponsiveDisplay'
import { DataTableDisplayColumns } from '../../types/types'

const styles = makeStyles({
  flexContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
  },
})

const subAccountLabels: DataTableDisplayColumns = {
  columns: [{title:"Username", type:0}, {title:"", type:0}]
};

export const ManageSubAccountsPage = () => {
  const classes = styles()

  return (
    <>
      <div className={classes.flexContainer}></div>
      <div className={classes.flexContainer}>
        <Media
          query="(max-width: 879px)"
          render={() => (
            <ManageSubAccountsResponsiveDisplay labels={subAccountLabels}></ManageSubAccountsResponsiveDisplay>
          )}
        />
        <Media
          query="(min-width: 880px)"
          render={() => <ManageSubAccountsDisplay labels={subAccountLabels}></ManageSubAccountsDisplay>}
        />
      </div>
    </>
  )
}

export default ManageSubAccountsPage
