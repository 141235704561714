import React from 'react'
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
})

interface FillLimitPerPurchaseRadioGroupProps {
  fillLimitPerPurchase: string
  handleChangeFillLimitPerPurchase: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const FillLimitPerPurchaseRadioGroup = ({
  fillLimitPerPurchase,
  handleChangeFillLimitPerPurchase,
}: FillLimitPerPurchaseRadioGroupProps) => {
  const classes = useStyles()
  const fillLimits = ['10', '20', '30', '40', '50', '75', '150', '250', '500+']
  const parsedFillLimitPerPurchase = !isNaN(parseInt(fillLimitPerPurchase)) ? parseInt(fillLimitPerPurchase).toString() : ''
  const firstFillLimitShouldBeChecked = !fillLimits.includes(parsedFillLimitPerPurchase)
  return (
    <>
      <RadioGroup
        style={{ maxHeight: 250 }}
        onChange={handleChangeFillLimitPerPurchase}
        value={fillLimitPerPurchase}
      >
        <FormLabel className={classes.title}>Gallon Limit Per Purchase</FormLabel>
        <div>
          {fillLimits.map((fillLimit, i) => (
            <FormControlLabel
              key={fillLimit}
              value={fillLimit}
              control={
                <Radio
                  checked={(firstFillLimitShouldBeChecked && i === 0) || (parsedFillLimitPerPurchase === fillLimit)}
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                />
              }
              label={fillLimit}
            />
          ))}
        </div>
      </RadioGroup>
    </>
  )
}
export default FillLimitPerPurchaseRadioGroup
