import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import axios from 'axios'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import AccountNumberInput from './ModalInputs/AccountNumberInput'
import EmailInput from './ModalInputs/EmailInput'
import UserNameInput from './ModalInputs/UserNameInput'
import { useStore } from '../../store/StoreProvider'
import { getUserToSet } from '../../utils/helpers'
import { User } from '../../types/types'
import _ from 'lodash'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  leftLabel: {
    marginRight: 20,
  },
  flexContainer: {
    display: 'flex',
  },
  formSection: {
    marginTop: 25,
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
})

export const GetOldUserEmailModal: React.FunctionComponent = ({ children }) => {
  const { user, setUser, disableLegacyEmailPopup } = useStore()
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [isChangingEmail, setIsChangingEmail] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  // TODO: when 2 part auth use getUser to update here
  // useEffect(() => {
  //
  //   const getUser = async () => {
  //     console.log("resetting user")
  //     console.log("old user Is " + JSON.stringify(user))
  //     const userData: User = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/authentication/getUserData`, {
  //       token: user?.token,
  //       username: user?.username,
  //       customerNumber: user?.id,
  //       level: user?.isAdmin ? "admin" : "user"
  //     })
  //     // hopefully circumvents user undefined error
  //    if(userData.emailOnfile !== null)
  //    {
  //      const newUserWithEmail: User | undefined = user;
  //      if(newUserWithEmail)
  //      {
  //        newUserWithEmail.emailOnfile = userData.emailOnfile;
  //      }
  //      setUser(newUserWithEmail)
  //
  //    }
  //     // console.log("new user Is " + JSON.stringify(userData))
  //     // setUser(userData)
  //   }
  //   getUser().then(r => console.log(""))
  //
  // }, [])

  const handleSetAccountEmail = async () => {
    if (isChangingEmail === true) {
      setIsChangingEmail(false)
    }
    // TODO: fix repeated fields, what the fuck???
    const response = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/userSetOwnEmail`, {
      token: localStorage.getItem('CFN_TOKEN'),
      customerNumber: user?.id,
      id: user?.id,
      username: user?.username,
      customerName: user?.customerName,
      email: email,
    })

    if (response.status === 200) {
      // update user on global store
      let newUser: undefined | User = getUserToSet()
      if (newUser) {
        newUser.emailOnfile = email
        setUser(newUser)
      }
    } else {
      setErrorMessage('*Could Not Set Customer Email')
    }
  }
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }
  const navigateToChangeEmail = () => {
    // allows users to go back to first screen of modal so they can enter a different email if they make a mistake
    if (isChangingEmail === false) {
      setIsChangingEmail(true)
    }
  }

  return (
    <div>
      {console.log(user?.emailOnfile + ' user email ')}
      <Dialog
        open={!disableLegacyEmailPopup && user?.isRegistrationComplete === false}
        aria-labelledby="form-dialog-title"
      >
        {/*If registration is incomplete but there is an email on file send user to dialog that insturcts them to check their email*/}
        {isChangingEmail || user?.emailOnfile === null ? (
          <>
            {console.log((user && user?.emailOnfile === '') || user?.emailOnfile === null)}

            <ValidatorForm onSubmit={handleSetAccountEmail}>
              <DialogTitle id="form-dialog-title">Set User Email</DialogTitle>
              <DialogContent>
                <p>
                  Our new web portal requires all users to have a verified email on file. Please enter your email below.
                  A registration email will be sent to you with a link that will allow you to finish registering your
                  account.
                </p>
                <EmailInput email={email} handleChangeEmailAddress={handleChangeEmail}></EmailInput>
              </DialogContent>
              <DialogActions>
                <Button type={'submit'}>Send Registration Email</Button>
              </DialogActions>
            </ValidatorForm>
          </>
        ) : (
          <div>
            {console.log(user)}
            <p style={{ padding: 10 }}>
              A registration email has been sent to {user?.emailOnfile}. Please confirm this registration by opening the
              email and following the link.
            </p>
            <DialogActions>
              <Button onClick={navigateToChangeEmail}>Change Registration Email</Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
      {children}
    </div>
  )
}
export default GetOldUserEmailModal
