import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useHistory } from 'react-router-dom'
import Routes from '../../Routes'
import ResponsiveDrawer from './ResponsiveDrawer'
import Media from 'react-media'
import TravelCardLogo from '../../images/TravelCardLogo.png'
import CFNLogo from '../../images/CFNLogo.png'
import { useStore } from '../../store/StoreProvider'
import UserNavigationOptions from './UserNavigationOptions'
import AdminNavigationOptions from './AdminNavigationOptions'

const styles = makeStyles({
  root: {
    flexGrow: 1,
    fontFamily: 'Oswald',
    fontWeight: 600,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  backGround: {
    backgroundColor: 'white',
  },
  toolbar: {
    justifyContent: 'center!important' as 'center',
  },
  '@media (max-width: 600px)': {
    toolbar: {
      justifyContent: 'left!important' as 'center',
    },
  },
  aboveNavbar: {
    display: 'flex',
    height: 200,
    flexDirection: 'row',
    width: '100% !important',
  },
  travelCardLogoContainer: {
    display: 'flex',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  travelCardLogo: {
    maxWidth: 590,
  },
  cfnLogoContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cfnLogo: {
    maxWidth: 71,
  },
})

export const NavBar = () => {
  const classes = styles()
  const { user, setHistoryDataTableDisplay, setCardsDataTableDisplay, disableLegacyEmailPopup } = useStore()
  const history = useHistory()

  const logoutSpoofAccount = () => {
    localStorage.removeItem('spoofUserId')
    if (!user) {
      return
    }
    setCardsDataTableDisplay(undefined)
    setHistoryDataTableDisplay(undefined)
    history.push('/adminAccountsList')
  }

  return (
    <>
      <div className={classes.root}>
        <Media
          query="(min-width: 650px)"
          render={() => (
            <>
              <div className={classes.aboveNavbar}>
                <div className={classes.travelCardLogoContainer}>
                  <img src={TravelCardLogo} className={classes.travelCardLogo} />
                </div>
                <div className={classes.cfnLogoContainer}>
                  <img src={CFNLogo} className={classes.cfnLogo} />
                </div>
              </div>
            </>
          )}
        />
        {!disableLegacyEmailPopup && (
          <AppBar position="relative" className={classes.backGround}>
            <Toolbar className={classes.toolbar}>
              <Media query="(max-width: 599px)" render={() => <ResponsiveDrawer />} />
              <Media
                query="(min-width: 600px)"
                render={() =>
                  !user ||
                  user?.isAdmin === false ||
                  (user?.isAdmin === true && localStorage.getItem('spoofUserId')) ? (
                    <UserNavigationOptions logoutSpoofAccount={logoutSpoofAccount}></UserNavigationOptions>
                  ) : (
                    <AdminNavigationOptions></AdminNavigationOptions>
                  )
                }
              />
            </Toolbar>
          </AppBar>
        )}
        <div>
          <Routes />
        </div>
      </div>
    </>
  )
}
