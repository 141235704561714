import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { SingleCardOrder, VehicleCardOrder, DriverCardOrder } from '../../types/types'
import SingleCardOrderModal from '../Modals/SingleCardOrderModal'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { isDriverCardOrder, isSingleCardOrder, isVehicleCardOrder } from '../../utils/typeguards'
import Button from '@material-ui/core/Button'
import VehicleCardOrderModal from '../Modals/VehicleCardOrderModal'
import DriverCardOrderModal from '../Modals/DriverCardOrderModal'
import Card from '@material-ui/core/Card'
import axios from 'axios'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useStore } from '../../store/StoreProvider'

const styles = makeStyles({
  errorText: {
    color: '#ed2224',
  },
  successText: {
    color: '#4BB543',
  },
})
export const NewCard = () => {
  const classes = styles()
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  // const [companyName, setCompanyName] = useState('')
  const [contactPerson, setContactPerson] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [authorizedUsers, setAuthorizedUsers] = useState<string[]>([])
  const [comments, setComments] = useState('')
  const [requestedBy, setRequestedBy] = useState('')
  //const [currentAuthorizedUser, setCurrentAuthorizedUser] = useState('')
  const [cardOrders, setCardOrders] = useState<(SingleCardOrder | VehicleCardOrder | DriverCardOrder)[]>([])
  const [isSingleCardOrderModalOpen, setIsSingleCardOrderModalOpen] = useState(false)
  const [isVehicleCardOrderModalOpen, setIsVehicleCardOrderModalOpen] = useState(false)
  const [isDriverCardOrderModalOpen, setIsDriverCardOrderModalOpen] = useState(false)
  const { user } = useStore()

  const addCardOrder = (cardOrder: SingleCardOrder | VehicleCardOrder | DriverCardOrder) => {
    if (cardOrder) {
      // This is the equivalent of array.push() with hooks
      setCardOrders((cardOrders) => [...cardOrders, cardOrder])
    }
  }
  const removeCardOrder = (i: number) => {
    setCardOrders(cardOrders.filter((item, index) => index !== i))
  }
  const handleNewSingleCardModalOpen = () => {
    setIsSingleCardOrderModalOpen(true)
  }
  const handleNewSingleCardModalClose = () => {
    setIsSingleCardOrderModalOpen(false)
  }
  const handleNewVehicleCardModalOpen = () => {
    setIsVehicleCardOrderModalOpen(true)
  }
  const handleNewVehicleCardModalClose = () => {
    setIsVehicleCardOrderModalOpen(false)
  }
  const handleNewDriverCardModalOpen = () => {
    setIsDriverCardOrderModalOpen(true)
  }
  const handleNewDriverCardModalClose = () => {
    setIsDriverCardOrderModalOpen(false)
  }
  const removeAuthorizedUser = (i: number) => {
    setAuthorizedUsers(authorizedUsers.filter((item, index) => index !== i))
  }
  const handleChangeComments = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value)
  }

  const handleChangeRequestedBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestedBy(event.target.value)
  }
  const submitOrders = async () => {
    try {
      const data = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/cards/orderCards`, {
        token: localStorage.getItem('CFN_TOKEN'),
        orders: cardOrders,
        companyName: user?.customerName,
        authorizedUsers: authorizedUsers,
        comments: comments,
        requestedBy: requestedBy,
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
      })

      if (data.status === 200) {
        setCardOrders([])
        setContactPerson('')
        setPhoneNumber('')
        setEmailAddress('')
        setAuthorizedUsers([])
        setComments('')
        setErrorMessage('')
        setSuccessMessage('*Cards Successfully Ordered')
      } else {
        setSuccessMessage('')
        setErrorMessage('*Could Not Order Cards')
      }
    } catch (e) {
      setSuccessMessage('')
      setErrorMessage('*Could Not Order Cards')
    }
  }
  return (
    <div style={{ marginTop: 10 }}>
      <SingleCardOrderModal
        isOpen={isSingleCardOrderModalOpen}
        handleClose={handleNewSingleCardModalClose}
        handleSetCurrentCardOrder={addCardOrder}
      ></SingleCardOrderModal>
      <VehicleCardOrderModal
        isOpen={isVehicleCardOrderModalOpen}
        handleClose={handleNewVehicleCardModalClose}
        handleSetCurrentCardOrder={addCardOrder}
      ></VehicleCardOrderModal>
      <DriverCardOrderModal
        isOpen={isDriverCardOrderModalOpen}
        handleClose={handleNewDriverCardModalClose}
        handleSetCurrentCardOrder={addCardOrder}
      ></DriverCardOrderModal>
      <ValidatorForm onSubmit={submitOrders}>
        <Card style={{ padding: 10 }}>
          <Typography id="form-dialog-title">Account Details</Typography>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              style={{ paddingLeft: '0px !important', marginLeft: '0 !important', textAlign: 'left', marginTop: 25 }}
            >
              Company Name: {user?.customerName}
            </Typography>
            <Typography
              style={{ paddingLeft: '0px !important', marginLeft: '0 !important', textAlign: 'left', marginBottom: 0 }}
            >
              Company Number: {user?.id}
            </Typography>
            {authorizedUsers.length > 0 && (
              <ul style={{ listStyleType: 'none' }}>
                {authorizedUsers.length > 0 &&
                  authorizedUsers.map((item, i) => (
                    <div
                      style={{
                        display: 'flex',
                        padding: '0 !important',
                        margin: 0,
                      }}
                    >
                      <IconButton
                        style={{ padding: '0 !important' }}
                        onClick={() => {
                          removeAuthorizedUser(i)
                        }}
                      >
                        <HighlightOffIcon></HighlightOffIcon>
                      </IconButton>
                      <li key={i} style={{ marginTop: 10 }}>
                        {item}
                      </li>
                    </div>
                  ))}
              </ul>
            )}
            <TextField margin="dense" id="requested_by" label="Requested By" type="requested_by" onChange={handleChangeRequestedBy} />
            <TextField margin="dense" id="name" label="Comments" type="name" onChange={handleChangeComments} />
          </div>
          <Button variant="contained" style={{ marginRight: 10, marginTop: 10 }} onClick={handleNewSingleCardModalOpen}>
            Add Single Card
          </Button>
          <Button
            variant="contained"
            style={{ marginRight: 10, marginTop: 10 }}
            onClick={handleNewVehicleCardModalOpen}
          >
            Add Vehicle Card
          </Button>
          <Button variant="contained" style={{ marginRight: 10, marginTop: 10 }} onClick={handleNewDriverCardModalOpen}>
            Add Driver Card
          </Button>
          <Button variant="contained" style={{ marginTop: 10 }} type={'submit'}>
            {cardOrders.length > 1 ? 'Submit Orders' : 'Submit Order'}
          </Button>
          {successMessage && <p className={classes.successText}>{successMessage}</p>}
          {errorMessage && <p className={classes.errorText}>{errorMessage}</p>}
        </Card>
      </ValidatorForm>
      {cardOrders.length > 0 &&
        cardOrders.map((item, i) => (
          <>
            {isSingleCardOrder(item) ? (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <IconButton
                    style={{ padding: '0 !important' }}
                    onClick={() => {
                      removeCardOrder(i)
                    }}
                  >
                    <HighlightOffIcon></HighlightOffIcon>
                  </IconButton>
                  <div style={{ paddingTop: '25 !important' }}>
                    <p>{'Card ' + (i + 1) + ' - SINGLE'}</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {
                      <ul style={{ listStyleType: 'none' }}>
                        <li>Department: {item.department}</li>
                        <li>Card Label: {item.cardLabel}</li>
                        <li>
                          Has Oregon PUC Plates:
                          {item.oregonPucPlates.toString()}
                        </li>
                        <li>PUC Permit Number: {item.pucPermitNumber}</li>
                        <li>Fill Limit Per Purchase: {item.fillLimitPerPurchase}</li>
                        <li>Fill Limit Per Day: {item.fillLimitPerDay}</li>
                      </ul>
                    }
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ) : isVehicleCardOrder(item) ? (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                  <IconButton
                    style={{ padding: '0 !important' }}
                    onClick={() => {
                      removeCardOrder(i)
                    }}
                  >
                    <HighlightOffIcon></HighlightOffIcon>
                  </IconButton>
                  <p>{'Card ' + (i + 1) + ' - VEHICLE'}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {
                      <ul style={{ listStyleType: 'none' }} key={i}>
                        <li>Department: {item.department}</li>
                        <li>Vehicle Number: {item.vehicleNumber}</li>
                        <li>Has Oregon PUC Plates: {item.oregonPucPlates.toString()}</li>
                        <li>PUC Permit Number: {item.pucPermitNumber}</li>
                        <li>Fill Limit Per Purchase: {item.fillLimitPerPurchase}</li>
                        <li>Vehicle Number: {item.vehicleNumber}</li>
                        <li>Vehicle Description: {item.vehicleDescription}</li>
                      </ul>
                    }
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ) : (
              isDriverCardOrder(item) && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <IconButton
                      style={{ padding: '0 !important' }}
                      onClick={() => {
                        removeCardOrder(i)
                      }}
                    >
                      <HighlightOffIcon></HighlightOffIcon>
                    </IconButton>
                    <p>{'Card ' + (i + 1) + ' - DRIVER'}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <ul style={{ listStyleType: 'none' }} key={i}>
                        <li>Department: {item.department}</li>
                        <li>Card Label: {item.cardLabel}</li>
                        <li>Fill Limit Per Day: {item.transactionLimitPerDay}</li>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )
            )}
          </>
        ))}
    </div>
  )
}

export default NewCard
