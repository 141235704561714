import React, { useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Typography from '@material-ui/core/Typography'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import makeStyles from '@material-ui/core/styles/makeStyles'
import axios from 'axios'
import ShowError from '../Messages/ShowError'

const useStyles = makeStyles({
  cardHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  root: {
    minWidth: 275,
  },
  title: {
    justifyContent: 'center',
    display: 'flex',
    paddingBottom: 10,
  },
  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const ResetPassword = () => {
  const location = useLocation()
  const classes = useStyles()
  const [password, setPassword] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [hasSubmittedPassword, setHasSubmittedPassword] = useState<boolean>(false)
  let history = useHistory()

  const returnHome = () => {
    history.push('/transactions')
  }
  const decodedToken: any = jwt_decode(location.pathname.split('+')[1] ? location.pathname.split('+')[1] : '')

  const resetPassword = async () => {
    if (password === confirmPassword) {
      const changePassword = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/changeAccountPassword`, {
        // verifies with token from url sent in email to confirm user has access to email
        token: location.pathname.split('+')[1],
        password: password,
        customerNumber: decodedToken.customerId,
      })
      console.log(changePassword.status)
      if (changePassword.status === 200) {
        setHasSubmittedPassword(true)
      }
    } else {
      setErrorMessage('*Could Not Update Password')
    }
  }

  return (
    <>
      {!hasSubmittedPassword ? (
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" component="h2" className={classes.title}>
            Customer ID: {decodedToken.customerId}
          </Typography>
          <div className={classes.controlWrapper}>
            <TextField
              onChange={(event: any) => setPassword(event.target.value)}
              value={password}
              style={{ marginBottom: 20 }}
              label={'Enter New Password'}
              type="password"
            />
            <TextField
              onChange={(event: any) => setConfirmPassword(event.target.value)}
              value={confirmPassword}
              style={{ marginBottom: 20 }}
              label={'Confirm New Password'}
              type="password"
            />

            <Button variant="contained" onClick={resetPassword}>
              Reset Password
            </Button>
            <ShowError errorMessage={errorMessage}></ShowError>
          </div>
        </CardContent>
      ) : (
        <CardContent>
          <Button variant="contained" onClick={returnHome}>
            Return Home
          </Button>
        </CardContent>
      )}
    </>
  )
}

export default ResetPassword
