

// TODO: Replace this with a backend

import {DataTableDisplayColumns} from "./types/types";

export const historyTable:DataTableDisplayColumns = {
    columns: [
        {title:"", type:0},
        {title:"Date", type:0},
        {title:"Card", type:0},
        {title:"Card Number", type:0},
        {title:"Vehicle", type:0},
        {title:"Vehicle Number", type:0},
        {title:"Misc Entry", type:0},
        {title:"Invoice", type:0},
        {title:"Amount", type:0},
        {title:"Gallons", type:0},
        {title:"PPG", type:0},
        {title:"Product", type:0},
        {title:"Location Number", type:0},
        {title:"Location Name", type:0},
    ]
};

export const listCardsLabels:DataTableDisplayColumns = {
    columns: [
        {title:"Card Number", type:0},
        {title:"Status", type:0} ,
        {title:"Vehicle Description", type:0},
        {title:"Card Label", type:0},
        {title:"Authorized Products", type:0},
        {title:"Fill Limit", type:0},
        {title:"Card Type", type:0},
    ]
};

export const allAccountsLabels:DataTableDisplayColumns = {
    columns: [{title:"Customer Number", type:0}, {title:"Customer Name", type:0}]
};
export const statementLabels:DataTableDisplayColumns = {
    columns: [{title:"Statement Date", type:0}, {title:"Statement ID", type:0}]
};
export const validCardTypes = [6, 11, 7, 9, 8, 10]


