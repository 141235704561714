import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  formSection: {
    marginTop: 25,
  },
})

interface VehicleNumberInputProps {
  vehicleNumber: string
  handleChangeVehicleNumber: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const VehicleNumberInput = ({ vehicleNumber, handleChangeVehicleNumber }: VehicleNumberInputProps) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.formSection}>
        <FormLabel className={classes.title}>Vehicle Number</FormLabel>
        <TextValidator
          name={'vehicleNumber'}
          value={vehicleNumber}
          margin="dense"
          id="name"
          type="vehicleNumber"
          style={{
            display: 'flex',
          }}
          onChange={handleChangeVehicleNumber}
        />
      </div>
    </>
  )
}
export default VehicleNumberInput
