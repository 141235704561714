import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { DriverCardOrder, SingleCardOrder, VehicleCardOrder } from '../../types/types'
import CardLabelInput from './ModalInputs/CardLabelInput'
import DepartmentInput from './ModalInputs/DepartmentInput'
import TransactionLimitPerDayInput from './ModalInputs/TransactionLimitPerDayInput'

interface DriverCardOrderModalProps {
  isOpen: boolean
  handleClose: () => void
  handleSetCurrentCardOrder: (cardOrder: SingleCardOrder | VehicleCardOrder | DriverCardOrder) => void
}

export const DriverCardOrderModal = ({ isOpen, handleClose, handleSetCurrentCardOrder }: DriverCardOrderModalProps) => {
  const [department, setDepartment] = useState('')
  const [transactionLimitPerDay, setTransactionLimitPerDay] = useState('')
  const [label, setLabel] = useState('')

  const buildAndAddOrder = () => {
    const orderToAdd: DriverCardOrder = {
      orderType: 'driver',
      cardLabel: label,
      department: department,
      transactionLimitPerDay: transactionLimitPerDay,
    }
    handleSetCurrentCardOrder(orderToAdd)
    closeModal()
  }

  const closeModal = () => {
    clearSelections()
    handleClose()
  }

  const handleChangeLabel = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLabel(event.target.value as string)
  }

  const handleChangeDepartment = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDepartment(event.target.value as string)
  }
  const handleChangeTransactionLimitPerDay = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTransactionLimitPerDay(event.target.value as string)
  }

  const clearSelections = () => {
    setTransactionLimitPerDay('')
    setLabel('')
    setDepartment('')
  }
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        clearSelections()
        handleClose()
      }}
      onBackdropClick={clearSelections}
      aria-labelledby="form-dialog-title"
    >
      <>
        <ValidatorForm onSubmit={buildAndAddOrder}>
          <DialogTitle id="form-dialog-title">Driver Card Order Form</DialogTitle>
          <DialogContent>
            <CardLabelInput cardLabel={label} handleChangeCardLabel={handleChangeLabel}></CardLabelInput>
            <DepartmentInput department={department} handleChangeDepartment={handleChangeDepartment}></DepartmentInput>
            <TransactionLimitPerDayInput
              transactionLimitPerDay={transactionLimitPerDay}
              handleChangeTransactionLimitPerDay={handleChangeTransactionLimitPerDay}
            ></TransactionLimitPerDayInput>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>Cancel</Button>
            <Button type="submit">Add Card Order</Button>
          </DialogActions>
        </ValidatorForm>
      </>
    </Dialog>
  )
}
export default DriverCardOrderModal
