import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
    flexWrap: 'wrap',
    maxWidth: '50%',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
  },
})

interface ModalInformationProps {
  title: string
  information: string | number | undefined
}

export const ModalInformation = ({ title, information }: ModalInformationProps) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.flexContainer}>
        <div className={classes.title}>{title}:</div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', flex: 2 }}>{information}</div>
        <div style={{ width: 100 }}></div>
      </div>
    </>
  )
}
export default ModalInformation
