import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {createTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import axios from 'axios'
import { User } from '../../types/types'
import { useStore } from '../../store/StoreProvider'
import { useHistory, withRouter } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { useKeyPress } from '@react-typed-hooks/use-key-press'
import ShowError from "../Messages/ShowError";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        TruckNTravel
      </Link>
      {new Date().getFullYear()}
    </Typography>
  )
}

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'white',
        },
        '&:hover $notchedOutline': {
          borderColor: 'white',
        },
        '&$focused $notchedOutline': {
          borderColor: 'white',
        },
        '&$legendLabelled': {
          color: 'white',
        },
        '&$PrivateNotchedOutline-legend': {
          color: 'white',
        },
      },
    },
  },
})

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'white',
  },

  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'black',
    backgroundColor: '#ed2224',
  },
  textField: {
    color: 'white',
    borderColor: 'white !important',
  },
}))

export const AdminLogin = () => {
  const classes = useStyles()
  let history = useHistory()
  const { setUser } = useStore()

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const isEnterPressed = useKeyPress({ targetKey: 'Enter' })
  useEffect(() => {
    if (isEnterPressed === true) {
      // only using then to avoid warnings from React
      authenticateAdmin().then((r) => console.log(''))
    }
  }, [isEnterPressed])

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }
  const authenticateAdmin = async () => {
    try {
      const response = await axios.post<User>(`${process.env.REACT_APP_BASE_ROOT_URL}/authentication/login`, {
        username: username,
        password: password,
        level: 'admin',
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
      })
      if (response) {
        localStorage.setItem('CFN_TOKEN', response.data.token)
        const decodedToken: any = jwt_decode(response.data.token)
        const userToSetAsAdmin: User = {
          token: response.data.token,
          username: decodedToken.username,
          isAdmin: true,
          id: decodedToken.id,
          isRegistrationComplete: true,
          emailOnfile: '',
        }
        setUser(userToSetAsAdmin)
        history.push('/adminAccountsList')
      }
    }
    catch{
      setErrorMessage('*Invalid login. Please login with valid email address and password')
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <MuiThemeProvider theme={theme}>
          <Typography component="h1" variant="h5" className={classes.title}>
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              onChange={handleChangeUsername}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="email"
              autoComplete="username"
            />
            <TextField
              onChange={handleChangePassword}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={authenticateAdmin}
            >
              Sign In
            </Button>
            { errorMessage !== '' &&
            <ShowError errorMessage={errorMessage}></ShowError>
            }
          </form>
        </MuiThemeProvider>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  )
}

export default withRouter(AdminLogin)
