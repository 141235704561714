import React, { useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Typography from '@material-ui/core/Typography'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import makeStyles from '@material-ui/core/styles/makeStyles'
import axios from 'axios'
import ShowError from '../Messages/ShowError'
import { useStore } from '../../store/StoreProvider'

const useStyles = makeStyles({
  cardHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  root: {
    minWidth: 275,
  },
  title: {
    justifyContent: 'center',
    display: 'flex',
    paddingBottom: 10,
  },
  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const Register = () => {
  const { setUser } = useStore()
  const location = useLocation()
  const classes = useStyles()
  const { accountNumber } = useParams()
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [hasSubmittedPassword, setHasSubmittedPassword] = useState<boolean>(false)
  let history = useHistory()

  const returnHome = () => {
    localStorage.removeItem('CFN_TOKEN')
    localStorage.removeItem('spoofUserId')
    setUser(undefined)
    history.push('/login')
  }

  const registerCustomer = async () => {
    if (password !== confirmPassword) {
      setErrorMessage('*Passwords do not match')
    } else if (password === '' || confirmPassword === '') {
      setErrorMessage('*Passwords cannot be empty')
    } else {
      const isRegistered = await axios.post(
        `${process.env.REACT_APP_BASE_ROOT_URL}/accounts/setInitialAccountPassword`,
        {
          // verifies with token from url sent in email to confirm user has access to email
          token: location.pathname.split('+')[1],
          password: password,
          customerNumber: decodedToken.customerId,
        }
      )

      if (isRegistered.status === 200) {
        setHasSubmittedPassword(true)
      }
    }
  }

  const decodedToken: any = jwt_decode(accountNumber ? accountNumber : '')
  return (
    <>
      {!hasSubmittedPassword ? (
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" component="h2" className={classes.title}>
            Registering customer {decodedToken.customerName}
          </Typography>
          <Typography variant="h5" component="h2" className={classes.title}>
            Customer ID {decodedToken.customerId}
          </Typography>
          <Typography variant="h5" component="h2" className={classes.title}>
            Username: admin
          </Typography>
          <div className={classes.controlWrapper}>
            <TextField
              onChange={(event: any) => setPassword(event.target.value)}
              value={password}
              style={{ marginBottom: 20 }}
              label={'Enter Admin Password'}
              type={'password'}
            />
            <TextField
              onChange={(event: any) => setConfirmPassword(event.target.value)}
              value={confirmPassword}
              style={{ marginBottom: 20 }}
              label={'Confirm Admin Password'}
              type={'password'}
            />

            <Button variant="contained" onClick={registerCustomer}>
              Register Account
            </Button>
            <ShowError errorMessage={errorMessage}></ShowError>
          </div>
        </CardContent>
      ) : (
        <CardContent>
          <h1>Registration complete for {decodedToken.customerId}!</h1>
          <Button variant="contained" onClick={returnHome}>
            Return Home
          </Button>
        </CardContent>
      )}
    </>
  )
}

export default Register
