import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { IconButton, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { Link, useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { useStore } from '../../store/StoreProvider'
import ClearIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  menuButton: {
    color: 'black',
  },
  menuStyle: {
    display: 'flex',
    justifyContent: 'left!important',
  },
  typography: {
    fontFamily: 'Montserrat',
    textTransform: 'none',
    fontSize: '1rem',
  },
})

type Anchor = 'left'
// TODO: Make this code not ugly
// TODO: Tie this in with global state
export default function ResponsiveDrawer() {
  const { user, setUser, setHistoryDataTableDisplay, setCardsDataTableDisplay } = useStore()
  const classes = useStyles()
  const [state, setState] = React.useState({
    left: false,
  })
  const history = useHistory()

  const logoutUser = () => {
    localStorage.removeItem('CFN_TOKEN')
    localStorage.removeItem('spoofUserId')
    setUser(undefined)
    history.push('/')
  }
  const logoutSpoofAccount = () => {
    localStorage.removeItem('spoofUserId')
    if (!user) {
      return
    }
    setCardsDataTableDisplay(undefined)
    setHistoryDataTableDisplay(undefined)
    history.push('/adminAccountsList')
  }

  const userLabels = ['Transactions', 'Cards', 'Statements', 'Subaccounts', 'Make A Payment']
  const userRoutes = ['/transactions', '/cards', 'statements', '/subaccounts', '/makeapayment']
  const adminLabels = [
    'Add Account',
    'Change Password',
    'Delete Account',
    'View All Accounts',
    'Remove Duplicate Invoices',
  ]
  const adminRoutes = [
    '/addaccount',
    '/changepassword',
    'deleteaccount',
    '/adminaccountslist',
    '/removeduplicateinvoices',
  ]

  const labels = !user?.isAdmin ? userLabels : adminLabels
  const routes = !user?.isAdmin ? userRoutes : adminRoutes

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const list = (anchor: Anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {user && <ListItem button>Logged in as {user.username}</ListItem>}
      {localStorage.getItem('spoofUserId') && (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <Typography className={classes.typography} style={{ padding: 0, maxWidth: '75%' }}>
            Spoofing Customer Number: {localStorage.getItem('spoofUserId')}
          </Typography>
          <IconButton onClick={logoutSpoofAccount} style={{ padding: 0 }}>
            <ClearIcon></ClearIcon>
          </IconButton>
        </div>
      )}
      <List>
        {labels.map((text, index) => (
          <ListItem button key={text}>
            <Button disableRipple={true} component={Link} to={routes[index]} className={classes.typography}>
              {text}
            </Button>
          </ListItem>
        ))}
        <ListItem button>
          <Button disableRipple={true} component={Link} to={'/'} onClick={logoutUser} className={classes.typography}>
            Logout
          </Button>
        </ListItem>
      </List>
    </div>
  )

  return (
    <>
      {(['left'] as Anchor[]).map((anchor) => (
        <React.Fragment key={anchor}>
          <div className={classes.menuStyle}>
            <IconButton aria-label="menu" onClick={toggleDrawer(anchor, true)} className={classes.menuButton}>
              <MenuIcon />
            </IconButton>
          </div>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </>
  )
}
