import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { ListCardsDataDisplayComponent, CardsTableDisplay, DataTableDisplayColumns, CardModifiedCb } from '../../types/types'
import { useStore } from '../../store/StoreProvider'
import TablePagination from '@material-ui/core/TablePagination'
import { TextField } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import BuildIcon from '@material-ui/icons/Build'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import ToggleOffIcon from '@material-ui/icons/ToggleOff'
import InfoSharpIcon from '@material-ui/icons/InfoSharp'
import ToggleOnIcon from '@material-ui/icons/ToggleOn'
import { getCardStatus } from '../../utils/helpers'
import NewCardAlarmModal from '../Modals/NewCardAlarmModal'
import Tooltip from '@material-ui/core/Tooltip'
import ReplaceCardModal from '../Modals/ReplaceCardModal'
import InvalidateOrValidateCardModal from '../Modals/InvalidateOrValidateCardModal'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import ModifySingleCardModal from '../Modals/ModifySingleCardModal'
import ModifyDriverCardModal from '../Modals/ModifyDriverCardModal'
import ModifyVehicleCardModal from '../Modals/ModifyVehicleCardModal'
import { validCardTypes } from '../../Db'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: '#ededee',
  },
  headers: {
    fontWeight: 'bold',
  },
  searchAndClearWrapper: {
    display: 'flex',
    paddingRight: 20,
  },
  historyTableStyles: {
    width: '100 !important',
  },
  navButtons: {
    margin: 20,
  },
  searchIcon: {
    color: '#FF2045',
  },
})

export const ListCardsDataDisplay: React.FunctionComponent<ListCardsDataDisplayComponent> = ({ columns, cardModifiedCb }) => {
  let history = useHistory()
  const classes = useStyles()
  const { setCardsFilteredDataTable, cardsDataTable, cardsFilteredDataTable } = useStore()
  const [page, setPage] = useState(0)
  
  const [openModifyCardDialog, setOpenModifyCardDialog] = useState(false)
  const [openCardAlarmModal, setOpenCardAlarmModal] = useState(false)
  const [openReplaceCardModal, setOpenReplaceCardModal] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusDropdownValues, setStatusDropdownValues] = useState<string[]>([])
  const [authorizedProductsDropdownValues, setAuthorizedProductsDropdownValues] = useState<string[]>([])
  const [fillLimitDropdownValues, setfillLimitDropdownValues] = useState<string[]>([])
  const [cardTypeDropdownValues, setCardTypeDropdownValues] = useState<string[]>([])

  const [isModifyingSingleCard, setIsModifyingSingleCard] = useState(false)
  const [isModifyingDriverCard, setIsModifyingDriverCard] = useState(false)
  const [isModifyingVehicleCard, setIsModifyingVehicleCard] = useState(false)

  // TODO: Popover

  const [cardNumberFilterValue, setCardNumberFilterValue] = useState('')
  const [fillLimitFilterValue, setFillLimitFilterValue] = useState(0)
  const [cardLabelFilterValue, setCardLabelFilterValue] = useState('')
  const [vehicleFilterValue, setVehicleFilterValue] = useState('')
  const [authorizedProductsFilterValue, setAuthorizedProductsFilterValue] = useState(0)
  const [cardTypeFilterValue, setCardTypeFilterValue] = useState('')

  const [statusFilterValue, setStatusFilterValue] = useState<string>('')

  const [cardBeingModified, setCardBeingModified] = useState<CardsTableDisplay | null>(null)

  const [hasAlarmBeenCreated, setHasAlarmBeenCreated] = useState(false)
  const [isACardBeingInvalidatedOrValidated, setIsACardBeingInvalidatedOrValidated] = useState(false)

  const filterValues = [
    cardNumberFilterValue,
    statusFilterValue,
    vehicleFilterValue,
    cardLabelFilterValue,
    authorizedProductsFilterValue,
    fillLimitFilterValue,
    cardTypeFilterValue,
  ]

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const openCardModificationModal = (cardTypeCode: number) => {
    switch (cardTypeCode) {
      case 6:
        setIsModifyingSingleCard(true)
        break
      case 11:
        setIsModifyingSingleCard(true)
        break
      case 7:
        setIsModifyingDriverCard(true)
        break
      case 9:
        setIsModifyingDriverCard(true)
        break
      case 8:
        setIsModifyingVehicleCard(true)
        break
      case 10:
        setIsModifyingVehicleCard(true)
        break
    }
  }

  const generateValuesForStatusDropdown = (values: CardsTableDisplay[] | undefined) => {
    const statusTypes: string[] = []
    values
      ? values.forEach(
          (element) =>
            !statusTypes.includes(getCardStatus(element.card_status)) &&
            statusTypes.push(getCardStatus(element.card_status))
        )
      : console.log('')
    setStatusDropdownValues(statusTypes)
  }

  const generateValuesForAuthorizedProductsDropdown = (values: CardsTableDisplay[] | undefined) => {
    const authTypes: string[] = []
    values
      ? values.forEach(
          (element) =>
            !authTypes.includes(element.product_auth_code) &&
            authTypes.push(element.product_auth_code)
        )
      : console.log('')
    setAuthorizedProductsDropdownValues(authTypes)
  }

  const generateValuesForFillLimitDropdown = (values: CardsTableDisplay[] | undefined) => {
    const fillLimits: string[] = []

    values
      ? values.forEach(
          (element) =>
            !fillLimits.includes(element.fill_limit_per_purchase) &&
            fillLimits.push(element.fill_limit_per_purchase)
        )
      : console.log('')
    setfillLimitDropdownValues(fillLimits)
  }

  const generateValuesForCardTypeDropdown = (values: CardsTableDisplay[] | undefined) => {
    const cardTypes: string[] = []

    values?.forEach(element =>
      !cardTypes.includes(element.cardType) &&
      cardTypes.push(element.cardType)
    )
    setCardTypeDropdownValues(cardTypes)
  }

  const clearAllFilters = () => {
    setCardNumberFilterValue('')
    setStatusFilterValue('')
    setVehicleFilterValue('')
    setCardLabelFilterValue('')
    setAuthorizedProductsFilterValue(0)
    setFillLimitFilterValue(0)
  }

  const handleStatusFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusFilterValue(event.target.value as string)
  }

  const handleAuthorizedProductsFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAuthorizedProductsFilterValue(event.target.value as number)
  }

  const handleFillLimitFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFillLimitFilterValue(event.target.value as number)
  }

  const handleCardTypeFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCardTypeFilterValue(event.target.value as string)
  }

  const handleInvalidateOrValidateClickOpen = (cardBeingModified: CardsTableDisplay) => {
    setCardBeingModified(cardBeingModified)
    setIsACardBeingInvalidatedOrValidated(true)
  }

  const handleModifyCardClickOpen = (cardBeingModified: CardsTableDisplay) => {
    setCardBeingModified(cardBeingModified)
    openCardModificationModal(cardBeingModified.cardTypeCode)
  }
  const handleReplaceCardClickOpen = (cardBeingModified: CardsTableDisplay) => {
    setCardBeingModified(cardBeingModified)
    setOpenReplaceCardModal(true)
  }

  const handleInvalidateOrValidateClickClose = () => {
    setCardBeingModified(null)
    setIsACardBeingInvalidatedOrValidated(false)
  }

  const handleReplaceCardClickClose = () => {
    setCardBeingModified(null)
    setOpenReplaceCardModal(false)
  }

  const handleModifyCardClickClose = () => {
    setCardBeingModified(null)
    setOpenModifyCardDialog(false)
    setIsModifyingSingleCard(false)
    setIsModifyingDriverCard(false)
    setIsModifyingVehicleCard(false)
  }

  const handleCardAlarmClickClose = () => {
    setCardBeingModified(null)
    setHasAlarmBeenCreated(false)
    setOpenCardAlarmModal(false)
  }

  const filterTable = () => {
    // When filtering return to first page of dataset
    setPage(0)
    // Makes a copy of all data on data table. This serves as source of truth for all filters going down the line
    let tempFilteredTable: CardsTableDisplay[] | undefined = cardsDataTable

    if (cardLabelFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => 
        row.card_name?.toString().toLowerCase().startsWith(cardLabelFilterValue.toLowerCase()))
    }
    if (statusFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        getCardStatus(row.card_status)?.toString().startsWith(statusFilterValue)
      )
    }
    if (vehicleFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => 
        row.vehicleDescription?.toString().toLowerCase().startsWith(vehicleFilterValue.toLowerCase()))
    }
    if (authorizedProductsFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.product_auth_code?.startsWith(authorizedProductsFilterValue.toString())
      )
    }
    if (fillLimitFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.fill_limit_per_purchase?.toString().startsWith(fillLimitFilterValue.toString())
      )
    }
    if (cardTypeFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.cardType?.toString().startsWith(cardTypeFilterValue.toString())
      )
    }
    if (cardNumberFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.card_num?.startsWith(cardNumberFilterValue))
    }

    createDropdowns()
    setCardsFilteredDataTable && setCardsFilteredDataTable(tempFilteredTable ? tempFilteredTable : cardsDataTable)
  }

  const createDropdowns = () => {
    generateValuesForStatusDropdown(cardsDataTable)
    generateValuesForAuthorizedProductsDropdown(cardsDataTable)
    generateValuesForFillLimitDropdown(cardsDataTable)
    generateValuesForCardTypeDropdown(cardsDataTable)
  }

  const getCardsTableRow = (row: CardsTableDisplay, i: number) => {
    return (
      <TableRow key={i} style={!row.cardTypeCode ? { backgroundColor: 'yellow' } : { backgroundColor: 'white' }}>
        <TableCell component="th" scope="row">
          <Tooltip disableFocusListener title="Modify card">
            {validCardTypes.includes(row.cardTypeCode) ? (
              <IconButton onClick={() => handleModifyCardClickOpen(row)}>
                <BuildIcon style={{ height: 20 }}></BuildIcon>
              </IconButton>
            ) : (
              <IconButton
                style={{visibility: 'hidden'}}
              >
                <BuildIcon style={{ height: 20 }}></BuildIcon>
              </IconButton>
            )}
          </Tooltip>
          <Tooltip disableFocusListener title="Order replacement card">
            <IconButton onClick={() => handleReplaceCardClickOpen(row)}>
              <AddCircleOutlineIcon style={{ height: 20 }}></AddCircleOutlineIcon>
            </IconButton>
          </Tooltip>

          {row.card_status === 'I' ? (
            <Tooltip disableFocusListener title="Validate Card">
              <IconButton onClick={() => handleInvalidateOrValidateClickOpen(row)}>
                <ToggleOnIcon style={{ height: 20 }}></ToggleOnIcon>
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip disableFocusListener title="Invalidate Card">
              <IconButton onClick={() => handleInvalidateOrValidateClickOpen(row)}>
                <ToggleOffIcon style={{ height: 20 }}></ToggleOffIcon>
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.card_num}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.pending_edit ? 'Pending Changes' : getCardStatus(row.card_status)}
        </TableCell>
        <TableCell>{row.vehicleDescription}</TableCell>
        <TableCell>{row.card_name}</TableCell>
        <TableCell>{row.product_auth_code}</TableCell>
        <TableCell>{row.fill_limit_per_purchase}</TableCell>
        <TableCell>
          {row.cardType} <Tooltip disableFocusListener title={row.cardTypeDescription}>
            <InfoSharpIcon style={{ height: 15 }} />
          </Tooltip>
        </TableCell>
      </TableRow>
    )
  }

  useEffect(() => {
    // Any time one of the filters is updated re-run the filtering logic
    filterTable()
  }, filterValues)

  useEffect(() => {
    if (!cardsDataTable) {
      createDropdowns()
    }
    // performs initial date filtering
    filterTable()
  }, [cardsDataTable])

  // TODO: Replace this with a composable version that can accept a component as a prop for the rows
  return (
    <>
      {cardBeingModified && (
        <>
          <ModifyDriverCardModal
            isOpen={isModifyingDriverCard}
            handleClose={handleModifyCardClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          />
          <ModifySingleCardModal
            isOpen={isModifyingSingleCard}
            handleClose={handleModifyCardClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          ></ModifySingleCardModal>
          <ModifyVehicleCardModal
            isOpen={isModifyingVehicleCard}
            handleClose={handleModifyCardClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          ></ModifyVehicleCardModal>
          <InvalidateOrValidateCardModal
            isOpen={isACardBeingInvalidatedOrValidated}
            handleClose={handleInvalidateOrValidateClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          ></InvalidateOrValidateCardModal>
          <NewCardAlarmModal
            hasAlarmBeenCreated={hasAlarmBeenCreated}
            setHasAlarmBeenCreated={setHasAlarmBeenCreated}
            isOpen={openCardAlarmModal}
            handleClose={handleCardAlarmClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          ></NewCardAlarmModal>
          <ReplaceCardModal
            isOpen={openReplaceCardModal}
            handleClose={handleReplaceCardClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          ></ReplaceCardModal>
        </>
      )}
      <Paper className={classes.historyTableStyles}>
        <div className={classes.searchAndClearWrapper}>
          <IconButton onClick={() => clearAllFilters()}>
            <ClearIcon></ClearIcon>
          </IconButton>
          <Button className={classes.navButtons} variant="contained" onClick={() => history.push('/newcard')}>
            Order New Cards
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell></TableCell>
                {columns.map((label, i) => (
                  <TableCell key={i} className={classes.headers}>
                    {label.title}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <TextField
                    onChange={(event: any) => setCardNumberFilterValue(event.target.value)}
                    value={cardNumberFilterValue}
                    style={{ maxWidth: 50 }}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={statusFilterValue}
                    onChange={handleStatusFilterChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {statusDropdownValues.map((dropdownItem, i) => (
                      <MenuItem value={dropdownItem} key={i}>
                        {dropdownItem}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    onChange={(event: any) => setVehicleFilterValue(event.target.value)}
                    value={vehicleFilterValue}
                    style={{ maxWidth: 50 }}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    onChange={(event: any) => setCardLabelFilterValue(event.target.value)}
                    value={cardLabelFilterValue}
                    style={{ maxWidth: 100 }}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={authorizedProductsFilterValue}
                    onChange={handleAuthorizedProductsFilterChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {authorizedProductsDropdownValues.map((dropdownItem, i) => (
                      <MenuItem value={dropdownItem} key={i}>
                        {dropdownItem}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={fillLimitFilterValue}
                    onChange={handleFillLimitFilterChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {fillLimitDropdownValues.map((dropdownItem, i) => (
                      <MenuItem value={dropdownItem} key={i}>
                        {dropdownItem}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={cardTypeFilterValue}
                    onChange={handleCardTypeFilterChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {cardTypeDropdownValues.map((dropdownItem, i) => (
                      <MenuItem value={dropdownItem} key={i}>
                        {dropdownItem}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow></TableRow>
              {/*TODO: After the routes are more hashed out make sure to come back and add a way to dynamically wrap column data in <a> tags*/}
              {/*If data is filtered map filtered data, else map all data*/}
              {
                cardsFilteredDataTable &&
                  cardsFilteredDataTable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => getCardsTableRow(row, i))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 1000]}
          onPageChange={() => {}}
          count={cardsFilteredDataTable && cardsFilteredDataTable !== undefined ? cardsFilteredDataTable.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  )
}
export default ListCardsDataDisplay
