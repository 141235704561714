import React, { createContext, useContext, useState } from 'react'
import { HistoryTableDisplay, CardsTableDisplay, User } from '../types/types'
import { getUserToSet } from '../utils/helpers'

export interface Store {
  disableLegacyEmailPopup: boolean
  setDisableLegacyEmailPopup: (disableLegacyEmailPopup: boolean) => void
  historyDataTable?: HistoryTableDisplay[]
  setHistoryDataTableDisplay: (dataTable?: HistoryTableDisplay[]) => void
  cardsDataTable?: CardsTableDisplay[]
  setCardsDataTableDisplay: (dataTable?: CardsTableDisplay[]) => void
  historyFilteredDataTable?: HistoryTableDisplay[]
  setHistoryFilteredDataTable: (historyFilteredDataTable?: HistoryTableDisplay[]) => void
  cardsFilteredDataTable?: CardsTableDisplay[]
  setCardsFilteredDataTable?: (cardsFilteredDataTable?: CardsTableDisplay[]) => void
  user?: User
  setUser: (adminUser?: User) => void
}

export const StoreContext = createContext<Store>({} as Store)

export const useStore = () => useContext<Store>(StoreContext)

const StoreProvider: React.FunctionComponent = ({ children }) => {
  const [disableLegacyEmailPopup, setDisableLegacyEmailPopup] = useState<boolean>(false)
  const [historyDataTable, setHistoryDataTableDisplay] = useState<HistoryTableDisplay[] | undefined>(undefined)
  const [cardsDataTable, setCardsDataTableDisplay] = useState<CardsTableDisplay[] | undefined>(undefined)
  const [historyFilteredDataTable, setHistoryFilteredDataTable] = useState<HistoryTableDisplay[] | undefined>(undefined)
  const [cardsFilteredDataTable, setCardsFilteredDataTable] = useState<CardsTableDisplay[] | undefined>(undefined)
  const [user, setUser] = useState<User | undefined>(getUserToSet)

  const store: Store = {
    disableLegacyEmailPopup,
    setDisableLegacyEmailPopup,
    cardsDataTable,
    setCardsDataTableDisplay,
    historyDataTable,
    setHistoryDataTableDisplay,
    historyFilteredDataTable,
    setHistoryFilteredDataTable,
    cardsFilteredDataTable,
    setCardsFilteredDataTable,
    user,
    setUser,
  }
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export default StoreProvider
