import React from 'react'
import { DateRange, DateRangePicker, DefinedRange } from 'materialui-daterange-picker'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import DateRangeIcon from '@material-ui/icons/DateRange'
import Popper from '@material-ui/core/Popper'
import moment from 'moment';

interface DaterangePickerProps {
  definedRanges?: DefinedRange[]
  onChange: (dateRange: DateRange) => void
  initialDateRange?: DateRange
}

export const DaterangePicker: React.FunctionComponent<DaterangePickerProps> = (props: DaterangePickerProps) => {
  const now = moment().toDate()
  const defaultRanges: DefinedRange[] = [
    {label: 'Current Month', startDate: moment().startOf('month').toDate(), endDate: now},
    {label: 'Three Month', startDate: moment().subtract(3, 'M').toDate(), endDate: now},
    {label: 'Quarter', startDate: moment().startOf('quarter').toDate(), endDate: now},
    {label: 'Year', startDate: moment().startOf('year').toDate(), endDate: now},
    {label: 'All', startDate: moment.unix(0).toDate(), endDate: now},
  ]
  const {
    definedRanges = defaultRanges,
    onChange,
    initialDateRange,
  } = props
  const [daterangeIsOpen, setDaterangeIsOpen] = React.useState<boolean>(false);
  const daterangeIsOpenToggle = () => setDaterangeIsOpen(!daterangeIsOpen)
  const daterangeInputRef = React.useRef(null)
  const [currDaterange, setCurrDaterange] = React.useState<DateRange | undefined>(initialDateRange)
  const daterangeDisplatString = currDaterange &&
    currDaterange.startDate &&
    currDaterange.endDate &&
    `${currDaterange.startDate.toLocaleDateString('en-US')} - ${currDaterange.endDate.toLocaleDateString('en-US')}`
  return (
    <>
      <TextField
        onClick={daterangeIsOpenToggle}
        ref={daterangeInputRef}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <DateRangeIcon />
            </InputAdornment>
          )
        }}
        value={daterangeDisplatString}
      />
      <Popper open={daterangeIsOpen} anchorEl={daterangeInputRef.current}>
        <DateRangePicker
          open={daterangeIsOpen}
          toggle={daterangeIsOpenToggle}
          definedRanges={definedRanges}
          onChange={daterange => {
            setCurrDaterange(daterange)
            onChange(daterange)
            setDaterangeIsOpen(false)
          }}
          initialDateRange={initialDateRange}
          minDate={moment.unix(0).toDate()}
        />
      </Popper>
    </>
  )
}
