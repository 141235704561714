import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { DataTableDisplayColumns, Subaccount } from '../../types/types'
import TablePagination from '@material-ui/core/TablePagination'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import NewSubAccountModal from '../Modals/NewSubAccountModal'
import { useStore } from '../../store/StoreProvider'
import ConfirmDeleteSubaccountModal from '../Modals/ConfirmDeleteSubaccountModal'
import DataLoadingSpinner from '../Global/DataLoadingSpinner'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: '#ededee',
  },
  headers: {
    fontWeight: 'bold',
  },
  searchAndClearWrapper: {
    display: 'flex',
    paddingRight: 20,
  },
  navButtons: {
    margin: 20,
  },
})
interface SubAccountsDisplayProps {
  labels: DataTableDisplayColumns
}

export const ManageSubAccountsDisplay = ({ labels }: SubAccountsDisplayProps) => {
  const { user } = useStore()
  const classes = useStyles()
  const [subAccounts, setSubAccounts] = useState<Subaccount[] | undefined>(undefined)
  const [refreshDataTable, setRefreshDatatable] = useState(false)
  const [page, setPage] = useState(0)
  const [newSubAccountModalOpen, setNewSubAccountModalOpen] = useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [accountNumberBeingDeleted, setAccountNumberBeingDeleted] = useState(0)
  const [isCreatingNewSubaccount, setIsCreatingNewSubaccount] = useState(false)
  const [isDeletingSubaccount, setIsDeletingSubaccount] = useState(false)
  const [deleteSubAccountModalOpen, setDeleteSubAccountModalOpen] = useState(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleOpenNewSubaccountModal = () => {
    setIsCreatingNewSubaccount(false)
    setNewSubAccountModalOpen(true)
  }
  const handleNewSubAccountClose = () => {
    setNewSubAccountModalOpen(false)
  }

  const handleDeleteSubaccountModalOpen = (accountNumber: number) => {
    setIsDeletingSubaccount(false)
    setAccountNumberBeingDeleted(accountNumber)
    setDeleteSubAccountModalOpen(true)
  }
  const handleDeleteSubAccountClose = () => {
    setDeleteSubAccountModalOpen(false)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  useEffect(() => {
    const getSubAccounts = async () => {
      const data = await axios.post<Subaccount[]>(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/getSubAccounts`, {
        token: localStorage.getItem('CFN_TOKEN'),
        custNum: user?.id,
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
      })
      setSubAccounts(data.data)
    }
    if (!subAccounts || refreshDataTable) {
      getSubAccounts()
      refreshDataTable && setRefreshDatatable(false)
    }
  }, [subAccounts, refreshDataTable])

  const getSubaccountTableRow = (row: Subaccount, i: number) => {
    return (
      <TableRow key={i}>
        <TableCell component="th" scope="row">
          {row.username}
        </TableCell>
        <TableCell>
          <a href={'#'} onClick={() => handleDeleteSubaccountModalOpen(row.id)}>
            Delete Subaccount
          </a>
        </TableCell>
      </TableRow>
    )
  }

  // TODO: Replace this with a composable version that can accept a component as a prop for the rows
  return (
    <>
      {user && (
        <>
          <NewSubAccountModal
            isCreatingNewSubAccount={isCreatingNewSubaccount}
            setIsCreatingNewSubaccount={setIsCreatingNewSubaccount}
            setRefreshDataTable={setRefreshDatatable}
            isOpen={newSubAccountModalOpen}
            handleClose={handleNewSubAccountClose}
            customerNumber={user?.id}
          ></NewSubAccountModal>
          <ConfirmDeleteSubaccountModal
            handleClose={handleDeleteSubAccountClose}
            id={accountNumberBeingDeleted}
            isOpen={deleteSubAccountModalOpen}
            customerNumber={user?.id}
            isDeletingSubaccount={isDeletingSubaccount}
            setIsDeletingSubaccount={setIsDeletingSubaccount}
            setRefreshDataTable={setRefreshDatatable}
          ></ConfirmDeleteSubaccountModal>
        </>
      )}
      {!subAccounts ? (
        <DataLoadingSpinner />
      ) : (
        <Paper>
          <div className={classes.searchAndClearWrapper}>
            <Button className={classes.navButtons} variant="contained" onClick={handleOpenNewSubaccountModal}>
              Create New Subaccount
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {labels.columns.map((label, i) => (
                    <TableCell key={i} className={classes.headers}>
                      {label.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/*TODO: After the routes are more hashed out make sure to come back and add a way to dynamically wrap column data in <a> tags*/}
                {subAccounts &&
                  subAccounts
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => getSubaccountTableRow(row, i))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 1000]}
            onPageChange={() => {}}
            count={subAccounts && subAccounts !== undefined ? subAccounts.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  )
}
export default ManageSubAccountsDisplay
