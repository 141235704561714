import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import clsx from 'clsx'
import axios from 'axios'
import { getCardStatus } from '../../utils/helpers'
import { useStore } from '../../store/StoreProvider'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { CardModifiedCb, CardsTableDisplay } from '../../types/types'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  leftLabel: {
    marginRight: 20,
  },
  flexContainer: {
    display: 'flex',
  },
  checked: {},
})
interface InvalidateOrValidateCardModalProps {
  isOpen: boolean
  handleClose: () => void
  cardBeingModified: CardsTableDisplay
  cardModifiedCb: CardModifiedCb
}

export const InvalidateOrValidateCardModal = ({
  isOpen,
  handleClose,
  cardBeingModified,
  cardModifiedCb,
}: InvalidateOrValidateCardModalProps) => {
  const classes = useStyles()
  const { user } = useStore()

  const [contactPerson, setContactPerson] = useState('')
  const [comments, setComments] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [hasCardRequestBeenMade, setHasCardRequestBeenMade] = useState(false)

  const cardStatus = cardBeingModified.card_status

  const handleSendUpdateRequest = async () => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/cards/invalidateValidateCard`, {
      companyName: user?.customerName,
      cardLabel: cardBeingModified.card_name,
      cardNumber: cardBeingModified.card_num,
      action: cardStatus === 'I' ? 'Validate' : 'Invalidate',
      comments: comments,
      customerNumber: user?.id,
      contactPerson: contactPerson,
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
      token: localStorage.getItem('CFN_TOKEN'),
    })

    if (response.status === 200) {
      cardModifiedCb(cardBeingModified.card_num)
      setHasCardRequestBeenMade(true)
    } else {
      setErrorMessage(`*Could Not Create Card ${cardStatus === 'I' ? 'Validation' : 'Invalidation'} Request`)
    }
  }

  const handleChangeContactPerson = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactPerson(event.target.value)
  }
  const handleSetComments = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value)
  }
  const closeModal = () => {
    clearSelections()
    handleClose()
  }
  const clearSelections = () => {
    setHasCardRequestBeenMade(false)
    setContactPerson('')
    setComments('')
  }
  return (
    <Dialog
      open={isOpen}
      onBackdropClick={clearSelections}
      onClose={() => {
        clearSelections()
        handleClose()
      }}
      aria-labelledby="form-dialog-title"
    >
      {!hasCardRequestBeenMade ? (
        <>
          <ValidatorForm onSubmit={handleSendUpdateRequest}>
            <DialogTitle id="form-dialog-title">
              {cardStatus === 'I' ? 'Validating ' : 'Invalidating '} Card {cardBeingModified.card_num}
            </DialogTitle>
            <DialogContent>
              <div className={classes.flexContainer}>
                <div className={clsx(classes.title, classes.leftLabel)}>Company Name:</div>
                {user?.customerName}
              </div>
              <div className={classes.flexContainer}>
                <div className={clsx(classes.title, classes.leftLabel)}>Card Number:</div>
                {cardBeingModified.card_num}
              </div>
              <div className={classes.flexContainer}>
                <div className={clsx(classes.title, classes.leftLabel)}>Card Status:</div>
                {getCardStatus(cardStatus)}
              </div>
              <div style={{ display: 'flex' }}>
                <TextValidator
                  name={'contactPerson'}
                  value={contactPerson}
                  margin="dense"
                  id="name"
                  label="Requested By"
                  type="name"
                  style={{ paddingRight: 10 }}
                  onChange={handleChangeContactPerson}
                  validators={['required']}
                  errorMessages={['Requested by is required']}
                />
                <TextValidator
                  name={'comments'}
                  value={comments}
                  margin="dense"
                  id="name"
                  label="Comments/Reason"
                  type="name"
                  onChange={handleSetComments}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModal}>Cancel</Button>
              <Button type={'submit'}>{cardStatus === 'I' ? 'Validate' : 'Invalidate'}</Button>
            </DialogActions>
          </ValidatorForm>
        </>
      ) : (
        <div>
          <p style={{ padding: 10 }}>New Card {cardStatus === 'I' ? 'Validation' : 'Invalidation'} Request Sent</p>
          <DialogActions>
            <Button onClick={closeModal}>Close</Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  )
}
export default InvalidateOrValidateCardModal
