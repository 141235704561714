import { DriverCardOrder, HistoryTableDisplay, SingleCardOrder, VehicleCardOrder } from '../types/types'

// You can't use x.typeof with Typescript custom types because they are stripped away at execution time. These functions take the place of Typeof by checking unique properties on the object

export const isHistoryTableDisplay = (table: HistoryTableDisplay): table is HistoryTableDisplay => {
  return (table as HistoryTableDisplay).entryNum !== undefined
}

export const isSingleCardOrder = (
  order: SingleCardOrder | VehicleCardOrder | DriverCardOrder
): order is SingleCardOrder => {
  return (order as SingleCardOrder).vehicleNumber !== undefined
}

export const isVehicleCardOrder = (
  order: SingleCardOrder | VehicleCardOrder | DriverCardOrder
): order is VehicleCardOrder => {
  return (order as VehicleCardOrder).vehicleDescription !== undefined
}

export const isDriverCardOrder = (
  order: SingleCardOrder | VehicleCardOrder | DriverCardOrder
): order is VehicleCardOrder => {
  return ((order as DriverCardOrder).cardLabel && (order as DriverCardOrder).department) !== undefined
}
