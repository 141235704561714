import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import FormLabel from '@material-ui/core/FormLabel'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  formSection: {
    marginTop: 25,
  },
})

interface VehicleDescriptionInputProps {
  vehicleDescription: string
  handleChangeVehicleDescription: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const VehicleDescriptionInput = ({
  vehicleDescription,
  handleChangeVehicleDescription,
}: VehicleDescriptionInputProps) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.formSection}>
        <FormLabel className={classes.title}>Vehicle Description</FormLabel>
        <TextValidator
          name={'vehicleDescription'}
          value={vehicleDescription}
          margin="dense"
          id="name"
          type="name"
          style={{
            display: 'flex',
          }}
          inputProps={{
            maxLength: 20,
          }}
          onChange={handleChangeVehicleDescription}
          helperText={`${vehicleDescription.length}/20`}
        />
      </div>
    </>
  )
}
export default VehicleDescriptionInput
