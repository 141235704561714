import React, { Dispatch, SetStateAction, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import clsx from 'clsx'
import axios from 'axios'
import { useStore } from '../../store/StoreProvider'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { CardModifiedCb, CardsTableDisplay } from '../../types/types'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  leftLabel: {
    marginRight: 20,
  },
  flexContainer: {
    display: 'flex',
  },
  warningText: {
    paddingLeft: 20,
    color: '#ed2224',
  },
})
interface NewCardAlarmModalProps {
  isOpen: boolean
  handleClose: () => void
  cardBeingModified: CardsTableDisplay
  hasAlarmBeenCreated: boolean
  setHasAlarmBeenCreated: Dispatch<SetStateAction<boolean>>
  cardModifiedCb: CardModifiedCb
}

export const NewCardAlarmModal = ({
  isOpen,
  handleClose,
  cardBeingModified,
  hasAlarmBeenCreated,
  setHasAlarmBeenCreated,
  cardModifiedCb,
}: NewCardAlarmModalProps) => {
  const classes = useStyles()
  const { user } = useStore()
  const [contactPerson, setContactPerson] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email1, setEmail1] = useState('')
  const [email2, setEmail2] = useState('')
  const [email3, setEmail3] = useState('')

  const [errorMessage, setErrorMessage] = useState('')

  const cardNumber = cardBeingModified.card_num

  const handleCreateCardAlarm = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/cards/createCardAlarm`, {
        contactPerson: contactPerson,
        phone: phoneNumber,
        email1: email1,
        email2: email2,
        email3: email3,
        cardNumber: cardNumber,
        customerNumber: user?.id,
        companyName: user?.customerName,
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
        token: localStorage.getItem('CFN_TOKEN'),
      })

      if (response.status === 200) {
        cardModifiedCb(cardNumber)
        setHasAlarmBeenCreated(true)
      } else {
        setErrorMessage('*Could Not Create New Card Alarm')
      }
    } catch {
      setErrorMessage('*Could Not Create New Card Alarm')
    }
  }

  const handleSetContactPerson = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactPerson(event.target.value)
  }
  const handleSetPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value)
  }
  const handleSetEmail1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail1(event.target.value)
  }
  const handleSetEmail2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail2(event.target.value)
  }
  const handleSetEmail3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail3(event.target.value)
  }

  const clearSelections = () => {
    setContactPerson('')
    setPhoneNumber('')
    setEmail1('')
    setEmail2('')
    setEmail3('')
  }

  const closeModal = () => {
    clearSelections()
    handleClose()
  }
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          clearSelections()
          handleClose()
        }}
        aria-labelledby="form-dialog-title"
      >
        {!hasAlarmBeenCreated ? (
          <>
            <ValidatorForm onSubmit={handleCreateCardAlarm}>
              <DialogTitle id="form-dialog-title">Add New Alarm</DialogTitle>
              <DialogContent>
                <div className={classes.flexContainer}>
                  <div className={clsx(classes.title, classes.leftLabel)}>Company Name:</div>
                  {user?.customerName}
                </div>
                <div className={classes.flexContainer}>
                  <div className={clsx(classes.title, classes.leftLabel)}>Card: {cardNumber}</div>
                </div>
                <div>
                  <TextValidator
                    name={'contactPerson'}
                    value={contactPerson}
                    margin="dense"
                    id="contactPerson"
                    label="Contact Person"
                    style={{ paddingRight: 10 }}
                    onChange={handleSetContactPerson}
                    validators={['required']}
                    errorMessages={['Contact person is required']}
                  />
                  <TextValidator
                    name={'phone'}
                    value={phoneNumber}
                    margin="dense"
                    id="name"
                    label="Phone"
                    style={{ paddingRight: 10 }}
                    onChange={handleSetPhoneNumber}
                    validators={['required']}
                    errorMessages={['Phone number is required']}
                  />
                  <TextValidator
                    name={'email1'}
                    value={email1}
                    margin="dense"
                    id="name"
                    label="Email Address"
                    style={{ paddingRight: 10 }}
                    onChange={handleSetEmail1}
                    validators={['isEmail']}
                    errorMessages={['Email must be in proper format']}
                  />
                  <TextValidator
                    name={'email2'}
                    value={email2}
                    margin="dense"
                    id="name"
                    label="Email Address"
                    style={{ paddingRight: 10 }}
                    onChange={handleSetEmail2}
                    validators={['isEmail']}
                    errorMessages={['Email must be in proper format']}
                  />
                  <TextValidator
                    name={'email3'}
                    value={email3}
                    margin="dense"
                    id="name"
                    label="Email Address"
                    style={{ paddingRight: 10 }}
                    onChange={handleSetEmail3}
                    validators={['isEmail']}
                    errorMessages={['Email must be in proper format']}
                  />
                </div>
              </DialogContent>
              <p className={classes.warningText}>{errorMessage}</p>
              <DialogActions>
                <Button onClick={closeModal}>Cancel</Button>
                <Button type={'submit'}>Add</Button>
              </DialogActions>
            </ValidatorForm>
          </>
        ) : (
          <div>
            <p style={{ padding: 10 }}>New Card Alarm Request Sent</p>
            <DialogActions>
              <Button onClick={closeModal}>Close</Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    </>
  )
}
export default NewCardAlarmModal
