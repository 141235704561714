import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import axios from 'axios'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import { useHistory } from 'react-router-dom'
import PromptForNewPassword from '../ChangePassword/PromptForNewPassword'
import PasswordUpdated from './PasswordUpdated'
import { DoesAccountExistApiResponse } from '../../../types/types'
import ShowError from '../../Messages/ShowError'
import { useKeyPress } from '@react-typed-hooks/use-key-press'

const useStyles = makeStyles({
  cardHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  root: {
    minWidth: 275,
  },
  title: {
    justifyContent: 'center',
    display: 'flex',
    paddingBottom: 10,
  },
  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const ChangePassword = () => {
  let history = useHistory()
  const classes = useStyles()
  const [accountNumber, setAccountNumber] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false)
  const [passwordUpdateDone, setPasswordUpdateDone] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [addLinkToNewAccount, setAddLinkToNewAccount] = useState<boolean>(false)

  const isEnterPressed = useKeyPress({ targetKey: 'Enter' })
  useEffect(() => {
    if (isEnterPressed === true) {
      if (!isChangingPassword) {
        lookupAccount().then((r) => console.log(''))
      } else if (!passwordUpdateDone) {
        setPasswordUpdated()
      }
    }
  }, [isEnterPressed])

  const lookupAccount = async () => {
    const data = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/doesAccountExist`, {
      token: localStorage.getItem('CFN_TOKEN'),
      customerNumber: accountNumber,
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
      userName: userName,
    })
    const doesAccountExist: DoesAccountExistApiResponse = data.data
    if (doesAccountExist && doesAccountExist.doesAccountAlreadyExist === true) {
      setIsChangingPassword(true)
    } else {
      setErrorMessage('*Account not found')
      setAddLinkToNewAccount(true)
    }
  }
  const setPasswordUpdated = () => {
    setPasswordUpdateDone(true)
  }
  const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value)
  }
  const goToAddAccount = () => {
    history.push('/addaccount')
  }

  return (
    <div className={classes.cardHolder}>
      <Card className={classes.root}>
        {!isChangingPassword ? (
          <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" component="h2" className={classes.title}>
              Change Password
            </Typography>
            <div className={classes.controlWrapper}>
              <TextField
                onChange={(event: any) => setAccountNumber(event.target.value)}
                value={accountNumber}
                style={{ marginBottom: 20 }}
                label={'Enter Account Number'}
              />
              <TextField
                onChange={handleChangeUserName}
                value={userName}
                style={{ marginBottom: 20 }}
                label={'Enter User Name'}
              />
              <Button variant="contained" onClick={lookupAccount}>
                Lookup Account
              </Button>
              <ShowError errorMessage={errorMessage}></ShowError>
              {addLinkToNewAccount && (
                // error handling, if account dosent exist give user a chance to create it
                <Button variant="contained" onClick={goToAddAccount}>
                  Add New Account
                </Button>
              )}
            </div>
          </CardContent>
        ) : !passwordUpdateDone ? (
          <PromptForNewPassword
            setPasswordUpdated={setPasswordUpdated}
            customerNumber={accountNumber}
            userName={userName}
          ></PromptForNewPassword>
        ) : (
          <PasswordUpdated customerNumber={accountNumber}></PasswordUpdated>
        )}
      </Card>
    </div>
  )
}

export default ChangePassword
