import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import axios from 'axios'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import { useStore } from '../../../store/StoreProvider'
import ShowError from '../../Messages/ShowError'

const useStyles = makeStyles({
  title: {
    justifyContent: 'center',
    display: 'flex',
    paddingBottom: 10,
  },
  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
})

interface PromptForNewPasswordProps {
  customerNumber: string
  userName: string
  setPasswordUpdated: () => void
}

export const PromptForNewPassword = ({ customerNumber, setPasswordUpdated, userName }: PromptForNewPasswordProps) => {
  const classes = useStyles()
  const { user } = useStore()
  const [newPassword, setNewPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')

  const changePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setErrorMessage('*Passwords do not match')
    } else {
      const data = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/changeAccountPassword`, {
        token: localStorage.getItem('CFN_TOKEN'),
        password: newPassword,
        customerNumber: customerNumber,
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
        userName: userName,
      })
      if (data.status === 200) {
        setPasswordUpdated()
      }
    }
  }

  return (
    <>
      <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h5" component="h2" className={classes.title}>
          Enter New Password
        </Typography>
        <div className={classes.controlWrapper}>
          <TextField
            onChange={(event: any) => setNewPassword(event.target.value)}
            value={newPassword}
            style={{ marginBottom: 20 }}
            label={'New Password'}
          />
          <TextField
            onChange={(event: any) => setConfirmNewPassword(event.target.value)}
            value={confirmNewPassword}
            style={{ marginBottom: 20 }}
            label={'Confirm New Password'}
          />
          <Button variant="contained" onClick={changePassword}>
            Change Password
          </Button>
          <ShowError errorMessage={errorMessage}></ShowError>
        </div>
      </CardContent>
    </>
  )
}

export default PromptForNewPassword
