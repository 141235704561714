import React from 'react'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Link } from 'react-router-dom'
import { useStore } from '../../store/StoreProvider'
import { Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ClearIcon from '@material-ui/icons/Clear'

const styles = makeStyles({
  buttonHover: {
    '&:hover': {
      borderRadius: 0,
      borderBottom: '1px solid #e32124',
      backgroundColor: 'transparent',
    },
  },
  typography: {
    color: 'black',
    fontFamily: 'Oswald',
    textTransform: 'none',
  },
  toolbar: {
    justifyContent: 'center!important' as 'center',
  },
  '@media (max-width: 600px)': {
    toolbar: {
      justifyContent: 'left!important' as 'center',
    },
  },
})
interface UserNavigationOptionsProps {
  logoutSpoofAccount: () => void
}
export const UserNavigationOptions = ({ logoutSpoofAccount }: UserNavigationOptionsProps) => {
  const classes = styles()
  const { user, setUser } = useStore()

  const logoutUser = () => {
    localStorage.removeItem('CFN_TOKEN')
    localStorage.removeItem('spoofUserId')
    setUser(undefined)
  }

  return (
    <>
      <Button
        disableRipple={true}
        className={`${classes.typography} + ${classes.buttonHover}`}
        component={Link}
        to="/transactions"
      >
        Transactions
      </Button>
      <Button
        disableRipple={true}
        className={`${classes.typography} + ${classes.buttonHover}`}
        component={Link}
        to="/cards"
      >
        Cards
      </Button>
      <Button
        disableRipple={true}
        className={`${classes.typography} + ${classes.buttonHover}`}
        component={Link}
        to="/statements"
      >
        Statements
      </Button>
      <Button
        disableRipple={true}
        className={`${classes.typography} + ${classes.buttonHover}`}
        component={Link}
        to="/subaccounts"
      >
        Subaccounts
      </Button>
      <Button
        disableRipple={true}
        className={`${classes.typography} + ${classes.buttonHover}`}
        component={Link}
        to="/makeapayment"
      >
        Make A Payment
      </Button>
      {user && (
        <Button
          disableRipple={true}
          className={`${classes.typography} + ${classes.buttonHover}`}
          component={Link}
          onClick={logoutUser}
          to={localStorage.getItem('spoofUserId') ? 'admin' : '/login'}
        >
          {'Logout'}
        </Button>
      )}
      <Typography className={`${classes.typography}`} style={{ paddingLeft: '50px' }}>
        {user ? `Logged in as ${user.username}` : 'Login'}
      </Typography>

      {localStorage.getItem('spoofUserId') && (
        <>
          <IconButton onClick={logoutSpoofAccount}>
            <ClearIcon></ClearIcon>
          </IconButton>
          <Typography className={classes.typography}>
            Spoofing Customer Number: {localStorage.getItem('spoofUserId')}
          </Typography>
        </>
      )}
    </>
  )
}

export default UserNavigationOptions
