import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { HistoryTableDisplay, DataTableDisplayColumns } from '../../types/types'
import { useStore } from '../../store/StoreProvider'
import TablePagination from '@material-ui/core/TablePagination'
import moment from 'moment'
import FilterListIcon from '@material-ui/icons/FilterList'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Accordion from '@material-ui/core/Accordion'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Popper, TextField } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import SaveIcon from '@material-ui/icons/Save'
import Button from '@material-ui/core/Button'
import { useKeyPress } from '@react-typed-hooks/use-key-press'
import DataLoadingSpinner from '../Global/DataLoadingSpinner'
import { DaterangePicker } from '../Global/DaterangePicker'

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
    lineHeight: 0,
  },
  tableHead: {
    backgroundColor: '#ededee',
  },
  searchAndClearWrapper: {
    display: 'flex',
    paddingRight: 20,
  },
  historyTableStyles: {
    //width:'100% !important'
    maxWidth: '100% !important',
  },
  dateInputBox: {
    maxWidth: 75,
  },
  responsiveData: {
    padding: '0 !important',
    margin: '0 !important',
  },
  searchIcon: {
    color: '#FF2045',
  },
})

export const ResponsiveTransactionHistoryDataDisplay: React.FunctionComponent<DataTableDisplayColumns> = () => {
  const { user } = useStore()
  const classes = useStyles()
  const { historyDataTable } = useStore()

  const { historyFilteredDataTable, setHistoryFilteredDataTable } = useStore()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [productDropdownValues, setProductDropdownValues] = useState<string[]>([])
  const [locationDropdownValues, setLocationDropdownValues] = useState<string[]>([])
  const [productFilterValue, setProductFilterValue] = useState('')
  const [locationFilterValue, setLocationFilterValue] = useState('')
  const [cardFilterValue, setCardFilterValue] = useState('')
  const [cardNumberFilterValue, setCardNumberFilterValue] = useState('')
  const [vehicleFilterValue, setVehicleFilterValue] = useState('')
  const [vehicleNumberFilterValue, setVehicleNumberFilterValue] = useState('')
  const [miscEntryFilterValue, setMiscEntryFilterValue] = useState('')
  const [invoiceFilterValue, setInvoiceFilterValue] = useState('')
  const [totalGallons, setTotalGallons] = useState<number>(0)
  const [totalFederalTax, setTotalFederalTax] = useState<number>(0)
  const [totalStateTax, setTotalStateTax] = useState<number>(0)
  const [totalSalesTax, setTotalSalesTax] = useState<number>(0)
  const [totalOtherTax, setTotalOtherTax] = useState<number>(0)
  const [earliestDate, setEarliestDate] = useState<Date>(moment().toDate())
  const [displayedDateDropdownValue, setDisplayedDateDropdownValue] = useState<string>('currentMonth')
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState<number>(0)
  const isEnterPressed = useKeyPress({ targetKey: 'Enter' })
  const [startDate, setStartDate] = useState<Date>(moment().startOf('month').toDate())
  const [endDate, setEndDate] = useState<Date>(moment().toDate())
  const [filterPopperAnchorEl, setFilterPopperAnchorEl] = React.useState<null | HTMLElement>(null)

  const filterValues = [
    invoiceFilterValue,
    miscEntryFilterValue,
    vehicleFilterValue,
    vehicleNumberFilterValue,
    cardFilterValue,
    cardNumberFilterValue,
    locationFilterValue,
    productFilterValue,
    endDate,
    startDate,
  ]

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const filterPopperHandleClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterPopperAnchorEl(filterPopperAnchorEl ? null : event.currentTarget)
  }
  const sumGallons = () => {
    const sumGallons: number | undefined = historyFilteredDataTable?.reduce((accum, item) => accum + item.gallons, 0)
    setTotalGallons(sumGallons ? sumGallons : 0)
  }
  const sumFederalTax = () => {
    const sumFederalTax: number | undefined = historyFilteredDataTable?.reduce(
      (accum, item) => accum + item.fedTaxAmt,
      0
    )
    setTotalFederalTax(sumFederalTax ? sumFederalTax : 0)
  }
  const sumStateTax = () => {
    const sumStateTax: number | undefined = historyFilteredDataTable?.reduce(
      (accum, item) => accum + item.stateTaxAmt,
      0
    )
    setTotalStateTax(sumStateTax ? sumStateTax : 0)
  }
  const sumSalesTax = () => {
    const sumSalesTax: number | undefined = historyFilteredDataTable?.reduce(
      (accum, item) => accum + item.salesTaxAmt,
      0
    )
    setTotalSalesTax(sumSalesTax ? sumSalesTax : 0)
  }
  const sumOtherTax = () => {
    const sumOtherTax: number | undefined = historyFilteredDataTable?.reduce(
      (accum, item) => accum + item.otherTaxAmt,
      0
    )
    setTotalOtherTax(sumOtherTax ? sumOtherTax : 0)
  }
  const sumInvoiceAmount = () => {
    const sumInvoiceAmounts: number | undefined = historyFilteredDataTable?.reduce(
      (accum, item) => accum + item.invoiceAmt,
      0
    )
    setTotalInvoiceAmount(sumInvoiceAmounts ? sumInvoiceAmounts : 0)
  }

  const sortByDate = () => {
    const sortedDataTable: HistoryTableDisplay[] | undefined = historyFilteredDataTable?.sort(
      (a: HistoryTableDisplay, b: HistoryTableDisplay) => {
        const dateA = new Date(a.stamp)
        const dateB = new Date(b.stamp)
        return dateB.valueOf() - dateA.valueOf()
      }
    )
    setHistoryFilteredDataTable(sortedDataTable)
  }

  const getEarliestDate = () => {
    let moments = historyDataTable?.map(d => moment(d.stamp)),
        maxDate = moment.max(moments ? moments : [])
    setEarliestDate(maxDate.toDate())
  }

  const downloadDataFromTable = () => {
    const element = document.createElement('a')
    let fileToDownload: string[][] = []
    const titles: string[] = [
      'ID',
      'Date',
      'Card',
      'Card Number',
      'Invoice Number',
      'Vehicle',
      'Vehicle Number',
      'Invoice Amount',
      'Transaction Amount',
      'Site Name',
      'Site Number',
      'Gallons Purchased',
      'Product Name',
      'Entry Number',
      'Federal Tax Amount',
      'State Tax Amount',
      'Sales Tax Amount',
      'Other Tax Amount',
    ]
    fileToDownload.push(titles)
    historyFilteredDataTable?.forEach((item, i) => {
      const currentItem: string[] = [
        `"${item.id.toString()}"`,
        `"${moment.unix(+item.stamp).format('MM/DD/YYYY')} ${moment.unix(+item.stamp).format('hh:mm a')}"`,
        `"${item.cardLabel?.toString()}"`,
        `"${item.drivNum.toString()}"`,
        `"${item.invNum.toString()}"`,
        `"${item.vehicleLabel?.toString()}"`,
        `"${item.vehNum.toString()}"`,
        `"${item.invoiceAmt.toString()}"`,
        `"${item.transAmt.toString()}"`,
        `"${item.siteName}"`,
        `"${item.siteNum}"`,
        `"${item.gallons.toFixed(2)}"`,
        `"${item.prodNameShort}"`,
        `"${item.entryNum.toString()}"`,
        `"${item.fedTaxAmt.toString()}"`,
        `"${item.stateTaxAmt.toString()}"`,
        `"${item.salesTaxAmt.toString()}"`,
        `"${item.otherTaxAmt.toString()}"`,
      ]
      fileToDownload.push(currentItem)
    })
    let csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(fileToDownload.map((e) => e.join(',')).join('\n'))

    const link = document.createElement('a')
    link.setAttribute('href', csvContent)
    link.setAttribute(
      'download',
      `${moment() + (user && user?.customerName ? user?.customerName.replace(/ /g, '') : '')}.csv`
    )
    link.click()
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleProductFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProductFilterValue(event.target.value as string)
  }
  const handleLocationFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLocationFilterValue(event.target.value as string)
  }

  const generateValuesForProductFilterDropdown = (values: HistoryTableDisplay[] | undefined) => {
    const productNames: string[] = []
    values
      ? values.forEach(
          (element) => !productNames.includes(element.prodNameShort) && productNames.push(element.prodNameShort)
        )
      : console.log('')
    setProductDropdownValues(productNames)
  }
  const generateValuesForLocationFilterDropdown = (values: HistoryTableDisplay[] | undefined) => {
    const locationNames: string[] = []
    values
      ? values.forEach((element) => !locationNames.includes(element.siteName) && locationNames.push(element.siteName))
      : console.log('')
    setLocationDropdownValues(locationNames)
  }

  const clearAllFilters = () => {
    const endDate = moment().toDate()
    const startDate = moment().subtract(1, 'months').toDate()
    setInvoiceFilterValue('')
    setMiscEntryFilterValue('')
    setVehicleFilterValue('')
    setVehicleNumberFilterValue('')
    setCardFilterValue('')
    setCardNumberFilterValue('')
    setLocationFilterValue('')
    setProductFilterValue('')
    setEndDate(moment().toDate())
    setStartDate(moment().startOf('month').toDate())
    setDisplayedDateDropdownValue('currentMonth')
  }

  const createDropdowns = async () => {
    generateValuesForLocationFilterDropdown(historyDataTable)
    generateValuesForProductFilterDropdown(historyDataTable)
  }

  const filterTable = () => {
    // When filtering return to first page of dataset
    setPage(0)
    sortByDate()
    // Makes a copy of all data on data table. This serves as source of truth for all filters going down the line
    let tempFilteredTable: HistoryTableDisplay[] | undefined = historyDataTable

    //Filtering is exclusive so if it fails any one filter it is removed
    if (startDate) {
      tempFilteredTable = tempFilteredTable?.filter((row) => moment.unix(+row.stamp).isSameOrAfter(moment(startDate).startOf('day')))
    }
    if (endDate) {
      tempFilteredTable = tempFilteredTable?.filter((row) => moment.unix(+row.stamp).isSameOrBefore(moment(endDate).endOf('day')))
    }
    if (cardFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.cardLabel?.toString().startsWith(cardFilterValue))
    }
    if (cardNumberFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.drivNum.toString().startsWith(cardNumberFilterValue))
    }
    if (vehicleFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.vehicleLabel?.toString().startsWith(vehicleFilterValue))
    }
    if (vehicleNumberFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.vehNum.toString().startsWith(vehicleNumberFilterValue))
    }
    if (miscEntryFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.entryNum.toString().startsWith(miscEntryFilterValue))
    }
    if (invoiceFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.invNum.toString().startsWith(invoiceFilterValue))
    }
    if (productFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.prodNameShort.toString().startsWith(productFilterValue)
      )
    }
    if (locationFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.siteName.toString().startsWith(locationFilterValue))
    }
    generateValuesForLocationFilterDropdown(historyDataTable)
    generateValuesForProductFilterDropdown(historyDataTable)
    setHistoryFilteredDataTable(tempFilteredTable)
  }

  useEffect(() => {
    // Any time one of the filters is updated re-run the filtering logic
    filterTable()
  }, filterValues)

  useEffect(() => {
    sortByDate()
    sumGallons()
    sumFederalTax()
    sumStateTax()
    sumSalesTax()
    sumOtherTax()
    sumInvoiceAmount()
  }, [historyFilteredDataTable])

  useEffect(() => {
    if (!historyDataTable) {
      createDropdowns()
    }
    // performs initial date filtering
    filterTable()
    getEarliestDate()
  }, [historyDataTable])
  useEffect(() => {
    filterTable()
  }, [isEnterPressed])

  const getHistoryTableRow = (row: HistoryTableDisplay, i: number) => {
    return (
      <TableRow key={i}>
        <TableCell component="th" scope="row">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
              <p>Invoice: {row.invNum}</p>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.responsiveData}>
                <p>
                  Date: {moment.unix(+row.stamp).format('MM/DD/YYYY') + ' ' + moment.unix(+row.stamp).format('hh:mm a')}
                </p>
                <p>Card: {row.cardLabel}</p>
                <p>Card Number: {row.drivNum}</p>
                <p>Vehicle: {row.vehicleLabel}</p>
                <p>Vehicle Number: {row.vehNum}</p>
                <p>Misc Entry: {row.entryNum}</p>
                <p>Invoice: {row.invNum}</p>
                <p>Amount: {row.invoiceAmt}</p>
                <p>Gallons: {row.gallons.toFixed(2)}</p>
                <p>PPG: {'$' + (row.invoiceAmt / row.gallons).toFixed(2)}</p>
                <p>Product: {row.prodNameShort}</p>
                <p>Location: {row.siteName}</p>
              </div>
            </AccordionDetails>
          </Accordion>
        </TableCell>
      </TableRow>
    )
  }

  // TODO: Replace this with a composable version that can accept a component as a prop for the rows
  return (
    <div style={{ maxWidth: '100% !important' }}>
      {!historyDataTable ? (
        <DataLoadingSpinner />
      ) : (
        <Paper className={classes.historyTableStyles}>
          <div className={classes.searchAndClearWrapper}>
            <Tooltip title="Filter list">
              <IconButton aria-label="filter list" onClick={filterPopperHandleClick}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Popper id={'dadasad'} open={Boolean(filterPopperAnchorEl)} anchorEl={filterPopperAnchorEl}>
              <Paper
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 50,
                }}
              >
                <>
                  <div>
                    <IconButton onClick={() => clearAllFilters()}>
                      <ClearIcon></ClearIcon>
                    </IconButton>
                    <IconButton onClick={() => downloadDataFromTable()}>
                      <SaveIcon></SaveIcon>
                    </IconButton>
                    <Button style={{ marginLeft: 'auto' }} variant={'contained'} onClick={filterPopperHandleClick}>
                      Close
                    </Button>
                  </div>
                  <p>Date Range:</p>
                  <DaterangePicker
                    onChange={daterange => {
                      daterange.startDate && setStartDate(daterange.startDate)
                      daterange.endDate && setEndDate(daterange.endDate)
                    }}
                    initialDateRange={{startDate: startDate, endDate: endDate}}
                  />
                  <p>Card:</p>
                  <TextField
                    onChange={(event: any) => setCardFilterValue(event.target.value)}
                    value={cardFilterValue}
                  />
                  <p>Card Number:</p>
                  <TextField
                    onChange={(event: any) => setCardNumberFilterValue(event.target.value)}
                    value={cardNumberFilterValue}
                  />
                  <p>Vehicle:</p>
                  <TextField
                    onChange={(event: any) => setVehicleFilterValue(event.target.value)}
                    value={vehicleFilterValue}
                  />
                  <p>Vehicle Number:</p>
                  <TextField
                    onChange={(event: any) => setVehicleNumberFilterValue(event.target.value)}
                    value={vehicleNumberFilterValue}
                  />
                  <p>Misc Entry:</p>
                  <TextField
                    onChange={(event: any) => setMiscEntryFilterValue(event.target.value)}
                    value={miscEntryFilterValue}
                  />
                  <p>Invoice:</p>
                  <TextField
                    onChange={(event: any) => setInvoiceFilterValue(event.target.value)}
                    value={invoiceFilterValue}
                  />
                  <p>Product:</p>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={productFilterValue}
                    onChange={handleProductFilterChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {productDropdownValues.map((dropdownItem, i) => (
                      <MenuItem value={dropdownItem} key={i}>
                        {dropdownItem}
                      </MenuItem>
                    ))}
                  </Select>
                  <p>Location:</p>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={locationFilterValue}
                    onChange={handleLocationFilterChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {locationDropdownValues.map((dropdownItem, i) => (
                      <MenuItem value={dropdownItem} key={i}>
                        {dropdownItem}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              </Paper>
            </Popper>
          </div>
          <TableContainer component={Paper} style={{ width: '100% !important' }}>
            <Table className={classes.table} size="small">
              <TableHead className={classes.tableHead}></TableHead>
              <TableBody>
                {/*TODO: After the routes are more hashed out make sure to come back and add a way to dynamically wrap column data in <a> tags*/}
                {/*If data is filtered map filtered data, else map all data*/}
                {historyFilteredDataTable &&
                  historyFilteredDataTable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => getHistoryTableRow(row, i))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[]}
            onPageChange={() => {}}
            count={historyFilteredDataTable ? historyFilteredDataTable.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  )
}
export default ResponsiveTransactionHistoryDataDisplay
