import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  formSection: {
    marginTop: 25,
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
})
interface HasOregonPUCPermitInputProps {
  hasOregonPUCPlates: boolean
  handleChangeHasOregonPUCPlates: (event: React.ChangeEvent<HTMLInputElement>) => void
  pucPermitNumber: string
  handleChangePUCPermitNumber: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const HasOregonPUCPermitInput = ({
  hasOregonPUCPlates,
  handleChangeHasOregonPUCPlates,
  pucPermitNumber,
  handleChangePUCPermitNumber,
}: HasOregonPUCPermitInputProps) => {
  const classes = useStyles()

  return (
    <>
      <FormControl component="fieldset">
        <div style={{ marginTop: 25 }}>
          <FormLabel className={classes.title} focused={false}>
            Do You Have Oregon PUC Plates?
          </FormLabel>
          <RadioGroup
            aria-label="hasOregonPucPlates"
            name="hasOregonPucPlates"
            onChange={handleChangeHasOregonPUCPlates}
            value={String(hasOregonPUCPlates)}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FormControlLabel
              value="true"
              control={
                <Radio
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              value="false"
              control={
                <Radio
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                />
              }
              label="No"
            />
            <FormLabel
              style={{ marginLeft: 20 }}
              className={hasOregonPUCPlates ? classes.title : classes.titleOff}
              focused={false}
            >
              PUC Permit Number
            </FormLabel>
            <TextValidator
              name={'pucPermitNumber'}
              value={pucPermitNumber}
              margin="dense"
              id="name"
              type="name"
              validators={hasOregonPUCPlates ? ['required'] : []}
              errorMessages={['Permit number is required']}
              disabled={!hasOregonPUCPlates}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: 20,
                maxWidth: 75,
              }}
              onChange={handleChangePUCPermitNumber}
            />
          </RadioGroup>
        </div>
      </FormControl>
    </>
  )
}
export default HasOregonPUCPermitInput
