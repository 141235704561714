import React from 'react'
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
})

interface ReasonForReplacementQuestionProps {
  reasonForReplacement: string
  handleChangeReasonForReplacement: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ReasonForReplacementQuestion = ({
  reasonForReplacement,
  handleChangeReasonForReplacement,
}: ReasonForReplacementQuestionProps) => {
  const classes = useStyles()

  return (
    <>
      <FormControl component="fieldset">
        <div style={{ marginTop: 25 }}>
          <FormLabel className={classes.title} focused={false}>
            Reason For Replacement
          </FormLabel>
          <RadioGroup
            aria-label="reasonForReplacement"
            name="reasonForReplacement"
            onChange={handleChangeReasonForReplacement}
            value={reasonForReplacement}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FormControlLabel
              value="lost"
              control={
                <Radio
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                />
              }
              label="Lost"
            />
            <FormControlLabel
              value="stolen"
              control={
                <Radio
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                />
              }
              label="Stolen"
            />
            <FormControlLabel
              value="worn"
              control={
                <Radio
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                />
              }
              label="Worn"
            />
          </RadioGroup>
        </div>
      </FormControl>
    </>
  )
}
export default ReasonForReplacementQuestion
