import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import FormLabel from '@material-ui/core/FormLabel'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
})

interface CardLabelInputProps {
  cardLabel: string
  handleChangeCardLabel: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const CardLabelInput = ({ cardLabel, handleChangeCardLabel }: CardLabelInputProps) => {
  const classes = useStyles()

  return (
    <>
      <FormLabel className={classes.title}>Card Label</FormLabel>
      <Tooltip
        title={
          'The card label is a description which will appear on your statement along with the card number. It can be used to identify a driver, assigned to a certain card or the name of a vehicle type, liscense number, etc.'
        }
      >
        <InfoIcon style={{ height: 15 }}></InfoIcon>
      </Tooltip>
      <TextValidator
        validators={['required']}
        errorMessages={['Card label is required']}
        name={'name'}
        value={cardLabel}
        margin="dense"
        id="name"
        type="name"
        style={{
          display: 'flex',
        }}
        inputProps={{
          maxLength: 20,
        }}
        onChange={handleChangeCardLabel}
        helperText={`${cardLabel.length}/20`}
      />
    </>
  )
}
export default CardLabelInput
