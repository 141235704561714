import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import axios from 'axios'
import Card from '@material-ui/core/Card'
import { RecordsAffected, Swipe } from '../../../types/types'
import PromptToDeleteMultipleInvoices from './PromptToDeleteMultipleInvoices'
import PromptUserToFetchDuplicateInvoices from './PromptUserToFetchDuplicateInvoices'
import DuplicateInvoicesDeleted from './DuplicateInvoicesDeleted'

const useStyles = makeStyles({
  form: {
    display: 'flex',

    alignItems: 'center',
    maxWidth: '100%',
    flexDirection: 'column',
    padding: 20,
  },
})

export const RemoveDuplicateInvoices = () => {
  const classes = useStyles()
  const [duplicateRecordsFoundCount, setDuplicateRecordsFoundCount] = useState<number>(0)
  const [gotDuplicateRecords, setGotDuplicateRecords] = useState<boolean>(false)
  const [isGettingDuplicateInvoices, setIsGettingDuplicateInvoices] = useState<boolean>(false)
  const [invoiceNumbers, setInvoiceNumbers] = useState<any>()
  const [recordsKept, setRecordsKept] = useState<Swipe[]>()
  const [recordsDeleted, setRecordsDeleted] = useState<Swipe[]>()
  const [areDuplicateInvoicesDeleted, setAreDuplicateInvoicesDeleted] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const getDuplicateInvoices = async () => {
    setIsGettingDuplicateInvoices(true)
    const invNums = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/invoices/getDuplicateInvoices`, {
      token: localStorage.getItem('CFN_TOKEN'),
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
    })
    setGotDuplicateRecords(true)
    setDuplicateRecordsFoundCount(invNums.data.length)
    setInvoiceNumbers(invNums.data)
  }
  const deleteSwipes = async () => {
    let recordsDeleted: Swipe[] = []
    let recordsKept: Swipe[] = []
    setIsGettingDuplicateInvoices(false)
    setIsDeleting(true)
    if (invoiceNumbers.length > 1000) {
      // if there are more than 100k records we need batching

      const length = invoiceNumbers.length

      let batches = Math.floor(length / 1000)
      const remainder = length % 1000

      if (remainder !== 0) {
        // add one extra batch for the remainder
        batches++
      }

      for (let i = 0; i < batches; i++) {
        let startingIndexInCurrentBatch = 0
        let endingIndexInCurrentBatch = 0
        i === 0 ? (startingIndexInCurrentBatch = 0) : (startingIndexInCurrentBatch = i * 1000 - 1)
        endingIndexInCurrentBatch = (i + 1) * 1000 - 1
        let invoiceNumbersToSendInThisBatch: number[] = invoiceNumbers.slice(
          startingIndexInCurrentBatch,
          endingIndexInCurrentBatch
        )
        const deleted = await axios.post<RecordsAffected>(
          `${process.env.REACT_APP_BASE_ROOT_URL}/invoices/deleteDuplicateInvoices`,
          {
            token: localStorage.getItem('CFN_TOKEN'),
            invoiceNumbers: invoiceNumbersToSendInThisBatch,
          }
        )
        recordsDeleted = recordsDeleted.concat(deleted.data.recordsDeleted)
        recordsKept = recordsKept.concat(deleted.data.recordsKept)

        if (i === batches) {
        }
      }
    } else {
      const deleted = await axios.post<RecordsAffected>(
        `${process.env.REACT_APP_BASE_ROOT_URL}/invoices/deleteDuplicateInvoices`,
        { token: localStorage.getItem('CFN_TOKEN'), invoiceNumbers: invoiceNumbers }
      )

      recordsDeleted = recordsDeleted.concat(deleted.data.recordsDeleted)
      recordsKept = recordsKept.concat(deleted.data.recordsKept)
    }

    setRecordsDeleted(recordsDeleted)
    setRecordsKept(recordsKept)
    setAreDuplicateInvoicesDeleted(true)
    setIsDeleting(false)
  }

  return (
    <div>
      <Card>
        <div className={classes.form}>
          <h1>Remove Duplicate Invoices</h1>
          {areDuplicateInvoicesDeleted && !isGettingDuplicateInvoices && recordsDeleted && recordsKept ? (
            <DuplicateInvoicesDeleted
              recordsKept={recordsKept}
              recordsDeleted={recordsDeleted}
            ></DuplicateInvoicesDeleted>
          ) : gotDuplicateRecords ? (
            <PromptToDeleteMultipleInvoices
              duplicateRecordsFoundCount={duplicateRecordsFoundCount}
              deleteSwipes={deleteSwipes}
              isDeleting={isDeleting}
            />
          ) : (
            <PromptUserToFetchDuplicateInvoices
              getDuplicateInvoices={getDuplicateInvoices}
              isGettingDuplicateInvoices={isGettingDuplicateInvoices}
            ></PromptUserToFetchDuplicateInvoices>
          )}
        </div>
      </Card>
    </div>
  )
}

export default RemoveDuplicateInvoices
