import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import clsx from 'clsx'
import axios from 'axios'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { useStore } from '../../store/StoreProvider'
import ShowError from '../Messages/ShowError'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  leftLabel: {
    marginRight: 20,
  },
  flexContainer: {
    display: 'flex',
  },
})
interface StatementRequestModalProps {
  isOpen: boolean
  handleClose: () => void
  statementBeingRequested: number
  customerNumber: number
}

export const StatementRequestModal = ({
  isOpen,
  handleClose,
  statementBeingRequested,
  customerNumber,
}: StatementRequestModalProps) => {
  const classes = useStyles()
  const { user } = useStore()

  const [email1, setEmail1] = useState('')
  const [email2, setEmail2] = useState('')
  const [email3, setEmail3] = useState('')
  const [fax1, setFax1] = useState('')
  const [fax2, setFax2] = useState('')
  const [fax3, setFax3] = useState('')
  const [isRequestCompleted, setIsRequestCompleted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleSendStatementRequest = async () => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/statements/statementRequest`, {
      customerNumber: customerNumber,
      statementNumber: statementBeingRequested,
      email1: email1,
      email2: email2,
      email3: email3,
      fax1: fax1,
      fax2: fax2,
      fax3: fax3,
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
      token: localStorage.getItem('CFN_TOKEN'),
    })

    if (response.status === 200) {
      setIsRequestCompleted(true)
    } else {
      setErrorMessage('*Could Not Submit Request For Statement')
    }
  }

  const handleSetEmail1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail1(event.target.value)
  }
  const handleSetEmail2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail2(event.target.value)
  }
  const handleSetEmail3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail3(event.target.value)
  }
  const handleSetFax1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFax1(event.target.value)
  }
  const handleSetFax2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFax2(event.target.value)
  }
  const handleSetFax3 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFax3(event.target.value)
  }

  const clearSelections = () => {
    setEmail1('')
    setEmail2('')
    setEmail3('')
    setFax1('')
    setFax2('')
    setFax3('')
  }
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        clearSelections()
        handleClose()
      }}
      aria-labelledby="form-dialog-title"
    >
      {!isRequestCompleted ? (
        <>
          <ValidatorForm onSubmit={handleSendStatementRequest}>
            <DialogTitle id="form-dialog-title">Request Statement</DialogTitle>
            <DialogContent>
              <div className={classes.flexContainer}>
                <div className={clsx(classes.title, classes.leftLabel)}>Company Name:</div> {user?.customerName}
              </div>
              <div className={classes.flexContainer}>
                <div className={clsx(classes.title, classes.leftLabel)}>Statement Number:</div>{' '}
                {statementBeingRequested}
              </div>
              <div style={{ display: 'flex' }}>
                <TextValidator
                  name={'email1'}
                  value={email1}
                  margin="dense"
                  id="name"
                  label="Email 1"
                  type="email"
                  style={{ paddingRight: 10 }}
                  onChange={handleSetEmail1}
                  validators={['required', 'isEmail']}
                  errorMessages={['At least one email address must be provided', 'Email must be in correct format']}
                />
                <TextValidator
                  name={'email2'}
                  value={email2}
                  margin="dense"
                  id="name"
                  label="Email 2"
                  type="email"
                  style={{ paddingRight: 10 }}
                  onChange={handleSetEmail2}
                  validators={['isEmail']}
                  errorMessages={['Email must be in correct format']}
                />
                <TextValidator
                  name={'email3'}
                  value={email3}
                  margin="dense"
                  id="name"
                  label="Email 3"
                  type="email"
                  style={{ paddingRight: 10 }}
                  onChange={handleSetEmail3}
                  validators={['isEmail']}
                  errorMessages={['Email must be in correct format']}
                />
                <TextValidator
                  name={'fax1'}
                  value={fax1}
                  margin="dense"
                  id="name"
                  label="Fax 1"
                  type="number"
                  style={{ paddingRight: 10 }}
                  onChange={handleSetFax1}
                />
                <TextValidator
                  name={'fax2'}
                  value={fax2}
                  margin="dense"
                  id="name"
                  label="Fax 2"
                  type="number"
                  style={{ paddingRight: 10 }}
                  onChange={handleSetFax2}
                />
                <TextValidator
                  name={'fax3'}
                  value={fax3}
                  margin="dense"
                  id="name"
                  label="Fax 3"
                  type="number"
                  style={{ paddingRight: 10 }}
                  onChange={handleSetFax3}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type={'submit'}>Request</Button>
            </DialogActions>
            <ShowError errorMessage={errorMessage}></ShowError>
          </ValidatorForm>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <p style={{ padding: 10 }}>
            Request for statement {statementBeingRequested} submitted successfully. Statements may take up to 10 minutes
            to process.
          </p>
        </div>
      )}
    </Dialog>
  )
}
export default StatementRequestModal
