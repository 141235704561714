import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { DataTableDisplayColumns, Subaccount } from '../../types/types'
import TablePagination from '@material-ui/core/TablePagination'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import NewSubAccountModal from '../Modals/NewSubAccountModal'
import { useStore } from '../../store/StoreProvider'
import ConfirmDeleteSubaccountModal from '../Modals/ConfirmDeleteSubaccountModal'

const useStyles = makeStyles({
  warningText: {
    color: '#ed2224',
  },
})

interface ShowErrorProps {
  errorMessage: string
}
export const ShowError = ({ errorMessage }: ShowErrorProps) => {
  const classes = useStyles()

  return (
    <>
      <p className={classes.warningText}>{errorMessage}</p>
    </>
  )
}
export default ShowError
