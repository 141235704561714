import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { HistoryTableDisplay, DataTableDisplayColumns } from '../../types/types'
import { useStore } from '../../store/StoreProvider'
import TablePagination from '@material-ui/core/TablePagination'
import moment from 'moment'
import { TextField } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import ClearIcon from '@material-ui/icons/Clear'
import SaveIcon from '@material-ui/icons/Save'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { useKeyPress } from '@react-typed-hooks/use-key-press'
import TransactionsDetailModal from '../Modals/TransactionsDetailModal'
import InfoIcon from '@material-ui/icons/Info'
import DataLoadingSpinner from '../Global/DataLoadingSpinner'
import { DaterangePicker } from '../Global/DaterangePicker'

const useStyles = makeStyles({
  tableHead: {
    backgroundColor: '#ededee',
  },
  headers: {
    fontWeight: 'bold',
  },
  searchAndClearWrapper: {
    display: 'flex',
    paddingRight: 20,
  },
  headTotals: {
    display: 'flex',
    marginRight: 30,
  },
  headTotalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateInputBox: {
    maxWidth: 75,
  },
  searchIcon: {
    color: '#FF2045',
  },
})

export const TransactionHistoryDataDisplay: React.FunctionComponent<DataTableDisplayColumns> = (
  labels: DataTableDisplayColumns
) => {
  const { user } = useStore()
  const classes = useStyles()

  const { historyDataTable } = useStore()
  const { historyFilteredDataTable, setHistoryFilteredDataTable } = useStore()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [productDropdownValues, setProductDropdownValues] = useState<string[]>([])
  const [locationNameDropdownValues, setLocationNameDropdownValues] = useState<string[]>([])
  const [locationNumberDropdownValues, setLocationNumberDropdownValues] = useState<string[]>([])
  const [displayedDateDropdownValue, setDisplayedDateDropdownValue] = useState<string>('currentMonth')

  const [productFilterValue, setProductFilterValue] = useState('')
  const [locationNameFilterValue, setLocationNameFilterValue] = useState('')
  const [locationNumberFilterValue, setLocationNumberFilterValue] = useState('')
  const [cardFilterValue, setCardFilterValue] = useState('')
  const [cardNumberFilterValue, setCardNumberFilterValue] = useState('')
  const [vehicleFilterValue, setVehicleFilterValue] = useState('')
  const [vehicleNumberFilterValue, setVehicleNumberFilterValue] = useState('')
  const [miscEntryFilterValue, setMiscEntryFilterValue] = useState('')
  const [invoiceFilterValue, setInvoiceFilterValue] = useState('')
  const [totalGallons, setTotalGallons] = useState<number>(0)
  const [totalFederalTax, setTotalFederalTax] = useState<number>(0)
  const [totalStateTax, setTotalStateTax] = useState<number>(0)
  const [totalSalesTax, setTotalSalesTax] = useState<number>(0)
  const [totalOtherTax, setTotalOtherTax] = useState<number>(0)

  // state management for transaction detail modal
  const [currentTransactionDetailModalId, setCurrentTransactionDetailModalId] = useState<number>(0)
  const [currentTransactionDetailModalCardLabel, setCurrentTransactionDetailModalCardLabel] = useState<string>('')
  const [currentTransactionDetailModalDrivNum, setCurrentTransactionDetailModalDrivNum] = useState<number>(0)
  const [currentTransactionDetailModalInvNum, setCurrentTransactionDetailModalInvNum] = useState<number>(0)
  const [currentTransactionDetailModalVehicleLabel, setCurrentTransactionDetailModalVehicleLabel] = useState<string>('')
  const [currentTransactionDetailModalVehNum, setCurrentTransactionDetailModalVehNum] = useState<number>(0)
  const [currentTransactionDetailModalInvoiceAmt, setCurrentTransactionDetailModalInvoiceAmt] = useState<number>(0)
  const [currentTransactionDetailModalTransAmt, setCurrentTransactionDetailModalTransAmt] = useState<number>(0)
  const [currentTransactionDetailModalSiteName, setCurrentTransactionDetailModalSiteName] = useState<string>('')
  const [currentTransactionDetailModalGallons, setCurrentTransactionDetailModalGallons] = useState<number>(0)
  const [currentTransactionDetailModalProdNameShort, setCurrentTransactionDetailModalProdNameShort] =
    useState<string>('')
  const [currentTransactionDetailModalStamp, setCurrentTransactionDetailModalStamp] = useState<string>('')
  const [currentTransactionDetailModalEntryNum, setCurrentTransactionDetailModalEntryNum] = useState<number>(0)
  const [currentTransactionDetailModalFedTaxAmt, setCurrentTransactionDetailModalFedTaxAmt] = useState<number>(0)
  const [currentTransactionDetailModalStateTaxAmt, setCurrentTransactionDetailModalStateTaxAmt] = useState<number>(0)
  const [currentTransactionDetailModalSalesTaxAmt, setCurrentTransactionDetailModalSalesTaxAmt] = useState<number>(0)
  const [currentTransactionDetailModalOtherTaxAmt, setCurrentTransactionDetailModalOtherTaxAmt] = useState<number>(0)
  const [openTransactionDetailModal, setOpenTransactionDetailModal] = useState(false)

  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState<number>(0)
  const [startDate, setStartDate] = useState<Date>(moment().startOf('month').toDate())
  const [earliestDate, setEarliestDate] = useState<Date>(moment().toDate())
  const [endDate, setEndDate] = useState<Date>(moment().toDate())
  const isEnterPressed = useKeyPress({ targetKey: 'Enter' })

  const filterValues = [
    invoiceFilterValue,
    miscEntryFilterValue,
    vehicleFilterValue,
    vehicleNumberFilterValue,
    cardFilterValue,
    cardNumberFilterValue,
    locationNameFilterValue,
    productFilterValue,
    endDate,
    startDate,
  ]

  const handleOpenTransactionDetailModal = (
    id: number,
    cardLabel: string,
    drivNum: number,
    invNum: number,
    vehicleLabel: string,
    vehNum: number,
    invoiceAmt: number,
    transAmt: number,
    siteName: string,
    gallons: number,
    prodNameShort: string,
    stamp: string,
    entryNum: number,
    fedTaxAmt: number,
    stateTaxAmt: number,
    salesTaxAmt: number,
    otherTaxAmt: number
  ) => {
    setCurrentTransactionDetailModalId(id)
    setCurrentTransactionDetailModalCardLabel(cardLabel)
    setCurrentTransactionDetailModalDrivNum(drivNum)
    setCurrentTransactionDetailModalInvNum(invNum)
    setCurrentTransactionDetailModalVehicleLabel(vehicleLabel)
    setCurrentTransactionDetailModalVehNum(vehNum)
    setCurrentTransactionDetailModalInvoiceAmt(invoiceAmt)
    setCurrentTransactionDetailModalTransAmt(transAmt)
    setCurrentTransactionDetailModalSiteName(siteName)
    setCurrentTransactionDetailModalGallons(gallons)
    setCurrentTransactionDetailModalProdNameShort(prodNameShort)
    setCurrentTransactionDetailModalStamp(stamp)
    setCurrentTransactionDetailModalEntryNum(entryNum)
    setCurrentTransactionDetailModalFedTaxAmt(fedTaxAmt)
    setCurrentTransactionDetailModalStateTaxAmt(stateTaxAmt)
    setCurrentTransactionDetailModalSalesTaxAmt(salesTaxAmt)
    setCurrentTransactionDetailModalOtherTaxAmt(otherTaxAmt)
    setOpenTransactionDetailModal(true)
  }

  const handleTransactionDetailsClickClose = () => {
    setOpenTransactionDetailModal(false)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }
  const sumGallons = () => {
    const sumGallons: number | undefined = historyFilteredDataTable?.reduce((accum, item) => accum + item.gallons, 0)
    setTotalGallons(sumGallons ? sumGallons : 0)
  }
  const sumFederalTax = () => {
    const sumFederalTax: number | undefined = historyFilteredDataTable?.reduce(
      (accum, item) => accum + item.fedTaxAmt,
      0
    )
    setTotalFederalTax(sumFederalTax ? sumFederalTax : 0)
  }
  const sumStateTax = () => {
    const sumStateTax: number | undefined = historyFilteredDataTable?.reduce(
      (accum, item) => accum + item.stateTaxAmt,
      0
    )
    setTotalStateTax(sumStateTax ? sumStateTax : 0)
  }
  const sumSalesTax = () => {
    const sumSalesTax: number | undefined = historyFilteredDataTable?.reduce(
      (accum, item) => accum + item.salesTaxAmt,
      0
    )
    setTotalSalesTax(sumSalesTax ? sumSalesTax : 0)
  }
  const sumOtherTax = () => {
    const sumOtherTax: number | undefined = historyFilteredDataTable?.reduce(
      (accum, item) => accum + item.otherTaxAmt,
      0
    )
    setTotalOtherTax(sumOtherTax ? sumOtherTax : 0)
  }
  const sumInvoiceAmount = () => {
    const sumInvoiceAmounts: number | undefined = historyFilteredDataTable?.reduce(
      (accum, item) => accum + item.invoiceAmt,
      0
    )
    setTotalInvoiceAmount(sumInvoiceAmounts ? sumInvoiceAmounts : 0)
  }

  const sortByDate = () => {
    const sortedDataTable: HistoryTableDisplay[] | undefined = historyFilteredDataTable?.sort(
      (a: HistoryTableDisplay, b: HistoryTableDisplay) => {
        const dateA = new Date(a.stamp)
        const dateB = new Date(b.stamp)
        return dateB.valueOf() - dateA.valueOf()
      }
    )
    setHistoryFilteredDataTable(sortedDataTable)
  }
  const downloadDataFromTable = () => {
    const element = document.createElement('a')
    let fileToDownload: string[][] = []
    const titles: string[] = [
      'ID',
      'Date',
      'Card',
      'Card Number',
      'Invoice Number',
      'Vehicle',
      'Vehicle Number',
      'Invoice Amount',
      'Transaction Amount',
      'Site Name',
      'Site Number',
      'Gallons Purchased',
      'Product Name',
      'Entry Number',
      'Federal Tax Amount',
      'State Tax Amount',
      'Sales Tax Amount',
      'Other Tax Amount',
    ]
    fileToDownload.push(titles)
    historyFilteredDataTable?.forEach((item, i) => {
      const currentItem: string[] = [
        `"${item.id.toString()}"`,
        `"${moment.unix(+item.stamp).format('MM/DD/YYYY')} ${moment.unix(+item.stamp).format('hh:mm a')}"`,
        `"${item.cardLabel?.toString()}"`,
        `"${item.drivNum.toString()}"`,
        `"${item.invNum.toString()}"`,
        `"${item.vehicleLabel?.toString()}"`,
        `"${item.vehNum.toString()}"`,
        `"${item.invoiceAmt.toString()}"`,
        `"${item.transAmt.toString()}"`,
        `"${item.siteName}"`,
        `"${item.siteNum}"`,
        `"${item.gallons.toFixed(2)}"`,
        `"${item.prodNameShort}"`,
        `"${item.entryNum.toString()}"`,
        `"${item.fedTaxAmt.toString()}"`,
        `"${item.stateTaxAmt.toString()}"`,
        `"${item.salesTaxAmt.toString()}"`,
        `"${item.otherTaxAmt.toString()}"`,
      ]
      fileToDownload.push(currentItem)
    })
    let csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(fileToDownload.map((e) => e.join(',')).join('\n'))

    const link = document.createElement('a')
    link.setAttribute('href', csvContent)
    link.setAttribute(
      'download',
      `${moment() + (user && user?.customerName ? user?.customerName.replace(/ /g, '') : '')}.csv`
    )
    link.click()
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  const handleProductFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setProductFilterValue(event.target.value as string)
  }
  const handleLocationNameFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLocationNameFilterValue(event.target.value as string)
  }
  const handleLocationNumberFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLocationNumberFilterValue(event.target.value as string)
  }

  const generateValuesForProductFilterDropdown = (values: HistoryTableDisplay[] | undefined) => {
    const productNames: string[] = []
    values
      ? values.forEach(
          (element) => !productNames.includes(element.prodNameShort) && productNames.push(element.prodNameShort)
        )
      : console.log('')
    setProductDropdownValues(productNames)
  }
  const generateValuesForLocationFilterDropdown = (values: HistoryTableDisplay[] | undefined) => {
    const locationNames: string[] = []
    values
      ? values.forEach((element) => !locationNames.includes(element.siteName) && locationNames.push(element.siteName))
      : console.log('')
    setLocationNameDropdownValues(locationNames)
  }

  const generateValuesForSiteNumberDropdown = (values: HistoryTableDisplay[] | undefined) => {
    const locationNumbers: string[] = []
    values
      ? values.forEach((element) => !locationNumbers.includes(element.siteNum) && locationNumbers.push(element.siteNum))
      : console.log('')
    setLocationNumberDropdownValues(locationNumbers)
  }

  const clearAllFilters = () => {
    const endDate = moment().toDate()
    const startDate = moment().subtract(1, 'months').toDate()
    setInvoiceFilterValue('')
    setMiscEntryFilterValue('')
    setVehicleFilterValue('')
    setVehicleNumberFilterValue('')
    setCardFilterValue('')
    setCardNumberFilterValue('')
    setLocationNumberFilterValue('')
    setLocationNameFilterValue('')
    setProductFilterValue('')
    setEndDate(moment().toDate())
    setStartDate(moment().startOf('month').toDate())
    setDisplayedDateDropdownValue('currentMonth')
  }

  const createDropdowns = async () => {
    generateValuesForLocationFilterDropdown(historyDataTable)
    generateValuesForProductFilterDropdown(historyDataTable)
    generateValuesForSiteNumberDropdown(historyDataTable)
  }

  const filterTable = () => {
    // When filtering return to first page of dataset
    setPage(0)
    sortByDate()
    // Makes a copy of all data on data table. This serves as source of truth for all filters going down the line
    let tempFilteredTable: HistoryTableDisplay[] | undefined = historyDataTable

    //Filtering is exclusive so if it fails any one filter it is removed
    if (startDate) {
      tempFilteredTable = tempFilteredTable?.filter((row) => moment.unix(+row.stamp).isSameOrAfter(moment(startDate).startOf('day')))
    }
    if (endDate) {
      tempFilteredTable = tempFilteredTable?.filter((row) => moment.unix(+row.stamp).isSameOrBefore(moment(endDate).endOf('day')))
    }
    if (cardFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.cardLabel?.toString().startsWith(cardFilterValue))
    }
    if (cardNumberFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.drivNum.toString().startsWith(cardNumberFilterValue))
    }
    if (vehicleFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.vehicleLabel?.toString().startsWith(vehicleFilterValue))
    }
    if (vehicleNumberFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.vehNum.toString().startsWith(vehicleNumberFilterValue))
    }
    if (miscEntryFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.entryNum.toString().startsWith(miscEntryFilterValue))
    }
    if (invoiceFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => row.invNum.toString().startsWith(invoiceFilterValue))
    }
    if (productFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.prodNameShort.toString().startsWith(productFilterValue)
      )
    }
    if (locationNameFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.siteName.toString().startsWith(locationNameFilterValue)
      )
    }
    if (locationNumberFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.siteNum.toString().startsWith(locationNumberFilterValue)
      )
    }
    generateValuesForLocationFilterDropdown(historyDataTable)
    generateValuesForProductFilterDropdown(historyDataTable)
    generateValuesForSiteNumberDropdown(historyDataTable)
    setHistoryFilteredDataTable(tempFilteredTable)
  }

  const getEarliestDate = () => {
    let moments = historyDataTable?.map(d => moment(d.stamp)),
        maxDate = moment.max(moments ? moments : [])
    setEarliestDate(maxDate.toDate())
  }

  useEffect(() => {
    // Any time one of the filters is updated re-run the filtering logic
    filterTable()
  }, filterValues)

  useEffect(() => {
    sortByDate()
    sumGallons()
    sumFederalTax()
    sumStateTax()
    sumSalesTax()
    sumOtherTax()
    sumInvoiceAmount()
  }, [historyFilteredDataTable])

  useEffect(() => {
    if (!historyDataTable) {
      createDropdowns()
    }
    // performs initial date filtering
    filterTable()
    getEarliestDate()
  }, [historyDataTable])
  useEffect(() => {
    filterTable()
  }, [isEnterPressed])

  const getHistoryTableRow = (row: HistoryTableDisplay, i: number) => {
    return (
      <>
        <TableRow key={i}>
          <TableCell component="th" scope="row">
            <InfoIcon
              onClick={() => {
                handleOpenTransactionDetailModal(
                  row.id,
                  row.cardLabel,
                  row.drivNum,
                  row.invNum,
                  row.vehicleLabel,
                  row.vehNum,
                  row.invoiceAmt,
                  row.transAmt,
                  row.siteName,
                  row.gallons,
                  row.prodNameShort,
                  moment.unix(+row.stamp).format('MM/DD/YYYY') + ' ' + moment.unix(+row.stamp).format('hh:mm a'),
                  row.entryNum,
                  row.fedTaxAmt,
                  row.salesTaxAmt,
                  row.otherTaxAmt,
                  row.stateTaxAmt
                )
              }}
            ></InfoIcon>
          </TableCell>
          <TableCell component="th" scope="row">
            {moment.unix(+row.stamp).format('MM/DD/YYYY') + ' ' + moment.unix(+row.stamp).format('hh:mm a')}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.cardLabel}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.drivNum}
          </TableCell>
          <TableCell>{row.vehicleLabel}</TableCell>
          <TableCell>{row.vehNum}</TableCell>
          <TableCell>{row.entryNum}</TableCell>
          <TableCell>{row.invNum}</TableCell>
          <TableCell>${row.invoiceAmt}</TableCell>
          <TableCell>{row.gallons.toFixed(2)}</TableCell>
          <TableCell>${(row.invoiceAmt / row.gallons).toFixed(2)}</TableCell>
          <TableCell>{row.prodNameShort}</TableCell>
          <TableCell>{row.siteNum}</TableCell>
          <TableCell>{row.siteName}</TableCell>
        </TableRow>
      </>
    )
  }

  // TODO: Replace this with a composable version that can accept a component as a prop for the rows
  return !historyDataTable ? (
    <DataLoadingSpinner />
  ) : (
    <Paper>
      <div className={classes.searchAndClearWrapper}>
        <Tooltip disableFocusListener title="Clear all filters">
          <IconButton onClick={() => clearAllFilters()}>
            <ClearIcon></ClearIcon>
          </IconButton>
        </Tooltip>
        <Tooltip disableFocusListener title="Export data to txt file">
          <IconButton onClick={() => downloadDataFromTable()}>
            <SaveIcon></SaveIcon>
          </IconButton>
        </Tooltip>
        <div className={classes.headTotalContainer}>
          <div className={classes.headTotals}>Gallons: {totalGallons.toFixed(2)}</div>
          <div className={classes.headTotals}>Federal Tax: {'$' + totalFederalTax.toFixed(2)}</div>
          <div className={classes.headTotals}>State Tax: {'$' + totalStateTax.toFixed(2)}</div>
          <div className={classes.headTotals}>Sales Tax: {'$' + totalSalesTax.toFixed(2)}</div>
          <div className={classes.headTotals}>Other Tax: {'$' + totalOtherTax.toFixed(2)}</div>
          <div className={classes.headTotals}>
            Total Taxes:
            {'$' + (totalFederalTax + totalStateTax + totalSalesTax + totalOtherTax).toFixed(2)}
          </div>
          <div>Total Charges: {'$' + totalInvoiceAmount.toFixed(2)}</div>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {labels.columns.map((label, i) => (
                <TableCell key={i} className={classes.headers}>
                  {label.title}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>
                <div style={{ display: 'flex', flexDirection: 'column' }}>{''}</div>
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <DaterangePicker
                    onChange={daterange => {
                      daterange.startDate && setStartDate(daterange.startDate)
                      daterange.endDate && setEndDate(daterange.endDate)
                    }}
                    initialDateRange={{startDate: startDate, endDate: endDate}}
                  />
                </div>
              </TableCell>
              <TableCell>
                <TextField onChange={(event: any) => setCardFilterValue(event.target.value)} value={cardFilterValue} />
              </TableCell>
              <TableCell>
                <TextField onChange={(event: any) => setCardNumberFilterValue(event.target.value)} value={cardNumberFilterValue} />
              </TableCell>
              <TableCell>
                <TextField
                  onChange={(event: any) => setVehicleFilterValue(event.target.value)}
                  value={vehicleFilterValue}
                />
              </TableCell>
              <TableCell>
                <TextField
                  onChange={(event: any) => setVehicleNumberFilterValue(event.target.value)}
                  value={vehicleNumberFilterValue}
                />
              </TableCell>
              <TableCell>
                <TextField
                  onChange={(event: any) => setMiscEntryFilterValue(event.target.value)}
                  value={miscEntryFilterValue}
                />
              </TableCell>
              <TableCell>
                <TextField
                  onChange={(event: any) => setInvoiceFilterValue(event.target.value)}
                  value={invoiceFilterValue}
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={productFilterValue}
                  onChange={handleProductFilterChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {productDropdownValues.map((dropdownItem, i) => (
                    <MenuItem value={dropdownItem} key={i}>
                      {dropdownItem}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={locationNumberFilterValue}
                  onChange={handleLocationNumberFilterChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {locationNumberDropdownValues.map((dropdownItem, i) => (
                    <MenuItem value={dropdownItem} key={i}>
                      {dropdownItem}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={locationNameFilterValue}
                  onChange={handleLocationNameFilterChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {locationNameDropdownValues.map((dropdownItem, i) => (
                    <MenuItem value={dropdownItem} key={i}>
                      {dropdownItem}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow></TableRow>
            <TransactionsDetailModal
              isOpen={openTransactionDetailModal}
              handleClose={handleTransactionDetailsClickClose}
              id={currentTransactionDetailModalId}
              cardLabel={currentTransactionDetailModalCardLabel}
              drivNum={currentTransactionDetailModalDrivNum}
              invNum={currentTransactionDetailModalInvNum}
              vehicleLabel={currentTransactionDetailModalVehicleLabel}
              vehNum={currentTransactionDetailModalVehNum}
              invoiceAmt={currentTransactionDetailModalInvoiceAmt}
              transAmt={currentTransactionDetailModalTransAmt}
              siteName={currentTransactionDetailModalSiteName}
              gallons={currentTransactionDetailModalGallons}
              prodNameShort={currentTransactionDetailModalProdNameShort}
              stamp={currentTransactionDetailModalStamp}
              entryNum={currentTransactionDetailModalEntryNum}
              fedTaxAmt={currentTransactionDetailModalFedTaxAmt}
              salesTaxAmt={currentTransactionDetailModalStateTaxAmt}
              otherTaxAmt={currentTransactionDetailModalSalesTaxAmt}
              stateTaxAmt={currentTransactionDetailModalOtherTaxAmt}
            />
            {/*TODO: After the routes are more hashed out make sure to come back and add a way to dynamically wrap column data in <a> tags*/}
            {/*If data is filtered map filtered data, else map all data*/}
            {historyFilteredDataTable &&
              historyFilteredDataTable
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => getHistoryTableRow(row, i))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100, 1000]}
        onPageChange={() => {}}
        count={historyFilteredDataTable && historyFilteredDataTable !== undefined ? historyFilteredDataTable.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
export default TransactionHistoryDataDisplay
