import React, { useEffect, useState } from 'react'
import axios from 'axios'
import DataLoadingSpinner from '../Global/DataLoadingSpinner'


export const DeletePendingCardEditPage = () => {
  const [customerNumber, setCustomerNumber] = useState(undefined)
  const [cardNumber, setCardNumber] = useState(undefined)
  const [requestSuccessful, setRequestSuccessful] = useState(undefined)
  const [requestLoading, setRequestLoading] = useState(false)
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    setCardNumber(queryParams.get('cardNumber'))
    setCustomerNumber(queryParams.get('customerNumber'))
  }, [])

  const deletePendingCardEditPage = async (cardNumberToDelete, customerNumberToDelete) => {
    setRequestLoading(true)
    const { data: { success }} = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/cards/deletePendingCardEdit`, {
      token: localStorage.getItem('CFN_TOKEN'),
      customerNumber: customerNumberToDelete,
      cardNumber: cardNumberToDelete,
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
    })
    setRequestLoading(false)
    setRequestSuccessful(!!success)
  }
  return (
    <>
      {requestLoading === true || [cardNumber, customerNumber].indexOf(undefined) > -1 ? (
        <DataLoadingSpinner/>
      ) : requestSuccessful === true ? (
        <span>Pending edit for card number {cardNumber} and account number: {customerNumber} successfully deleted!</span>
      ) : [cardNumber, customerNumber].indexOf(null) > -1 ? (
        <span>cardNumber or customerNumber missing</span>
      ) : (
        <span>
          Are you sure you want to delete the pending card edits for:<br/>
          <ul>
            <li>Card number: {cardNumber}</li>
            <li>Account number: {customerNumber}</li>
          </ul>
          <button onClick={() => deletePendingCardEditPage(cardNumber, customerNumber)}>Delete?</button>
          {requestSuccessful === false && (
            <span>Delete failed, please try again.</span>
          )}
        </span>
      )}
    </>
  )
}

export default DeletePendingCardEditPage
