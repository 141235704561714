import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
    textAlign: 'left',
  },
  formSection: {
    marginTop: 25,
  },
})

interface EmailInputProps {
  email: string
  handleChangeEmailAddress: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const EmailInput = ({ email, handleChangeEmailAddress }: EmailInputProps) => {
  const classes = useStyles()

  return (
    <>
      <FormLabel className={classes.title}>Email Address</FormLabel>
      <TextValidator
        name={'email'}
        value={email}
        margin="dense"
        id="email"
        type="email"
        validators={['required', 'isEmail']}
        errorMessages={['Email address is required', 'Email Address Must Be in Proper Format']}
        style={{
          display: 'flex',
        }}
        onChange={handleChangeEmailAddress}
      />
    </>
  )
}
export default EmailInput
