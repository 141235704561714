import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { statementLabels } from '../../Db'
import axios from 'axios'
import { useStore } from '../../store/StoreProvider'
import { Statement } from '../../types/types'
import StatementDisplay from './StatementDisplay'
import Media from 'react-media'
import ResponsiveStatementDisplay from './ResponsiveStatementDisplay'
import DataLoadingSpinner from '../Global/DataLoadingSpinner'

const styles = makeStyles({
  topImgContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'center',
  },
  displayImg: {
    width: '100%',
  },
  middleBox: {
    flexGrow: 2,
    minWidth: '60%',
  },
  flexContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
  },
  sumDataTable: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    marginBottom: 10,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'column',
  },
  pageHeader: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export const StatementsPage = () => {
  const classes = styles()
  const [statements, setStatements] = useState<Statement[] | undefined>(undefined)
  const [filteredStatements, setFilteredStatements] = useState<Statement[] | undefined>(undefined)
  const { user } = useStore()

  useEffect(() => {
    const getStatements = async () => {
      const data = await axios.post<Statement[]>(`${process.env.REACT_APP_BASE_ROOT_URL}/statements/getStatements`, {
        token: localStorage.getItem('CFN_TOKEN'),
        custNum: user?.id,
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
      })
      setStatements(data.data)
    }
    if (!statements) {
      getStatements()
    }
  }, [statements])

  const handleChangeFilteredStatements = (statements: Statement[] | undefined) => {
    setFilteredStatements(statements)
  }

  return (
    <>
      {!statements ? (
        <DataLoadingSpinner />
      ) : (
        <div className={classes.flexContainer}>
          <Media
            query="(max-width: 879px)"
            render={() => (
              <ResponsiveStatementDisplay
                filteredStatements={filteredStatements}
                setFilteredStatements={handleChangeFilteredStatements}
                labels={statementLabels}
                statements={statements}
              ></ResponsiveStatementDisplay>
            )}
          />
          <Media
            query="(min-width: 880px)"
            render={() => (
              <StatementDisplay
                filteredStatements={filteredStatements}
                setFilteredStatements={handleChangeFilteredStatements}
                labels={statementLabels}
                statements={statements}
              ></StatementDisplay>
            )}
          />
        </div>
      )}
    </>
  )
}

export default StatementsPage
