import React, { useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ListCardsDataDisplay from './ListCardsDataDisplay'
import { listCardsLabels } from '../../Db'
import axios from 'axios'
import { useStore } from '../../store/StoreProvider'
import Media from 'react-media'
import ResponsiveListCardsDataDisplay from './ResponsiveListCardsDataDisplay'
import DataLoadingSpinner from '../Global/DataLoadingSpinner'
import { CardModifiedCb, CardsTableDisplay } from '../../types/types'

const styles = makeStyles({
  flexContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
  },
})

export const ListCardsPage = () => {
  const classes = styles()
  const {
    cardsDataTable,
    setCardsDataTableDisplay,
    setCardsFilteredDataTable,
    cardsFilteredDataTable,
  } = useStore()

  const cardModifiedCb: CardModifiedCb = (cardNumber: string) => {
    const setModifiedCardPendingEdit = (cardList?: CardsTableDisplay[]) => {
      return cardList?.map(card => {
        if(card.card_num == cardNumber) {
          card.pending_edit = true
        }
        return card
      })
    }
    setCardsDataTableDisplay(setModifiedCardPendingEdit(cardsDataTable))
    setCardsFilteredDataTable && setCardsFilteredDataTable(setModifiedCardPendingEdit(cardsFilteredDataTable))
  }

  useEffect(() => {
    const getDataTable = async () => {
      const data = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/cards`, {
        token: localStorage.getItem('CFN_TOKEN'),
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
      })
      setCardsDataTableDisplay(data.data)
    }
    if (!cardsDataTable) {
      getDataTable()
    }
  }, [cardsDataTable])

  return (
    <>
      {!cardsDataTable ? (
        <DataLoadingSpinner />
      ) : (
        <div className={classes.flexContainer}>
          <Media
            query="(max-width: 879px)"
            render={() => (
              <ResponsiveListCardsDataDisplay
                columns={listCardsLabels.columns}
                cardModifiedCb={cardModifiedCb}
              />
            )}
          />
          <Media
            query="(min-width: 880px)"
            render={() => (
              <ListCardsDataDisplay
                columns={listCardsLabels.columns}
                cardModifiedCb={cardModifiedCb}
              />
            )}
          />
        </div>
      )}
    </>
  )
}

export default ListCardsPage
