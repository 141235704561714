import React from 'react'
import Button from '@material-ui/core/Button'
import { List, Paper } from '@material-ui/core'
import { Swipe } from '../../../types/types'
import { useHistory } from 'react-router-dom'

interface DuplicateInvoicesDeletedProps {
  recordsKept: Swipe[]
  recordsDeleted: Swipe[]
}

export const DuplicateInvoicesDeleted = ({ recordsKept, recordsDeleted }: DuplicateInvoicesDeletedProps) => {
  let history = useHistory()
  const blowThisPopsicleStand = () => {
    history.push('/adminAccountsList')
  }

  return (
    <>
      <h1>Records Kept</h1>
      <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
        <List>
          {recordsKept.map((item, i) => (
            <li key={i}>
              Id: {item.id} Card Number: {item.cardNum} Customer Number: {item.custNum} Invoice Number: {item.invNum}{' '}
              Invoice Amount: {item.invoiceAmt} Date: {item.stamp}
              <br />
            </li>
          ))}
        </List>
      </Paper>
      <h1>Records Deleted</h1>
      <Paper style={{ maxHeight: 200, overflow: 'auto' }}>
        <List>
          {recordsDeleted.map((item, i) => (
            <li key={i}>
              Id: {item.id} Card Number: {item.cardNum} Customer Number: {item.custNum} Invoice Number: {item.invNum}{' '}
              Invoice Amount: {item.invoiceAmt} Date: {item.stamp}
              <br />
            </li>
          ))}
        </List>
      </Paper>
      <Button variant="contained" onClick={blowThisPopsicleStand} style={{ marginTop: 20 }}>
        Done
      </Button>
    </>
  )
}

export default DuplicateInvoicesDeleted
