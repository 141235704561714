import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import ShowError from '../../Messages/ShowError'

const useStyles = makeStyles({
  title: {
    justifyContent: 'center',
    display: 'flex',
    paddingBottom: 10,
  },
  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
})

interface PromptForNewPasswordProps {
  customerNumber: string
  setAccountDeleted: () => void
  setRowsDeleted: (rowsDeleted: number) => void
}
interface RowsAffected {
  affectedRows: number
}

export const PromptToDeleteAccount = ({
  customerNumber,
  setAccountDeleted,
  setRowsDeleted,
}: PromptForNewPasswordProps) => {
  const classes = useStyles()
  const [errorMessage] = useState<string>('')

  const deleteAccount = async () => {
    const data = await axios.post<RowsAffected>(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/deleteAccount`, {
      token: localStorage.getItem('CFN_TOKEN'),
      customerNumber: customerNumber,
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
    })
    if (data.status === 200) {
      setRowsDeleted(data.data.affectedRows)
      setAccountDeleted()
    }
  }

  return (
    <>
      <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h5" component="h2" className={classes.title}>
          Confirm Delete Account
        </Typography>
        <ShowError
          errorMessage={
            `This is the last warning. By clicking this button you will be deleting account number ${customerNumber}!`
          }
        ></ShowError>
        <div className={classes.controlWrapper}>
          <Button variant="contained" onClick={deleteAccount}>
            Delete Account
          </Button>
          <ShowError errorMessage={errorMessage}></ShowError>
        </div>
      </CardContent>
    </>
  )
}

export default PromptToDeleteAccount
