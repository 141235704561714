import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import './fonts/Overpass/Overpass-Bold.ttf'
import StoreProvider, { useStore } from './store/StoreProvider'
import 'moment-timezone'
import GetOldUserEmailModal from './components/Modals/GetOldUserEmailModal'

const history = createBrowserHistory()

ReactDOM.render(
  <Router history={history}>
    <StoreProvider>
      <GetOldUserEmailModal>
        <App />
      </GetOldUserEmailModal>
    </StoreProvider>
  </Router>,

  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
