import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import ModalInformation from './ModalInputs/ModalInformation'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  leftLabel: {
    marginRight: 20,
  },
  flexContainer: {
    display: 'flex',
  },
  formSection: {
    marginTop: 25,
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
})
interface TransactionsDetailModalProps {
  isOpen: boolean
  handleClose: () => void
  id: number
  cardLabel: string
  drivNum: number
  invNum: number
  vehicleLabel: string
  vehNum: number
  invoiceAmt: number
  transAmt: number
  siteName: string
  gallons: number
  prodNameShort: string
  stamp: string
  entryNum: number
  fedTaxAmt: number
  stateTaxAmt: number
  salesTaxAmt: number
  otherTaxAmt: number
}

export const TransactionsDetailModal = ({
  isOpen,
  handleClose,
  id,
  cardLabel,
  drivNum,
  invNum,
  vehicleLabel,
  vehNum,
  invoiceAmt,
  transAmt,
  siteName,
  gallons,
  prodNameShort,
  stamp,
  entryNum,
  fedTaxAmt,
  stateTaxAmt,
  salesTaxAmt,
  otherTaxAmt,
}: TransactionsDetailModalProps) => {
  const classes = useStyles()

  const closeModal = () => {
    handleClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose()
      }}
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Invoice Details</DialogTitle>

      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ModalInformation title={'Invoice Number'} information={invNum}></ModalInformation>
          <ModalInformation title={'Card'} information={cardLabel}></ModalInformation>
          <ModalInformation title={'Card Number'} information={drivNum}></ModalInformation>
          <ModalInformation title={'Vehicle'} information={vehicleLabel}></ModalInformation>
          <ModalInformation title={'Vehicle Number'} information={vehNum}></ModalInformation>
          <ModalInformation title={'Invoice Amount'} information={invoiceAmt}></ModalInformation>
          <ModalInformation title={'Transaction Amount'} information={transAmt}></ModalInformation>
          <ModalInformation title={'Location'} information={siteName}></ModalInformation>
          <ModalInformation title={'Gallons'} information={gallons}></ModalInformation>
          <ModalInformation title={'Prod Name Short'} information={prodNameShort}></ModalInformation>
          <ModalInformation title={'Date'} information={stamp}></ModalInformation>
          <ModalInformation title={'Misc Entry'} information={entryNum}></ModalInformation>
          <ModalInformation title={'Federal Tax Amount'} information={fedTaxAmt}></ModalInformation>
          <ModalInformation title={'State Tax Amount'} information={stateTaxAmt}></ModalInformation>
          <ModalInformation title={'Sales Tax Amount'} information={salesTaxAmt}></ModalInformation>
          <ModalInformation title={'Other Tax Amount'} information={otherTaxAmt}></ModalInformation>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
export default TransactionsDetailModal
