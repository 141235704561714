import React from 'react'
import Button from '@material-ui/core/Button'
// @ts-ignore
import * as Spinner from 'react-spinkit'

interface PromptUserToFetchDuplicateInvoicesProps {
  getDuplicateInvoices: () => void
  isGettingDuplicateInvoices: boolean
}

export const PromptUserToFetchDuplicateInvoices = ({
  getDuplicateInvoices,
  isGettingDuplicateInvoices,
}: PromptUserToFetchDuplicateInvoicesProps) => {
  return (
    <>
      <p>This script will search the database for duplicate invoice numbers and remove all but the most recent.</p>
      <p>This process can take a while to complete</p>
      {!isGettingDuplicateInvoices ? (
        <Button variant="contained" onClick={getDuplicateInvoices}>
          Get Duplicate Invoices
        </Button>
      ) : (
        <>
          <p>Fetching Duplicate Records, please stand by...</p>
          <Spinner name="pacman" color="#ed2224" />
        </>
      )}
    </>
  )
}

export default PromptUserToFetchDuplicateInvoices
