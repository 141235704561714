import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  formSection: {
    marginTop: 25,
  },
})

interface AccountNumberInputProps {
  accountNumber: string
  handleChangeAccountNumber: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const AccountNumberInput = ({ accountNumber, handleChangeAccountNumber }: AccountNumberInputProps) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.formSection}>
        <FormLabel className={classes.title}>Account Number</FormLabel>
        <TextValidator
          name={'accountNumber'}
          value={accountNumber}
          margin="dense"
          id="name"
          type="name"
          validators={['required']}
          errorMessages={['Account number is required']}
          style={{
            display: 'flex',
          }}
          onChange={handleChangeAccountNumber}
        />
      </div>
    </>
  )
}
export default AccountNumberInput
