import React from 'react'
import { TextValidator } from 'react-material-ui-form-validator'
import FormLabel from '@material-ui/core/FormLabel'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
})

interface ChangeCardLabelQuestionProps {
  newCardLabel: string
  shouldChangeCardLabel: boolean
  handleChangeShouldChangeCardLabel: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeNewCardLabel: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ChangeCardLabelQuestion = ({
  newCardLabel,
  shouldChangeCardLabel,
  handleChangeShouldChangeCardLabel,
  handleChangeNewCardLabel,
}: ChangeCardLabelQuestionProps) => {
  const classes = useStyles()

  return (
    <>
      <div style={{ marginTop: 25 }}>
        <FormControl component="fieldset">
          <div style={{ marginTop: 25 }}>
            <FormLabel className={classes.title} focused={false}>
              Would You Like To Change The Card Label?
            </FormLabel>
            <RadioGroup
              aria-label="newCardLabel"
              name="newCardLabel"
              onChange={handleChangeShouldChangeCardLabel}
              value={String(shouldChangeCardLabel)}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <FormControlLabel
                value="true"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value="false"
                control={
                  <Radio
                    classes={{
                      root: classes.radio,
                      checked: classes.checked,
                    }}
                  />
                }
                label="No"
              />
              <FormLabel
                style={{ marginLeft: 20 }}
                className={shouldChangeCardLabel ? classes.title : classes.titleOff}
                focused={false}
              >
                Card Label
              </FormLabel>
              <TextValidator
                name={'newCardLabel'}
                value={newCardLabel}
                margin="dense"
                id="name"
                type="name"
                disabled={!shouldChangeCardLabel}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 20,
                  maxWidth: 75,
                }}
                inputProps={{
                  maxLength: 20,
                }}
                onChange={handleChangeNewCardLabel}
                helperText={`${newCardLabel.length}/20`}
                validators={shouldChangeCardLabel ? ['required'] : []}
                errorMessages={shouldChangeCardLabel ? ['New label is required'] : []}
              />
            </RadioGroup>
          </div>
        </FormControl>
      </div>
    </>
  )
}
export default ChangeCardLabelQuestion
