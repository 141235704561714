import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  formSection: {
    marginTop: 25,
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
})
interface ProductsAuthorizedInputProps {
  allProductsAuthorized: boolean
  dieselExhaustFluid: boolean
  dieselAuthorized: boolean
  compressedNaturalGas: boolean
  oilAuthorized: boolean
  unleadedAuthorized: boolean
  mediumUnleadedAuthorized: boolean
  premiumUnleadedAuthorized: boolean
  offRoadAuthorized: boolean
  handleAllProductsAuthorized: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleDieselExhaustFluid: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleCompressedNaturalGas: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleDieselAuthorized: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOilAuthorized: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleUnleadedAuthorized: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleMediumUnleadedAuthorized: (event: React.ChangeEvent<HTMLInputElement>) => void
  handlePremiumUnleadedAuthorized: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleOffRoadAuthorized: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ProductsAuthorizedInput = ({
  allProductsAuthorized,
  dieselExhaustFluid,
  handleDieselExhaustFluid,
  compressedNaturalGas,
  handleCompressedNaturalGas,
  dieselAuthorized,
  oilAuthorized,
  unleadedAuthorized,
  mediumUnleadedAuthorized,
  premiumUnleadedAuthorized,
  offRoadAuthorized,
  handleOffRoadAuthorized,
  handlePremiumUnleadedAuthorized,
  handleMediumUnleadedAuthorized,
  handleUnleadedAuthorized,
  handleOilAuthorized,
  handleDieselAuthorized,
  handleAllProductsAuthorized,
}: ProductsAuthorizedInputProps) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.formSection}>
        <FormGroup style={{ maxHeight: 250 }}>
          <FormLabel className={classes.title}>Authorized Products</FormLabel>
          <div>
            <FormControlLabel
              control={<Checkbox name="all" value={allProductsAuthorized} onChange={handleAllProductsAuthorized} />}
              label="All"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="diesel"
                  disabled={allProductsAuthorized}
                  value={dieselAuthorized}
                  onChange={handleDieselAuthorized}
                />
              }
              label="Diesel"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="oil"
                  disabled={allProductsAuthorized}
                  value={oilAuthorized}
                  onChange={handleOilAuthorized}
                />
              }
              label="Oil"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="unleaded"
                  disabled={allProductsAuthorized}
                  value={unleadedAuthorized}
                  onChange={handleUnleadedAuthorized}
                />
              }
              label="Unleaded"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="mediumunleaded"
                  disabled={allProductsAuthorized}
                  value={mediumUnleadedAuthorized}
                  onChange={handleMediumUnleadedAuthorized}
                />
              }
              label="Medium Unleaded"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="premiumunleaded"
                  disabled={allProductsAuthorized}
                  value={premiumUnleadedAuthorized}
                  onChange={handlePremiumUnleadedAuthorized}
                />
              }
              label="Premium Unleaded"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="offroaddiesel"
                  disabled={allProductsAuthorized}
                  value={offRoadAuthorized}
                  onChange={handleOffRoadAuthorized}
                />
              }
              label="Offroad Diesel"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="dieselexhaustfluid"
                  disabled={allProductsAuthorized}
                  value={dieselExhaustFluid}
                  onChange={handleDieselExhaustFluid}
                />
              }
              label="Diesel Exhaust Fluid"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="compressednaturalgas"
                  disabled={allProductsAuthorized}
                  value={compressedNaturalGas}
                  onChange={handleCompressedNaturalGas}
                />
              }
              label="Compressed Natural Gas"
            />
          </div>
        </FormGroup>
      </div>
    </>
  )
}
export default ProductsAuthorizedInput
