import React, { useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { SpinnerCircular } from 'spinners-react/lib/esm/SpinnerCircular'

const styles = makeStyles({
  flexContainer: {
    display: 'flex',
    width: '100vw',
    height: 'calc(100vh - 264px)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  flexItem: {
    height: 100,
    width: 100,
    display: 'flex',
  },
})

export const DataLoadingSpinner = () => {
  const classes = styles()

  return (
    <div className={classes.flexContainer}>
      <div className={classes.flexItem}>
        <SpinnerCircular thickness={180} className={classes.flexItem} color={'#D02C27'} size={90} />
      </div>
    </div>
  )
}

export default DataLoadingSpinner
