import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import axios from 'axios'
import { User } from '../../types/types'
import { useStore } from '../../store/StoreProvider'
import { useHistory, withRouter } from 'react-router-dom'
import { getUserToSet } from '../../utils/helpers'
import { Paper } from '@material-ui/core'
import ForgotPasswordModal from '../Modals/ForgotPasswordModal'
import { useKeyPress } from '@react-typed-hooks/use-key-press'
import ShowError from '../Messages/ShowError'
import AccountNeedsEmailModal from "../Modals/AccountNeedsEmailModal";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © TruckNTravel '}
      {new Date().getFullYear()}
    </Typography>
  )
}

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: '#00529e',
        },
        '&:hover $notchedOutline': {
          borderColor: '#00529e',
        },
        '&$focused $notchedOutline': {
          borderColor: '#00529e',
        },
        '&$legendLabelled': {
          color: '#00529e',
        },
        '&$PrivateNotchedOutline-legend': {
          color: '#00529e',
        },
      },
    },
  },
})

const useStyles = makeStyles((theme) => ({
  title: {},
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'black',
    backgroundColor: '#ed2224',
  },
}))

export const UserLogin = () => {
  // listener for enter keydown event
  const isEnterPressed = useKeyPress({ targetKey: 'Enter' })
  useEffect(() => {
    if (isEnterPressed === true) {
      // only using then to avoid warnings from React
      authenticateUser().then((r) => console.log(''))
    }
  }, [isEnterPressed])

  const initialUsername: string | null = localStorage.getItem('CFN_USERNAME')
    ? localStorage.getItem('CFN_USERNAME')
    : ''
  const initialAccountNumber: string | null = localStorage.getItem('CFN_ACCOUNTNUMBER')
    ? localStorage.getItem('CFN_ACCOUNTNUMBER')
    : ''
  const classes = useStyles()
  const { user, setUser } = useStore()
  const [username, setUsername] = useState<string | null>(initialUsername)
  const [password, setPassword] = useState<string>()
  const [customerNumber, setCustomerNumber] = useState<string | null>(initialAccountNumber)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [saveCredentials, setSaveCredentials] = useState(false)
  const [openAccountNeedsEmailModal, setOpenAccountNeedsEmailModal] = useState(false)
  const [isRequestPasswordModalOpen, setIsRequestPasswordModalOpen] = useState(false)
  let history = useHistory()

  const handleCloseRequestPasswordModal = () => {
    setIsRequestPasswordModalOpen(false)
  }
  const handleChangeSaveCredentials = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSaveCredentials(event.target.checked)
  }

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }
  const handleChangeCustomerNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerNumber(event.target.value)
  }
  // todo make this auth process work with a dual endpoint system (getUserData and authenticateUser)
  // const authenticateUser = async () => {
  //   try {
  //     const authenticateCredentialsAndSetToken = await axios.post<User>(`${process.env.REACT_APP_BASE_ROOT_URL}/authentication/getAuthenticationToken`, {
  //       username: username,
  //       password: password,
  //       accountNumber: customerNumber,
  //       level: 'user',
  //     })
  //     if (authenticateCredentialsAndSetToken && authenticateCredentialsAndSetToken.status === 200) {
  //       // sets user data token to local storage and then sets the user to store based on that token
  //       localStorage.setItem('CFN_TOKEN', authenticateCredentialsAndSetToken.data.token)
  //       // const user = getUserToSet()
  //       // setUser(user)
  //       const decodedToken: any = jwt_decode(authenticateCredentialsAndSetToken.data.token)
  //       console.log('decodedToken ' + JSON.stringify(decodedToken))
  //       const getUserData = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/authentication/getUserData`, {
  //         token: localStorage.getItem('CFN_TOKEN'),
  //         id: decodedToken.userId,
  //         level: 'user',
  //       })
  //       console.log('ud ' + JSON.stringify(getUserData.data.user[0]))
  //       const userToSet: User = {
  //         customerName:getUserData.data.user[0].custName,
  //         customerNumber:getUserData.data.user[0].custNum,
  //         username: getUserData.data.user[0].username,
  //         emailOnfile:getUserData.data.user[0].accountEmailOnFile,
  //         isRegistrationComplete: getUserData.data.user[0].hasCompletedRegistration,
  //         isAdmin: false,
  //         id: decodedToken.userId,
  //         auth: true,
  //         token: authenticateCredentialsAndSetToken.data.token
  //       }
  //        await setUser(userToSet)
  //
  //       if (saveCredentials && customerNumber && username) {
  //         localStorage.setItem('CFN_ACCOUNTNUMBER', customerNumber)
  //         localStorage.setItem('CFN_USERNAME', username)
  //       }
  //       history.push('/transactions')
  //     }
  //   } catch {
  //     setErrorMessage('*Could not find an account for that login. Please try again with different credentials')
  //   }
  // }

  const authenticateUser = async () => {
    try {
      const response = await axios.post<User>(`${process.env.REACT_APP_BASE_ROOT_URL}/authentication/login`, {
        username: username,
        password: password,
        accountNumber: customerNumber,
        level: 'user',
      })

      if (response && response.status === 200 && response.data.userNeedsEmail) {
        // LOGIN WAS VALID, BUT USERNAME WAS USED INSTEAD OF EMAIL
        console.log('here')
        setOpenAccountNeedsEmailModal(true)
      }
      else {
        if (response && response.status === 200) {
          // sets user data token to local storage and then sets the user to store based on that token
          localStorage.setItem('CFN_TOKEN', response.data.token)
          const user = getUserToSet()
          setUser(user)
          if (saveCredentials && customerNumber && username) {
            localStorage.setItem('CFN_ACCOUNTNUMBER', customerNumber)
            localStorage.setItem('CFN_USERNAME', username)
          }
          history.push('/transactions')
        }
      }
    } catch {
      setErrorMessage('*Could not find an account for that login. Please try again with different credentials')
    }
  }

  return (
    <>
      <Paper>
        <ForgotPasswordModal isOpen={isRequestPasswordModalOpen} handleClose={handleCloseRequestPasswordModal} />
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <MuiThemeProvider theme={theme}>
              <Typography component="h1" variant="h5" className={classes.title}>
                Welcome to the Travelcard admin center. Please log in.
              </Typography>
              <form className={classes.form} onSubmit={authenticateUser}>
                <TextField
                  onChange={handleChangeCustomerNumber}
                  value={customerNumber}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="customerNumber"
                  label="Customer Number"
                  name="customerNumber"
                  autoFocus
                />
                <TextField
                  onChange={handleChangeUsername}
                  value={username}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                />
                <TextField
                  onChange={handleChangePassword}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                />
                <FormControlLabel
                  control={<Checkbox color="primary" value={saveCredentials} onChange={handleChangeSaveCredentials} />}
                  label="Remember me"
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={authenticateUser}
                >
                  Sign In
                </Button>
              </form>
              { errorMessage !== '' &&
                <ShowError errorMessage={errorMessage}></ShowError>
              }
              <AccountNeedsEmailModal setIsOpen={setOpenAccountNeedsEmailModal} customerId={customerNumber ? customerNumber : ''} isOpen={openAccountNeedsEmailModal}></AccountNeedsEmailModal>
            </MuiThemeProvider>
          </div>
          <p>
            Forgot your password? Click{' '}
            <a
              onClick={() => {
                setIsRequestPasswordModalOpen(true)
              }}
              style={{ color: 'blue', cursor: 'pointer' }}
            >
              here
            </a>
          </p>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Container>
      </Paper>
    </>
  )
}

export default withRouter(UserLogin)
