import React, { useState } from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import History from './components/History/HistoryPage'
import ListCards from './components/ListCards/ListCardsPage'
import Admin from '../src/components/Admin/AdminLogin'
import AdminAccountsList from './components/Admin/AdminAccountsList'
import ProtectedRoute from './ProtectedRoute'
import { useStore } from './store/StoreProvider'
import { UserLogin } from './components/User/UserLogin'
import ChangePassword from './components/Admin/ChangePassword/ChangePassword'
import DeleteAccount from './components/Admin/DeleteAccount/DeleteAccount'
import RemoveDuplicateInvoices from './components/Admin/RemoveDuplicateInvoices/RemoveDuplicateInvoices'
import AddNewCustomer from './components/Admin/AddNewCustomer/AddNewCustomer'
import StatementsPage from './components/Statements/StatementsPage'
import ManageSubAccountsPage from './components/ManageSubAccounts/ManageSubAccountsPage'
import NewCard from './components/NewCard/NewCard'
import MakePayment from './components/MakePayment/MakePayment'
import Register from './components/Registration/Register'
import jwt from 'jsonwebtoken'
import moment from 'moment'
import ResetPassword from './components/Password/ResetPassword'
import LegacyRegister from './components/Registration/LegacyRegister'
import DeletePendingCardEditPage from './components/DeletePendingCardEdit/DeletePendingCardEditPage'

export const Routes = () => {
  const { user } = useStore()

  const isAuthed = (): boolean => {
    let isTokenExpired: boolean = false
    // DECODES the auth token and verifies that it isn't expired, it does not verify the tokens VALIDITY
    // VALIDITY is verified when a call is made to the backend for data

    // if the token is expired the user is redirected to the login page
    const token = localStorage.getItem('CFN_TOKEN') ? localStorage.getItem('CFN_TOKEN') : ''

    if (token === '') {
      return true
    }

    const decoded: null | { [key: string]: any } | string = jwt.decode(token ? token : '')

    // because jsonwebtoken just returns a native object you have to check for specific members
    if (decoded !== null && typeof decoded === 'object' && 'exp' in decoded) {
      const rightNow = moment()
      const tokenExpire = moment.unix(decoded.exp)

      // const diff = rightNow.diff(tokenExpire, "seconds");

      if (tokenExpire.isBefore(rightNow)) {
        console.log('token is expired')
        isTokenExpired = true
      }

      if (isTokenExpired) {
        // if token is expired remove it from storage
        // if you get here isExpired will always be true
        localStorage.removeItem('CFN_TOKEN')
        localStorage.removeItem('spoofUserId')
        return isTokenExpired
      } else {
        console.log('Token good')
        // if you get here isTokenExpired will always be false
        return isTokenExpired
      }
    } else {
      // token is null or cannot be read for some reason
      return false
    }

    // catch all for unknown cases
    return false
  }
  const isExpired = isAuthed()
  return (
    <Switch>
      <Route path="/" exact>
        {/*if no user redirect to login,
        if user is admin redirect to admin account list,
        if user regular user redirect to history page*/}

        {!user ? (
          <Redirect to={'/login'} />
        ) : user && user.isAdmin ? (
          <Redirect to={'/adminlistcards'} />
        ) : (
          <Redirect to={'/transactions'} />
        )}
      </Route>
      <Route path="/admin" exact component={Admin} />
      <Route path="/login" exact component={UserLogin} />
      <Route path="/register/:accountNumber" exact component={Register} />
      <Route path="/legacyRegister/:token" exact component={LegacyRegister} />
      <Route path="/passwordrecover/:token" exact component={ResetPassword} />
      <ProtectedRoute
        isAuthorized={user !== undefined && user?.isAdmin && !isExpired}
        isAdminRoute={true}
        path="/adminAccountsList"
        componentToRender={AdminAccountsList}
      />
      <ProtectedRoute
        isAuthorized={user !== undefined && user?.isAdmin && !isExpired}
        isAdminRoute={true}
        path="/addAccount"
        componentToRender={AddNewCustomer}
      />
      <ProtectedRoute
        isAuthorized={user !== undefined && user?.isAdmin && !isExpired}
        isAdminRoute={true}
        path="/changePassword"
        componentToRender={ChangePassword}
      />
      <ProtectedRoute
        isAuthorized={user !== undefined && user?.isAdmin && !isExpired}
        isAdminRoute={true}
        path="/deleteAccount"
        componentToRender={DeleteAccount}
      />
      <ProtectedRoute
        isAuthorized={user !== undefined && user?.isAdmin && !isExpired}
        isAdminRoute={true}
        path="/removeDuplicateInvoices"
        componentToRender={RemoveDuplicateInvoices}
      />
      <ProtectedRoute
        isAuthorized={user !== undefined && user?.isAdmin && !isExpired}
        isAdminRoute={true}
        path="/deletePendingCardEdit"
        componentToRender={DeletePendingCardEditPage}
      />
      <ProtectedRoute
        isAuthorized={user !== undefined && !isExpired}
        isAdminRoute={false}
        path="/transactions"
        componentToRender={History}
      />
      <ProtectedRoute
        isAuthorized={user !== undefined && !isExpired}
        isAdminRoute={false}
        path="/statements"
        componentToRender={StatementsPage}
      />
      <ProtectedRoute
        isAuthorized={user !== undefined && !isExpired}
        isAdminRoute={false}
        path="/cards"
        componentToRender={ListCards}
      />
      <ProtectedRoute
        isAuthorized={user !== undefined && !isExpired}
        isAdminRoute={false}
        path="/subaccounts"
        componentToRender={ManageSubAccountsPage}
      />
      <ProtectedRoute
        path="/newcard"
        isAuthorized={user !== undefined && !isExpired}
        isAdminRoute={false}
        componentToRender={NewCard}
      />
      <ProtectedRoute
        path="/makeapayment"
        isAuthorized={user !== undefined && !isExpired}
        isAdminRoute={false}
        componentToRender={MakePayment}
      />
    </Switch>
  )
}

export default Routes
