import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { CardsTableDisplay, ListCardsDataDisplayComponent } from '../../types/types'
import { useStore } from '../../store/StoreProvider'
import TablePagination from '@material-ui/core/TablePagination'
import { Button, Popper, TextField } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import BuildIcon from '@material-ui/icons/Build'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { getCardStatus } from '../../utils/helpers'
import NewCardAlarmModal from '../Modals/NewCardAlarmModal'
import ReplaceCardModal from '../Modals/ReplaceCardModal'
import InvalidateOrValidateCardModal from '../Modals/InvalidateOrValidateCardModal'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Accordion from '@material-ui/core/Accordion'
import FilterListIcon from '@material-ui/icons/FilterList'
import InfoSharpIcon from '@material-ui/icons/InfoSharp'
import Tooltip from '@material-ui/core/Tooltip'
import ToggleOnIcon from '@material-ui/icons/ToggleOn'
import ToggleOffIcon from '@material-ui/icons/ToggleOff'
import AddIcon from '@material-ui/icons/Add'
import { useHistory } from 'react-router-dom'
import ModifyDriverCardModal from '../Modals/ModifyDriverCardModal'
import ModifySingleCardModal from '../Modals/ModifySingleCardModal'
import ModifyVehicleCardModal from '../Modals/ModifyVehicleCardModal'
import { validCardTypes } from '../../Db'

const useStyles = makeStyles({
  table: {},
  tableHead: {
    backgroundColor: '#ededee',
  },
  searchAndClearWrapper: {
    display: 'flex',
    paddingRight: 20,
  },
  historyTableStyles: {
    width: '100 !important',
  },
  responsiveData: {
    padding: '0 !important',
    margin: '0 !important',
  },
  searchIcon: {
    color: '#FF2045',
  },
})

export const ResponsiveListCardsDataDisplay: React.FunctionComponent<ListCardsDataDisplayComponent> = ({ cardModifiedCb }) => {
  let history = useHistory()
  const classes = useStyles()
  const { setCardsFilteredDataTable, cardsDataTable, cardsFilteredDataTable } = useStore()
  const [page, setPage] = useState(0)
  const [openModifyCardDialog, setOpenModifyCardDialog] = useState(false)
  const [openCardAlarmModal, setOpenCardAlarmModal] = useState(false)
  const [openReplaceCardModal, setOpenReplaceCardModal] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [statusDropdownValues, setStatusDropdownValues] = useState<string[]>([])
  const [authorizedProductsDropdownValues, setAuthorizedProductsDropdownValues] = useState<string[]>([])
  const [fillLimitDropdownValues, setfillLimitDropdownValues] = useState<string[]>([])
  const [cardTypeDropdownValues, setCardTypeDropdownValues] = useState<string[]>([])

  const [cardNumberFilterValue, setCardNumberFilterValue] = useState('')
  const [fillLimitFilterValue, setFillLimitFilterValue] = useState(0)
  const [cardLabelFilterValue, setCardLabelFilterValue] = useState('')
  const [vehicleFilterValue, setVehicleFilterValue] = useState('')
  const [authorizedProductsFilterValue, setAuthorizedProductsFilterValue] = useState(0)
  const [cardTypeFilterValue, setCardTypeFilterValue] = useState('')
  const [cardBeingModified, setCardBeingModified] = useState<CardsTableDisplay | null>(null)

  const [statusFilterValue, setStatusFilterValue] = useState<string>('')

  const [isModifyingSingleCard, setIsModifyingSingleCard] = useState(false)
  const [isModifyingDriverCard, setIsModifyingDriverCard] = useState(false)
  const [isModifyingVehicleCard, setIsModifyingVehicleCard] = useState(false)

  const [hasAlarmBeenCreated, setHasAlarmBeenCreated] = useState(false)
  const [isACardBeingInvalidatedOrValidated, setIsACardBeingInvalidatedOrValidated] = useState(false)
  const [filterPopperAnchorEl, setFilterPopperAnchorEl] = React.useState<null | HTMLElement>(null)

  const filterValues = [
    statusFilterValue,
    vehicleFilterValue,
    cardLabelFilterValue,
    authorizedProductsFilterValue,
    fillLimitFilterValue,
    cardTypeFilterValue,
  ]

  const openCardModificationModal = (cardTypeCode: number) => {
    switch (cardTypeCode) {
      case 6 || 11:
        setIsModifyingSingleCard(true)
        break
      case 7 || 9:
        setIsModifyingDriverCard(true)
        break
      case 8 || 10:
        setIsModifyingVehicleCard(true)
        break
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const generateValuesForStatusDropdown = (values: CardsTableDisplay[] | undefined) => {
    const statusTypes: string[] = []
    values
      ? values.forEach(
          (element) =>
            !statusTypes.includes(getCardStatus(element.card_status)) &&
            statusTypes.push(getCardStatus(element.card_status))
        )
      : console.log('')
    setStatusDropdownValues(statusTypes)
  }

  const generateValuesForAuthorizedProductsDropdown = (values: CardsTableDisplay[] | undefined) => {
    const authTypes: string[] = []
    values
      ? values.forEach(
          (element) =>
            !authTypes.includes(element.product_auth_code) &&
            authTypes.push(element.product_auth_code)
        )
      : console.log('')
    setAuthorizedProductsDropdownValues(authTypes)
  }

  const generateValuesForFillLimitDropdown = (values: CardsTableDisplay[] | undefined) => {
    const fillLimits: string[] = []

    values
      ? values.forEach(
          (element) =>
            !fillLimits.includes(element.fill_limit_per_purchase) &&
            fillLimits.push(element.fill_limit_per_purchase)
        )
      : console.log('')
    setfillLimitDropdownValues(fillLimits)
  }

  const generateValuesForCardTypeDropdown = (values: CardsTableDisplay[] | undefined) => {
    const cardTypes: string[] = []

    values?.forEach((element) =>
        !cardTypes.includes(element.cardType) &&
        cardTypes.push(element.cardType)
    )
    setCardTypeDropdownValues(cardTypes)
  }
  const clearAllFilters = () => {
    setCardNumberFilterValue('')
    setStatusFilterValue('')
    setVehicleFilterValue('')
    setCardLabelFilterValue('')
    setAuthorizedProductsFilterValue(0)
    setFillLimitFilterValue(0)
  }

  const handleStatusFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatusFilterValue(event.target.value as string)
  }

  const handleAuthorizedProductsFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAuthorizedProductsFilterValue(event.target.value as number)
  }

  const handleFillLimitFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFillLimitFilterValue(event.target.value as number)
  }

  const handleCardTypeFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCardTypeFilterValue(event.target.value as string)
  }

  const handleInvalidateOrValidateClickOpen = (cardBeingModified: CardsTableDisplay) => {
    setCardBeingModified(cardBeingModified)
    setIsACardBeingInvalidatedOrValidated(true)
  }

  const handleModifyCardClickOpen = (cardBeingModified: CardsTableDisplay) => {
    setCardBeingModified(cardBeingModified)
    setOpenModifyCardDialog(true)
    openCardModificationModal(cardBeingModified.cardTypeCode)
  }

  const handleReplaceCardClickOpen = (cardBeingModified: CardsTableDisplay) => {
    setCardBeingModified(cardBeingModified)
    setOpenReplaceCardModal(true)
  }

  const handleInvalidateOrValidateClickClose = () => {
    setCardBeingModified(null)
    setIsACardBeingInvalidatedOrValidated(false)
  }

  const handleModifyCardClickClose = () => {
    setCardBeingModified(null)
    setIsModifyingSingleCard(false)
    setIsModifyingDriverCard(false)
    setIsModifyingVehicleCard(false)
    setOpenCardAlarmModal(false)
    setOpenReplaceCardModal(false)
    setIsACardBeingInvalidatedOrValidated(false)
  }


  const handleReplaceCardClickClose = () => {
    setCardBeingModified(null)
    setOpenReplaceCardModal(false)
  }

  const handleCardAlarmClickClose = () => {
    setCardBeingModified(null)
    setHasAlarmBeenCreated(false)
    setOpenCardAlarmModal(false)
  }

  const filterTable = () => {
    // When filtering return to first page of dataset
    setPage(0)
    // Makes a copy of all data on data table. This serves as source of truth for all filters going down the line
    let tempFilteredTable: CardsTableDisplay[] | undefined = cardsDataTable

    if (cardLabelFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) => 
        row.card_name?.toString().toLowerCase().startsWith(cardLabelFilterValue.toLowerCase()))
    }
    if (statusFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        getCardStatus(row.card_status).toString().startsWith(statusFilterValue)
      )
    }
    if (vehicleFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.vehicleDescription?.toString().toLowerCase().startsWith(vehicleFilterValue.toLowerCase()))
    }
    if (authorizedProductsFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.product_auth_code?.startsWith(authorizedProductsFilterValue.toString())
      )
    }
    if (fillLimitFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.fill_limit_per_purchase?.toString().startsWith(fillLimitFilterValue.toString())
      )
    }
    if (cardTypeFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.cardType?.toString().startsWith(cardTypeFilterValue.toString())
      )
    }
    if (cardNumberFilterValue) {
      tempFilteredTable = tempFilteredTable?.filter((row) =>
        row.card_num?.toString().toLowerCase().startsWith(cardNumberFilterValue.toLowerCase()))
    }

    createDropdowns()
    setCardsFilteredDataTable && setCardsFilteredDataTable(tempFilteredTable ? tempFilteredTable : cardsDataTable)
  }
  const filterPopperHandleClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterPopperAnchorEl(filterPopperAnchorEl ? null : event.currentTarget)
  }

  const createDropdowns = () => {
    generateValuesForStatusDropdown(cardsDataTable)
    generateValuesForAuthorizedProductsDropdown(cardsDataTable)
    generateValuesForFillLimitDropdown(cardsDataTable)
    generateValuesForCardTypeDropdown(cardsDataTable)
  }

  const getCardsTableRow = (row: CardsTableDisplay, i: number) => {
    return (
      <TableRow>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
            <p>Card: {row.card_num}</p>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.responsiveData}>
              {validCardTypes.includes(row.cardTypeCode) && (
                <IconButton
                  onClick={() => handleModifyCardClickOpen(row)}
                >
                  <BuildIcon style={{ height: 20 }}></BuildIcon>
                </IconButton>
              )}
              <IconButton
                onClick={() => handleReplaceCardClickOpen(row)}
              >
                <AddCircleOutlineIcon style={{ height: 20 }}></AddCircleOutlineIcon>
              </IconButton>
              {row.card_status === 'I' ? (
                <Tooltip disableFocusListener title="Validate Card">
                  <IconButton
                    onClick={() =>
                      handleInvalidateOrValidateClickOpen(row)
                    }
                  >
                    <ToggleOnIcon style={{ height: 20 }}></ToggleOnIcon>
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip disableFocusListener title="Invalidate Card">
                  <IconButton
                    onClick={() =>
                      handleInvalidateOrValidateClickOpen(row)
                    }
                  >
                    <ToggleOffIcon style={{ height: 20 }}></ToggleOffIcon>
                  </IconButton>
                </Tooltip>
              )}
              <p>Card Number: {row.card_num}</p>
              <p>Status: {row.pending_edit ? 'Pending Changes' : getCardStatus(row.card_status)}</p>
              <p>Vehicle Description: {row.vehicleDescription}</p>
              <p>Card Label: {row.travelCardLabel}</p>
              <p>Authorized Products: {row.product_auth_code}</p>
              <p>Fill Limit: {row.fill_limit_per_purchase}</p>
              <p>
                Card Type: {row.cardType} <Tooltip disableFocusListener title={row.cardTypeDescription}>
                  <InfoSharpIcon style={{ height: 15 }} />
                </Tooltip>
              </p>
            </div>
          </AccordionDetails>
        </Accordion>
      </TableRow>
    )
  }

  useEffect(() => {
    // Any time one of the filters is updated re-run the filtering logic
    filterTable()
  }, filterValues)

  useEffect(() => {
    if (!cardsDataTable) {
      createDropdowns()
    }
    // performs initial date filtering
    filterTable()
  }, [cardsDataTable])

  // TODO: Replace this with a composable version that can accept a component as a prop for the rows
  return (
    <div style={{ maxWidth: '100% !important' }}>
      {cardBeingModified && (
        <>
          <ModifyDriverCardModal
            isOpen={isModifyingDriverCard}
            handleClose={handleModifyCardClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          />
          <ModifySingleCardModal
            isOpen={isModifyingSingleCard}
            handleClose={handleModifyCardClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          ></ModifySingleCardModal>
          <ModifyVehicleCardModal
            isOpen={isModifyingVehicleCard}
            handleClose={handleModifyCardClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          ></ModifyVehicleCardModal>
          <InvalidateOrValidateCardModal
            isOpen={isACardBeingInvalidatedOrValidated}
            handleClose={handleInvalidateOrValidateClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          ></InvalidateOrValidateCardModal>
          <NewCardAlarmModal
            hasAlarmBeenCreated={hasAlarmBeenCreated}
            setHasAlarmBeenCreated={setHasAlarmBeenCreated}
            isOpen={openCardAlarmModal}
            handleClose={handleCardAlarmClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          ></NewCardAlarmModal>
          <ReplaceCardModal
            isOpen={openReplaceCardModal}
            handleClose={handleReplaceCardClickClose}
            cardBeingModified={cardBeingModified}
            cardModifiedCb={cardModifiedCb}
          ></ReplaceCardModal>
        </>
      )}
      <Paper className={classes.historyTableStyles}>
        <div className={classes.searchAndClearWrapper}>
          <IconButton aria-label="filter list" onClick={filterPopperHandleClick}>
            <FilterListIcon />
          </IconButton>
          <Popper id={'dadasad'} open={Boolean(filterPopperAnchorEl)} anchorEl={filterPopperAnchorEl}>
            <Paper style={{ display: 'flex', flexDirection: 'column', paddingTop: 50 }}>
              <>
                <div>
                  <IconButton onClick={() => clearAllFilters()}>
                    <ClearIcon></ClearIcon>
                  </IconButton>
                  <IconButton onClick={() => history.push('/newcard')}>
                    <AddIcon></AddIcon>
                  </IconButton>
                  <Button style={{ marginLeft: 'auto' }} variant={'contained'} onClick={filterPopperHandleClick}>
                    Close
                  </Button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>Card:</p>
                  <TextField
                    onChange={(event: any) => setCardNumberFilterValue(event.target.value)}
                    value={cardNumberFilterValue}
                  />
                  <p>Status:</p>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={statusFilterValue}
                    onChange={handleStatusFilterChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {statusDropdownValues.map((dropdownItem, i) => (
                      <MenuItem value={dropdownItem} key={i}>
                        {dropdownItem}
                      </MenuItem>
                    ))}
                  </Select>
                  <p>Vehicle Number:</p>
                  <TextField
                    onChange={(event: any) => setVehicleFilterValue(event.target.value)}
                    value={vehicleFilterValue}
                  />
                  <p>Card Label:</p>
                  <TextField
                    onChange={(event: any) => setCardLabelFilterValue(event.target.value)}
                    value={cardLabelFilterValue}
                  />
                  <p>Card Type:</p>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={cardTypeFilterValue}
                    onChange={handleCardTypeFilterChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {cardTypeDropdownValues.map((dropdownItem, i) => (
                      <MenuItem value={dropdownItem} key={i}>
                        {dropdownItem}
                      </MenuItem>
                    ))}
                  </Select>
                  <p>Authorized Products:</p>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={authorizedProductsFilterValue}
                    onChange={handleAuthorizedProductsFilterChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {authorizedProductsDropdownValues.map((dropdownItem, i) => (
                      <MenuItem value={dropdownItem} key={i}>
                        {dropdownItem}
                      </MenuItem>
                    ))}
                  </Select>
                  <p>Fill Limit:</p>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={fillLimitFilterValue}
                    onChange={handleFillLimitFilterChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {fillLimitDropdownValues.map((dropdownItem, i) => (
                      <MenuItem value={dropdownItem} key={i}>
                        {dropdownItem}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </>
            </Paper>
          </Popper>
        </div>
        <TableContainer component={Paper} style={{ width: '100% !important' }}>
          <Table className={classes.table} size="small">
            <TableHead></TableHead>
            <TableBody>
              <TableRow></TableRow>
              {cardsFilteredDataTable &&
                  cardsFilteredDataTable
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => getCardsTableRow(row, i))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          onPageChange={() => {}}
          count={cardsFilteredDataTable && cardsFilteredDataTable !== undefined ? cardsFilteredDataTable.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default ResponsiveListCardsDataDisplay
