import React, { Dispatch, SetStateAction, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import clsx from 'clsx'
import axios from 'axios'
import { useStore } from '../../store/StoreProvider'
import ShowError from '../Messages/ShowError'

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    color: 'black',
  },
  leftLabel: {
    marginRight: 20,
  },
  flexContainer: {
    display: 'flex',
  },
})
interface NewSubAccountModalProps {
  isOpen: boolean
  handleClose: () => void
  customerNumber: number
  id: number
  setRefreshDataTable: Dispatch<SetStateAction<boolean>>
  setIsDeletingSubaccount: Dispatch<SetStateAction<boolean>>
  isDeletingSubaccount: boolean
}

export const ConfirmDeleteSubaccountModal = ({
  isOpen,
  handleClose,
  customerNumber,
  id,
  setRefreshDataTable,
  setIsDeletingSubaccount,
  isDeletingSubaccount,
}: NewSubAccountModalProps) => {
  const { user } = useStore()
  const classes = useStyles()

  const [errorMessage, setErrorMessage] = useState('')

  const handleDeleteSubaccount = async () => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/deleteSubaccount`, {
      id: id,
      customerNumber: customerNumber,
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
      token: localStorage.getItem('CFN_TOKEN'),
    })

    if (response.status === 200) {
      setRefreshDataTable(true)
      setIsDeletingSubaccount(true)
    } else {
      setErrorMessage('*Could Not Delete Subaccount')
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose()
      }}
      aria-labelledby="form-dialog-title"
    >
      {!isDeletingSubaccount ? (
        <>
          <DialogTitle id="form-dialog-title">Create New Subaccount</DialogTitle>
          <DialogContent>
            <div className={classes.flexContainer}>
              <div className={clsx(classes.title, classes.leftLabel)}>Company Name:</div>
              {user && user.customerName}
            </div>
            <div className={classes.flexContainer}>
              <div className={clsx(classes.title, classes.leftLabel)}>
                Customer: {localStorage.getItem('spoofUserId') ? localStorage.getItem('spoofUserId') : customerNumber}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDeleteSubaccount}>Delete</Button>
          </DialogActions>
          <ShowError errorMessage={errorMessage}></ShowError>
        </>
      ) : (
        <div>
          <p style={{ padding: 10 }}>Subaccount Deleted</p>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  )
}
export default ConfirmDeleteSubaccountModal
