import React, { useState, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import CardContent from '@material-ui/core/CardContent'
import { useHistory } from 'react-router-dom'
import CheckImage from './sample_check.jpg'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { useStore } from '../../store/StoreProvider'
import { Card } from '@material-ui/core'
import ShowError from '../Messages/ShowError'
import DataLoadingSpinner from '../Global/DataLoadingSpinner'

const useStyles = makeStyles({
  cardHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  root: {
    minWidth: 275,
  },
  title: {
    justifyContent: 'center',
    display: 'flex',
    paddingBottom: 10,
  },
  controlWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  radio: {
    '&$checked': {
      color: '#ed2224',
    },
  },
  checked: {},
  titleOff: {
    fontWeight: 'bold',
    color: '#cfcfcf',
  },
  form: {
    '& form': {
      width: '100% !important',
    },
  },
})

interface PaymentInfoOnFile {
  bankName: string | null
  nameOnAccount: string | null
}

export const MakePayment = () => {
  let history = useHistory()
  const classes = useStyles()
  const { user } = useStore()

  const [keepCardOnFile, setKeepCardOnFile] = useState<boolean>(false)
  const [isPaymentMade, setIsPaymentMade] = useState<boolean>(false)
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false)
  const [nameOfBank, setNameOfBank] = useState('')
  const [nameOnCheckingAccount, setNameOnCheckingAccount] = useState('')
  const [abaRoutingNumber, setAbaRoutingNumber] = useState('')
  const [bankAccountNumber, setBankAccountNumber] = useState('')
  const [accountType, setAccountType] = useState('')
  const [paymentAmount, setPaymentAmount] = useState('')
  const [yourName, setYourName] = useState('')
  const [sendEmailConfirmationTo, setsendEmailConfirmationTo] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)
  const [paymentInfoOnFile, setPaymentInfoOnFile] = useState<PaymentInfoOnFile | undefined>(undefined)

  const isPaymentInfoOnFile = !!(paymentInfoOnFile?.bankName && paymentInfoOnFile?.nameOnAccount)

  const handleNavigateToConfirmation = () => {
    setIsConfirmation(true)
  }
  const handleSendPaymentRequest = async () => {
    try {
      let post: {[key: string]: any} = {
        token: localStorage.getItem('CFN_TOKEN'),
        amountToPay: paymentAmount,
        email: sendEmailConfirmationTo,
        companyName: user?.customerName,
        spoofedCustomerId: localStorage.getItem('spoofUserId'),
        customerNumber: user?.id,
      }
      if (isPaymentInfoOnFile === false) {
        post = {
          contactName: yourName,
          bankName: nameOfBank,
          nameOnAccount: nameOnCheckingAccount,
          abaRoutingNumber: abaRoutingNumber,
          accountNumber: bankAccountNumber,
          accountType: accountType,
          keepOnFile: keepCardOnFile,
          ...post,
        }
      }
      const endPoint = `/accounts/${isPaymentInfoOnFile === false ? 'makeAPayment' : 'makeAPaymentWithPaymentInfoOnFile'}`
      const data = await axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}${endPoint}`, post)
      if (data.status === 200) {
        setIsPaymentMade(true)
      } else {
        setErrorMessage('*Could Not Make Payment')
      }
    } catch (e) {
      setErrorMessage('*Could Not Make Payment')
    }
  }
  const leavePage = () => {
    history.push('/transactions')
  }
  const handleChangeKeepCardOnFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Casting string true/false from form into actual boolean type by using truthy falsy
    const value = event.target.value === 'true'
    setKeepCardOnFile(value)
  }

  const loadPaymentInfo = () => {
    axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/paymentInfo`, {
      token: localStorage.getItem('CFN_TOKEN'),
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
      customerNumber: user?.id,
    })
      .then(resp => {
        console.log(resp.data)
        setPaymentInfoOnFile(resp.data)
        setIsLoaded(true)
      })
  }

  const deletePaymentInfo = () => {
    axios.post(`${process.env.REACT_APP_BASE_ROOT_URL}/accounts/deletePaymentInfo`, {
      token: localStorage.getItem('CFN_TOKEN'),
      spoofedCustomerId: localStorage.getItem('spoofUserId'),
      customerNumber: user?.id,
    })
      .then(resp => {
        loadPaymentInfo()
      })
  }

  useEffect(() => {
    loadPaymentInfo()
  }, [])

  return (
    <>
      {!isLoaded ? (
        <DataLoadingSpinner />
      ) : !isConfirmation ? (
        <CardContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ maxWidth: '600px', paddingLeft: 25, paddingRight: 25 }}>
            {isPaymentInfoOnFile === false ? (
              <>
                <h1>Please Enter Your Banking Information to Make a Payment</h1>
                <p>
                  If you're using a checking account, refer to the sample check below for the location of the bank routing
                  number and your bank account number. If you're using a savings account, the deposit slip may have the
                  numbers or call your bank for the information. Some financial institutions use unique ABA routing and
                  account numbers for electronic payments. To avoid any delay, verify your ABA routing and account numbers
                  with your financial institution before completing this process.
                </p>
                <p>
                  If you're within 10 days of your due date, we suggest that you make a Click 'N' Pay payment using the
                  current bank account number on file (provided that it's still an open account) prior to submitting a
                  request to change your bank information. If you do change your bank information, you may experience a 6
                  business day delay while we validate your new bank account number.
                </p>
                <p>
                  Note: If you are changing from one type of account to another, such as a checking to savings or savings to
                  checking account, verify that your account change was made by your financial institution.
                </p>
                <img src={CheckImage} style={{ maxWidth: '100%' }} />
                <p>
                  If your information is kept on file with us, you may modify any of the fields below to update that
                  information. For your safety, we do not store any sensitive data on the server.
                </p>
              </>
            ) : (
              <h1>Please Enter The Below Fields to Make a Payment</h1>
            )}
          </div>
          <ValidatorForm onSubmit={handleNavigateToConfirmation} className={classes.form}>
            {isPaymentInfoOnFile === false ? (
              <>
                <TextValidator
                  name={'nameOfBank'}
                  onChange={(event: any) => setNameOfBank(event.target.value)}
                  value={nameOfBank}
                  style={{ marginBottom: 20, minWidth: '100% !important' }}
                  label={'Name of Bank'}
                  validators={['required']}
                  errorMessages={['Bank name is required']}
                />
                <TextValidator
                  name={'nameOnCheckingAccount'}
                  onChange={(event: any) => setNameOnCheckingAccount(event.target.value)}
                  value={nameOnCheckingAccount}
                  style={{ marginBottom: 20, width: '100% !important' }}
                  label={'Checking Account Name'}
                  validators={['required']}
                  errorMessages={['Checking account name is required']}
                />
                <TextValidator
                  name={'abaRoutingNumber'}
                  onChange={(event: any) => setAbaRoutingNumber(event.target.value)}
                  value={abaRoutingNumber}
                  style={{ marginBottom: 20, width: '100% !important' }}
                  label={'ABA Routing Number'}
                  validators={['required']}
                  errorMessages={['ABA routing number is required']}
                />
                <TextValidator
                  name={'bankAccountNumber'}
                  onChange={(event: any) => setBankAccountNumber(event.target.value)}
                  value={bankAccountNumber}
                  style={{ marginBottom: 20, width: '100% !important' }}
                  label={'Bank Account Number'}
                  validators={['required']}
                  errorMessages={['Bank account number is required']}
                />
                <TextValidator
                  name={'accountType'}
                  onChange={(event: any) => setAccountType(event.target.value)}
                  value={accountType}
                  style={{ marginBottom: 20, width: '100% !important' }}
                  label={'Account Type'}
                  validators={['required']}
                  errorMessages={['Account type is required']}
                />
              </>
            ) : (
              <>
                <ul style={{ listStyleType: 'none', textAlign: 'left' }}>
                  <li><h3>Payment Information On File</h3></li>
                  <li>Name of Bank: {paymentInfoOnFile?.bankName}</li>
                  <li>Name on Checking Account: {paymentInfoOnFile?.nameOnAccount}</li>
                </ul>
                <button onClick={deletePaymentInfo}>Delete Payment Information</button>
                <br/>
                <br/>
              </>
            )}
            <TextValidator
              name={'paymentAmount'}
              onChange={(event: any) => setPaymentAmount(event.target.value)}
              value={paymentAmount}
              style={{ marginBottom: 20, width: '100% !important' }}
              label={'Payment Amount'}
              validators={['required']}
              errorMessages={['Payment amount is required']}
            />
            {isPaymentInfoOnFile === false  && (
              <TextValidator
                name={'yourName'}
                onChange={(event: any) => setYourName(event.target.value)}
                value={yourName}
                style={{ marginBottom: 20, width: '100% !important' }}
                label={'Your Name'}
                validators={['required']}
                errorMessages={['Your name is required']}
              />
            )}
            <TextValidator
              name={'emailConfirmation'}
              onChange={(event: any) => setsendEmailConfirmationTo(event.target.value)}
              value={sendEmailConfirmationTo}
              style={{ marginBottom: 20, width: '100% !important' }}
              label={'Confirmation Email'}
              validators={['required', 'isEmail']}
              errorMessages={['Confirmation email is required', 'Email must be in correct format']}
            />
            {isPaymentInfoOnFile === false  && (
              <>
                <h3>Keep Card on File?</h3>
                <RadioGroup
                  aria-label="encodeVehicleNumber"
                  name="encodeVehicleNumber"
                  onChange={handleChangeKeepCardOnFile}
                  value={String(keepCardOnFile)}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="false"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
              </>
            )}
            <Button type="submit" variant="contained">
              Make Payment
            </Button>
            <ShowError errorMessage={errorMessage}></ShowError>
          </ValidatorForm>
        </CardContent>
      ) : !isPaymentMade ? (
        <>
          <Card>
            <CardContent>
              <div style={{ maxWidth: '600px', paddingLeft: 25, paddingRight: 25 }}>
                <ul style={{ listStyleType: 'none', textAlign: 'left' }}>
                  <li>Here are the details of your payment request:</li>
                  {isPaymentInfoOnFile === false  && (
                    <>
                      <li>Name of Bank: {nameOfBank}</li>
                      <li>Name on Checking Account: {nameOnCheckingAccount}</li>
                      <li>ABA Routing Number: {abaRoutingNumber}</li>
                      <li>Bank Account Number: {bankAccountNumber}</li>
                      <li>Account Type: {accountType}</li>
                    </>
                  )}
                  <li>Payment Amount: {paymentAmount}</li>
                  {isPaymentInfoOnFile === false  && (
                    <li>Your Name: {yourName}</li>
                  )}
                  <li>Email Confirmation to: {sendEmailConfirmationTo}</li>
                </ul>
                <div style={{ marginTop: 50 }}>
                  <h1>Terms and Conditions</h1>
                  <p>
                    You can make multiple payments in a billing cycle, but only 1 payment within a business day. We may
                    impose limitations on the number of payments accepted and the dollar amount of each payment using
                    this method.
                  </p>
                  <p>
                    If we receive your request to make a payment by 1 p.m. Pacific Time(PT) on a bank business day, your
                    payment will post to your account on the following business day. If we receive your request to make
                    a payment after 1 p.m. PT, your payment will be posted in two (2) business days. Our business days
                    are Monday through Friday, excluding Federal holidays.
                  </p>
                  <p>
                    If any electronic payment is returned unpaid by your financial institution for any reason, we may
                    charge and you agree to pay a returned payment fee and you may also be charged late fees based on
                    the terms and conditions of your Travel Card Commercial Fueling Account Agreement.
                  </p>
                  <p>
                    I authorize Eugene Truck Haven, Inc., dba Travel Card Commercial Fueling access to the above account
                    in order to electronically draft the amount indicated within this payment request. Further, I
                    certify that the above information is correct and that this account is a business account used
                    solely for business purposes.
                  </p>
                  <p>By clicking the button below, I acknowledge my agreement to the above statement.</p>
                  <Button variant="contained" onClick={handleSendPaymentRequest}>
                    Confirm Order
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </>
      ) : (
        <>
          <h1>Payment Made Successfully. Thank you!</h1>
          <Button variant="contained" onClick={leavePage}>
            Leave
          </Button>
        </>
      )}
    </>
  )
}

export default MakePayment
